import {useContext} from 'react'
import EmployeeTickets from './EmployeeTickets'
import {TicketContext} from './context/TicketContext'
import EmployeeTicketModal from './components/modal/EmployeeTicketModal'

function TicketActions() {
  const {modalState} = useContext(TicketContext)

  return (
    <>
      <EmployeeTickets />
      {modalState.showModal && <EmployeeTicketModal />}
    </>
  )
}

export default TicketActions
