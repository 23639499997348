import {useState, useEffect, FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import api from '../../../RequestConfig'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {showToast} from '../../../common/toastify/toastify.config'

const API_URL = process.env.REACT_APP_API_URL
const RESET_USER_PASSWORD = `${API_URL}/agent/account/reset-password`

interface resetPasswordProps {
  id: any
  closeModal: () => void
}

const ResetPasswordEmployee: FC<resetPasswordProps> = ({ id, closeModal }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  // eslint-disable-next-line
  const [passwordsMatch, setPasswordsMatch] = useState(true)

  const token = localStorage.getItem('token')

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const validationSchema = Yup.object({
    Password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .max(50, 'Maximum 50 symbols')
      .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
      .matches(/[a-z]/, 'Password must include at least one lowercase letter')
      .matches(/[@$!%*?&]/, 'Password must include at least one special character: @$!%*?&')
      .matches(/\d/, 'Password must include at least one digit')
      .matches(/^\S*$/, 'No whitespace allowed')
      .required('Password is required'),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref('Password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  })

  const initialValues = {
    Password: '',
    ConfirmPassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      showToast('Please wait for the password to reset', 'info')
      try {
        const result = await api.post(
          RESET_USER_PASSWORD,
          {
            appUserId: id,
            Password: values.Password,
          },
          {
            headers: {
              genie_access_token: 'Bearer ' + token,
            },
          }
        )
        if (result.status === 200) {
          showToast(`${result.data.message}`, 'success')
          const closeButton = document.getElementById('close_kt_modal_150')
          closeButton?.click()
        }
      } catch (error) {
        console.error(error)
      }
    },
  })

  const resetFormFields = () => {
    formik.resetForm()
  }

  useEffect(() => {
    const modalElement = document.getElementById('kt_modal_150')

    if (modalElement) {
      const handleModalHidden = () => {
        // Clear the values of the input fields
        resetFormFields()
      }

      const handleModalShown = () => {
        // Handle actions when the modal is shown/opened
        resetFormFields()
      }

      modalElement.addEventListener('hidden.bs.modal', handleModalHidden)
      modalElement.addEventListener('show.bs.modal', handleModalShown)

      // Cleanup: Remove the event listener when the component unmounts
      return () => {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden)
        modalElement.removeEventListener('show.bs.modal', handleModalShown)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetPassword = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to reset your password. are you sure you want to reset password',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          formik.handleSubmit()
        } catch (error) {
          console.error(error)
        }
      }
    })
  }

  return (
    <>
      <div className='modal fade show' tabIndex={-1}  style={{ display:  'block',  }}>  <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Reset Password</h5>
              <div
                id='close_kt_modal_150'
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={()=>closeModal()}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <form>
                <div className='mb-10'>
                  <label className='required form-label'>New Password</label>
                  <div className='input-group'>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className={`form-control form-control-solid ${
                        formik.touched.Password && formik.errors.Password ? 'is-invalid' : ''
                      }`}
                      placeholder='New Password'
                      name='Password'
                      value={formik.values.Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className='input-group-text' onClick={togglePasswordVisibility}>
                      <i className={`bi bi-eye${showPassword ? '-slash' : ''}`}></i>
                    </span>
                  </div>
                  {formik.touched.Password && formik.errors.Password && (
                    <div className='text-danger'>{formik.errors.Password}</div>
                  )}
                </div>
                <div className={`mb-3 ${!passwordsMatch ? 'text-danger' : ''}`}>
                  <label className='required form-label'>Confirm Password</label>
                  <div className='input-group'>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className={`form-control form-control-solid ${
                        formik.touched.ConfirmPassword && formik.errors.ConfirmPassword
                          ? 'is-invalid'
                          : ''
                      }`}
                      placeholder='Confirm Password'
                      name='ConfirmPassword'
                      value={formik.values.ConfirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className='input-group-text' onClick={toggleConfirmPasswordVisibility}>
                      <i className={`bi bi-eye${showConfirmPassword ? '-slash' : ''}`}></i>
                    </span>
                  </div>
                  {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword && (
                    <div className='text-danger'>{formik.errors.ConfirmPassword}</div>
                  )}
                </div>

                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' onClick={resetFormFields}>
                    Reset
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={() => {
                      handleResetPassword()
                    }}
                  >
                    {formik.isSubmitting && formik.isValid ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <span className='indicator-label'>Submit</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPasswordEmployee
