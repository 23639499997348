import {useContext, useState} from 'react'
import {LeaveContext} from '../../context/LeaveContext'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Option} from '../../../common/globals/common.constants'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

type Leave = {
  search: string
  status: string
  leaveType: string
  from: Date | null
  to: Date | null
  appUserId: string
}

const validationSchema = Yup.object().shape({
  search: Yup.string().trim(),
  leaveType: Yup.string().oneOf(['Sick Leave', 'Paid Leave', 'Casual Leave']),
  appUserId: Yup.string(),
  status: Yup.string().oneOf(['Approved', 'Rejected', 'Pending']),
  from: Yup.date().nullable(),
  to: Yup.date().nullable(),
})

let initialValues: Leave = {
  search: '',
  appUserId: '',
  leaveType: '',
  status: '',
  from: null,
  to: null,
}

const leaveTypeOptions = [
  {value: 'Sick Leave', label: 'Sick Leave'},
  {value: 'Paid Leave', label: 'Paid Leave'},
  {value: 'Casual Leave', label: 'Casual Leave'},
]
const statusOptions = [
  {value: 'Approved', label: 'Approved'},
  {value: 'Rejected', label: 'Rejected'},
  {value: 'Pending', label: 'Pending'},
]

function EmployeeLeavesHeader() {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [selectState, setSelectState] = useState<{
    leaveType: Option
    status: Option
  }>({
    leaveType: {label: 'Choose One', value: null},
    status: {label: 'Choose One', value: null},
  })

  const {changeLimit, loadFilterData, openModal, resetFilterData} = useContext(LeaveContext)

  const toggleMenu = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const filterStyle: any = {
    display: isFilterOpen ? 'block' : 'none',
    minWidth: 'auto',
    padding: '0',
    position: 'absolute',
    top: '70px',
    left: '60%',
    zIndex: '2',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      loadFilterData(values)
      setSubmitting(false)
      toggleMenu()
    },
  })

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>
        <div className='d-flex align-items-center position-relative my-1'>
          <select
            className='form-select form-select-solid mx-2'
            data-kt-select2='true'
            data-placeholder='Show records'
            defaultValue={10}
            onChange={(e) => {
              changeLimit(parseInt(e.target.value))
            }}
          >
            <option value='10'>10 Records</option>
            <option value='15'>15 Records</option>
            <option value='25'>25 Records</option>
            <option value='50'>50 Records</option>
          </select>
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <h2 className='mb-0 fs-1 fw-bold'>Employee Leaves</h2>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <div className='dropdown'>
            <button
              type='button'
              className='btn btn-light-primary me-3'
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <i className='fs-5 pe-2 fa-solid fa-filter'></i>Filter
            </button>
          </div>
          {isFilterOpen && (
            <div
              className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
              data-kt-menu='true'
              style={filterStyle}
            >
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              <div className='separator border-gray-200'></div>
              <form onSubmit={formik.handleSubmit} id='leave_filter' className='form'>
                <div className='px-7 py-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    <span>Search Leave Reason</span>
                  </label>
                  <input
                    type='text'
                    {...formik.getFieldProps('search')}
                    className={clsx('form-control bg-transparent py-2')}
                    placeholder='Search Leave Reason'
                  />
                  {formik.touched.search && formik.errors.search && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.search}</span>
                    </div>
                  )}
                </div>
                <div className='px-7 py-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    <span>Leave Type</span>
                  </label>
                  <Select
                    backspaceRemovesValue={true}
                    onChange={(option) => {
                      formik.setFieldValue('leaveType', option?.value)
                      setSelectState((prevState) => {
                        return {
                          ...prevState,
                          leaveType: option || {label: 'Choose One', value: null},
                        }
                      })
                    }}
                    value={selectState.leaveType}
                    options={leaveTypeOptions}
                    onBlur={formik.handleBlur}
                    isMulti={false}
                    isClearable={true}
                    placeholder={'Select Ticket Type'}
                  />
                  {formik.touched.leaveType && formik.errors.leaveType && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.leaveType}</span>
                    </div>
                  )}
                </div>
                <div className='px-7 py-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    <span>Status</span>
                  </label>
                  <Select
                    backspaceRemovesValue={true}
                    onChange={(option) => {
                      formik.setFieldValue('status', option?.value)
                      setSelectState((prevState) => {
                        return {
                          ...prevState,
                          status: option || {label: 'Choose One', value: null},
                        }
                      })
                    }}
                    value={selectState.status}
                    options={statusOptions}
                    onBlur={formik.handleBlur}
                    isMulti={false}
                    isClearable={true}
                    placeholder={'Select Ticket Type'}
                  />
                  {formik.touched.status && formik.errors.status && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.status}</span>
                    </div>
                  )}
                </div>
                <div className='d-flex align-items-center position-relative px-4 py-4'>
                  <div className='w-100'>
                    <DatePicker
                      selected={formik.values.from ? moment(formik.values.from).toDate() : null}
                      onChange={(date) => formik.setFieldValue('from', date)}
                      className='form-select form-select-solid w-100'
                      placeholderText='Select Start Date'
                    />
                  </div>
                </div>
                {formik.touched.from && formik.errors.from && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block' style={{paddingLeft: '68px'}}>
                      {formik.errors.from}
                    </div>
                  </div>
                )}
                <div className='d-flex align-items-center position-relative px-4 py-4'>
                  <div className='w-100'>
                    <DatePicker
                      selected={formik.values.to ? moment(formik.values.to).toDate() : null}
                      onChange={(date) => formik.setFieldValue('to', date)}
                      className='form-select form-select-solid w-100'
                      placeholderText='Select End Date'
                    />
                  </div>
                </div>
                {formik.touched.to && formik.errors.to && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block' style={{paddingLeft: '68px'}}>
                      {formik.errors.to}
                    </div>
                  </div>
                )}
                <div className='py-5 d-flex justify-content-evenly'>
                  <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting}>
                    {formik.isSubmitting && formik.isValid ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <span className='indicator-label'>Submit</span>
                    )}
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      formik.resetForm()
                      setSelectState((prevState) => {
                        return {
                          ...prevState,
                          leaveType: {label: 'Choose One', value: null},
                          status: {label: 'Choose One', value: null},
                        }
                      })
                      resetFilterData()
                    }}
                    className='btn btn-light-primary me-3'
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          )}
          <div className='dropdown'>
            <button
              type='button'
              className='btn btn-primary mb-3'
              onClick={() => openModal('create', {})}
            >
              Apply For Leave
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeLeavesHeader
