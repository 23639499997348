import {FC} from 'react'
import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {KTSVG, WithChildren} from '../../../../../_metronic/helpers'

type Props = {
  show: boolean
  handleClose: () => void
  modalTitle: string
  modalSubTitle?: string
  id: string
  modalSize?: 'default' | 'modal-sm' | 'modal-lg' | 'modal-xl'
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalComponent: FC<Props & WithChildren> = ({
  show,
  handleClose,
  children,
  modalTitle,
  modalSubTitle,
  id,
  modalSize,
}) => {
  return createPortal(
    <Modal
      id={id}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${
        modalSize ? modalSize : ''
      }`}
      show={show}
      onHide={handleClose}
      centered
      backdrop={'static'}
    >
      <div className='modal-header'>
        <div className='vstack align-items-center'>
          <div>
            <h2 className='mb-0 fs-3 fw-bolder' style={{display: 'block'}}>
              {modalTitle}
            </h2>
          </div>
          <div>
            <h6
              className='mb-0 fs-4'
              style={{
                fontWeight: 'normal',
                color: 'grey',
              }}
            >
              {modalSubTitle}
            </h6>
          </div>
        </div>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>{children}</div>
    </Modal>,
    modalsRoot
  )
}

export {ModalComponent}
