import RequestConfig from '../../../RequestConfig'
import {GET_EMPLOYEE_LEAVE} from '../../../employee_management/AttendancePage'
import {GET_USER_DETAILS} from '../../../employee_management/EmployeeDetails'

export class AppLeaveService {
  private static baseUrl: string = `${process.env.REACT_APP_API_URL}/user/leaves`

  private static token = localStorage.getItem('token')

  static async createLeave(appLeaveDetails: any): Promise<any> {
    try {
      const request = await RequestConfig.post(`${this.baseUrl}/create-leave`, appLeaveDetails, {
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async getLeaves(options: any): Promise<any> {
    try {
      const request = await RequestConfig.post(`${this.baseUrl}/get-leaves`, options, {
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async updateLeave(appLeaveDetails: any): Promise<any> {
    try {
      const request = await RequestConfig.put(`${this.baseUrl}/update-leave`, appLeaveDetails, {
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async getEmployeeLeaves(id: string): Promise<any> {
    try {
      const request = await RequestConfig.post(
        GET_EMPLOYEE_LEAVE,
        {
          appUserId: id,
        },
        {
          headers: {
            genie_access_token: `Bearer ${this.token}`,
          },
        }
      )
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async fetchEmployeeDetails(id: string): Promise<any> {
    try {
      const request = await RequestConfig.post(
        GET_USER_DETAILS,
        {
          appUserId: id,
        },
        {
          headers: {
            genie_access_token: `Bearer ${this.token}`,
          },
        }
      )
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async getEmployeeViaToken(): Promise<any> {
    try {
      const request = await RequestConfig.get(
        `${process.env.REACT_APP_API_URL}/agent/auth/getAgentByToken`,
        {
          headers: {
            genie_access_token: `Bearer ${this.token}`,
          },
        }
      )
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }
}
