import React, {useState, Dispatch, SetStateAction} from 'react'
import api from '../../../RequestConfig'

const API_URL = process.env.REACT_APP_API_URL
export const SERVE_IMAGE = `${API_URL}/template/serve-signature`

type ModalFunction = Dispatch<SetStateAction<any>>

interface SignatureContextProps {
  isCreateModalOpen: boolean
  isManageModalOpen: boolean
  isHistoryModalOpen: boolean
  searchData: any
  limitData: any
  appTemplateData: any
  signatureImgName: any
  receiverName: any
  imageSrc: string
  designationName: any
  rerenderPage: boolean
  selectAllChecked: boolean
  checkedRows: any[]
  allTemplateData: any[]
  handleTemplateData: any
  handleCheckboxChange: any
  modalFunction: ModalFunction
  manageModalFunction: ModalFunction
  modalActionFunction: ModalFunction
  modalHistoryFunction: ModalFunction
  onChangeTemplateData: ModalFunction
  onChangeSearchFunction: ModalFunction
  onChangeLimitFunction: ModalFunction
  onChangeImgNameFunction: ModalFunction
  onChangeReceiverNameFunction: ModalFunction
  onChangeDesignationFunction: ModalFunction
  onChangeDataFunction: any
  getSignatureImage: any
  resetImageValue: any
  onResetTemplateData: any
  onResetDesignationFunction: any
  onResetRecieverFunction: any
}

export const SignatureContext = React.createContext<SignatureContextProps>({
  isCreateModalOpen: false,
  isManageModalOpen: false,
  isHistoryModalOpen: false,
  searchData: '',
  appTemplateData: '',
  limitData: '',
  signatureImgName: '',
  receiverName: '',
  designationName: '',
  imageSrc: '',
  rerenderPage: false,
  checkedRows: [],
  selectAllChecked: false,
  allTemplateData: [],
  handleTemplateData: () => {},
  handleCheckboxChange: () => {},
  modalFunction: () => {}, // Default empty function
  manageModalFunction: () => {}, // Default empty function
  modalActionFunction: () => {}, // Default empty function
  modalHistoryFunction: () => {}, // Default empty function
  onChangeTemplateData: () => {}, // Default empty function
  onChangeSearchFunction: () => {}, // Default empty function
  onChangeLimitFunction: () => {}, // Default empty function
  onChangeImgNameFunction: () => {}, // Default empty function
  onChangeReceiverNameFunction: () => {}, // Default empty function
  onChangeDesignationFunction: () => {},
  getSignatureImage: () => {},
  resetImageValue: () => {},
  onChangeDataFunction: () => {},
  onResetTemplateData: () => {},
  onResetDesignationFunction: () => {},
  onResetRecieverFunction: () => {},
})

function SignatureContextProvider({children}: any) {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [isManageModalOpen, setIsManageModalOpen] = useState<boolean>(false)
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false)
  const [searchData, setSearchData] = useState<any>('')
  const [limitData, setLimitData] = useState<any>('')
  const [signatureImgName, setSignatureImgName] = useState<any>('')
  const [receiverName, setReceiverName] = useState<any>('')
  const [imageSrc, setImageSrc] = useState<string>('')
  const [designationName, setDesignationName] = useState<any>('')
  const [rerenderPage, setRerenderPage] = useState<boolean>(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [checkedRows, setCheckedRows] = useState<string[]>([])
  const [allTemplateData, setAllTemplateData] = useState<any[]>([])

  const getDefaultAppTemplateData = () => {
    return {
      appTemplateId: '',
      title: '',
      subject: '',
      description: '',
      date: '',
    }
  }

  const [appTemplateData, setAppTemplateData] = useState<any>(getDefaultAppTemplateData())

  const modalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsCreateModalOpen(value)
  }

  const manageModalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsManageModalOpen(value)
  }

  const modalHistoryFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsHistoryModalOpen(value)
  }

  const onChangeSearchFunction: ModalFunction = (event: SetStateAction<any>) => {
    setSearchData(event)
  }

  const onChangeLimitFunction: ModalFunction = (event: SetStateAction<any>) => {
    setLimitData(event)
  }

  const onChangeImgNameFunction: ModalFunction = (event: SetStateAction<any>) => {
    if (event?.target) {
      setSignatureImgName(event.target.value)
    } else {
      setSignatureImgName(event)
    }
  }

  const onChangeReceiverNameFunction: ModalFunction = (event: SetStateAction<any>) => {
    setReceiverName(event.target.value)
  }

  const modalActionFunction: ModalFunction = (value: SetStateAction<any>) => {
    setAppTemplateData({
      appTemplateId: value.appTemplateId,
      title: value.title,
      subject: value.subject,
      description: value.description,
      date: value.date,
    })
  }

  const onChangeTemplateData = (event: SetStateAction<any>) => {
    setAppTemplateData({...appTemplateData, [event.target.name]: event.target.value})
  }

  const onResetTemplateData = () => {
    // setAppTemplateData(getDefaultAppTemplateData())
    setAppTemplateData({
      appTemplateId: '',
      title: '',
      subject: '',
      description: '',
      date: '',
    })
  }

  // Get newly added signature
  const getSignatureImage = async (fileName: any) => {
    try {
      const varToken = localStorage.getItem('token')
      const result = await api.post(
        SERVE_IMAGE,
        {
          fileName,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
          responseType: 'blob',
        }
      )
      if (result.status === 200) {
        const url = URL.createObjectURL(new Blob([result.data]))
        setImageSrc(url)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const resetImageValue = () => {
    setImageSrc('')
  }

  const onChangeDesignationFunction: ModalFunction = (event: SetStateAction<any>) => {
    setDesignationName(event.target.value)
  }

  const onChangeDataFunction = () => {
    setRerenderPage(!rerenderPage)
  }

  const onResetDesignationFunction = () => {
    setDesignationName('')
  }

  const onResetRecieverFunction = () => {
    setReceiverName('')
  }

  const handleTemplateData = (value: any) => {
    setAllTemplateData(value)
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'selectAll') {
      const {checked} = e.target
      setCheckedRows(
        checked ? allTemplateData?.map((template) => template.allTemplateData._id || '') : []
      )
      setSelectAllChecked(checked)
    } else {
      const {checked, id} = e.target
      setCheckedRows((prevcheckedMenus) =>
        checked
          ? [...prevcheckedMenus, id]
          : prevcheckedMenus.filter((templateId) => templateId !== id)
      )
      setSelectAllChecked(false)
    }
  }

  return (
    <SignatureContext.Provider
      value={{
        isCreateModalOpen,
        modalFunction,
        isManageModalOpen,
        manageModalFunction,
        appTemplateData,
        modalActionFunction,
        isHistoryModalOpen,
        modalHistoryFunction,
        onChangeTemplateData,
        searchData,
        onChangeSearchFunction,
        limitData,
        onChangeLimitFunction,
        signatureImgName,
        onChangeImgNameFunction,
        receiverName,
        onChangeReceiverNameFunction,
        imageSrc,
        getSignatureImage,
        resetImageValue,
        designationName,
        onChangeDesignationFunction,
        rerenderPage,
        onChangeDataFunction,
        onResetTemplateData,
        onResetDesignationFunction,
        onResetRecieverFunction,
        selectAllChecked,
        checkedRows,
        allTemplateData,
        handleTemplateData,
        handleCheckboxChange,
      }}
    >
      {children}
    </SignatureContext.Provider>
  )
}

export default SignatureContextProvider
