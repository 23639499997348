import RequestConfig from '../../../RequestConfig'
import {GET_ALL_DEPARTMENTS, GET_ALL_USERS} from '../../../employee_management/CompanyEmployees'
import {GET_USER_DETAILS} from '../../../employee_management/EmployeeDetails'

export class AppTicketService {
  private static baseUrl: string = `${process.env.REACT_APP_API_URL}/ticketroutes`

  private static token = localStorage.getItem('token')

  static async getTickets(options: any): Promise<any> {
    try {
      const request = await RequestConfig.post(`${this.baseUrl}/get-ticket`, options, {
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async createTicket(appTicketDetails: any): Promise<any> {
    try {
      const request = await RequestConfig.post(`${this.baseUrl}/create-ticket`, appTicketDetails, {
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async updateTicket(appTicketDetails: any): Promise<any> {
    try {
      const request = await RequestConfig.put(`${this.baseUrl}/update-ticket`, appTicketDetails, {
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async deleteTicket(appTicketIds: any[], appUserId: any): Promise<any> {
    try {
      const request = await RequestConfig.delete(`${this.baseUrl}/delete-ticket`, {
        data: {
          appUserId,
          appTicketIds,
        },
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async loadDepartments(): Promise<any> {
    try {
      const request = await RequestConfig.post(
        GET_ALL_DEPARTMENTS,
        {
          search: null,
          metaData: {
            limit: -1,
          },
        },
        {
          headers: {
            genie_access_token: `Bearer ${this.token}`,
          },
        }
      )
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async loadEmployees(id: string): Promise<any> {
    try {
      const request = await RequestConfig.post(
        GET_ALL_USERS,
        {
          appDesignationId: null,
          appDepartmentId: id,
          search: null,
          metaData: {
            sortBy: null,
            sortOn: null,
            limit: -1,
            offset: null,
          },
        },
        {
          headers: {
            genie_access_token: `Bearer ${this.token}`,
          },
        }
      )
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async fetchEmployeeDetails(id: string): Promise<any> {
    try {
      const request = await RequestConfig.post(
        GET_USER_DETAILS,
        {
          appUserId: id,
        },
        {
          headers: {
            genie_access_token: `Bearer ${this.token}`,
          },
        }
      )
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async getEmployeeViaToken(): Promise<any> {
    try {
      const request = await RequestConfig.get(
        `${process.env.REACT_APP_API_URL}/agent/auth/getAgentByToken`,
        {
          headers: {
            genie_access_token: `Bearer ${this.token}`,
          },
        }
      )
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }
}
