import {createContext, useState} from 'react'
import api from '../../RequestConfig'
import {GET_ALL_DEPARTMENTS, GET_ALL_USERS} from '../../employee_management/CompanyEmployees'

type ModalState = {
  showModal: boolean
  editMode: boolean
  readOnlyMode: boolean
  appTickets: any | {}
}

type FilterState = {
  search: string
  appDepartmentId: string
  appUserIds: []
  ticketType: string
  priority: string
  status: string
}

type TicketStatistics = {
  openTickets: number
  closedTickets: number
  pendingTickets: number
  totalTickets: number
  percentOpenTickets: number
  percentClosedTickets: number
  percentPendingTickets: number
}

interface TicketContextProps {
  modalState: ModalState
  selectAllChecked: boolean
  checkedRows: string[]
  ticketDeatils: any[]
  filterOptions: FilterState
  ticketStatistics: TicketStatistics
  refreshPage: any
  limit: number
  changeLimit: any
  handleCheckboxChange: any
  resetCheckedRows: any
  onUpdateData: any
  openModal: any
  closeModal: any
  loadData: any
  loadTicketStatistics: any
  loadFilterData: any
  getAllTickets: any
  createTicket: any
  loadDepartments: any
  loadEmployees: any
}

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_TICKETS = `${API_URL}/ticketroutes/all_ticket`
const CREATE_TICKET = `${API_URL}/ticketroutes/create_ticket`

export const TicketContext = createContext<TicketContextProps>({
  modalState: {showModal: false, editMode: false, readOnlyMode: false, appTickets: {}},
  ticketDeatils: [],
  filterOptions: {
    search: '',
    appDepartmentId: '',
    appUserIds: [],
    ticketType: '',
    priority: '',
    status: '',
  },
  ticketStatistics: {
    openTickets: 0,
    closedTickets: 0,
    pendingTickets: 0,
    totalTickets: 0,
    percentOpenTickets: 0,
    percentClosedTickets: 0,
    percentPendingTickets: 0,
  },
  selectAllChecked: false,
  checkedRows: [],
  refreshPage: false,
  limit: 10,
  changeLimit: () => {},
  handleCheckboxChange: () => {},
  resetCheckedRows: () => {},
  onUpdateData: () => {},
  openModal: () => {},
  closeModal: () => {},
  loadData: () => {},
  loadTicketStatistics: () => {},
  loadFilterData: () => {},
  getAllTickets: () => {},
  createTicket: () => {},
  loadDepartments: () => {},
  loadEmployees: () => {},
})

function TicketContextProvider({children}: any) {
  const [modalState, setModalState] = useState<ModalState>({
    showModal: false,
    editMode: false,
    readOnlyMode: false,
    appTickets: {},
  })
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [checkedRows, setCheckedRows] = useState<string[]>([])
  const [ticketDeatils, setTicketDeatils] = useState<any[]>([])
  const [filterOptions, setFilterOptions] = useState<FilterState>({
    search: '',
    appDepartmentId: '',
    appUserIds: [],
    ticketType: '',
    priority: '',
    status: '',
  })
  const [ticketStatistics, setTicketStatistics] = useState<TicketStatistics>({
    openTickets: 0,
    closedTickets: 0,
    pendingTickets: 0,
    totalTickets: 0,
    percentOpenTickets: 0,
    percentClosedTickets: 0,
    percentPendingTickets: 0,
  })
  const [refreshPage, setRefreshPage] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(10)

  const changeLimit = (value: number) => {
    setLimit(value)
  }

  const openModal = (mode: 'create' | 'view' | 'edit', appTickets: any = {}) => {
    setModalState((prevState) => {
      return {
        ...prevState,
        showModal: true,
        editMode: mode === 'edit',
        readOnlyMode: mode === 'view',
        appTickets: appTickets,
      }
    })
  }
  const closeModal = () => {
    setModalState((prevState) => {
      return {
        ...prevState,
        showModal: false,
      }
    })
  }

  const loadData = (data: any[]) => {
    setTicketDeatils(data)
  }

  const loadTicketStatistics = (values: TicketStatistics) => {
    setTicketStatistics(values)
  }

  const loadFilterData = (values: FilterState) => {
    setFilterOptions(values)
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'selectAll') {
      const {checked} = e.target
      setCheckedRows(checked ? ticketDeatils?.map((ticket: any) => ticket.appTicketId || '') : [])
      setSelectAllChecked(checked)
    } else {
      const {checked, id} = e.target
      setCheckedRows((prevcheckedMenus) =>
        checked ? [...prevcheckedMenus, id] : prevcheckedMenus.filter((salaryId) => salaryId !== id)
      )
      setSelectAllChecked(false)
    }
  }

  const onUpdateData = () => {
    setRefreshPage(!refreshPage)
  }

  const resetCheckedRows = () => {
    setCheckedRows([])
    setSelectAllChecked(false)
  }

  const getAllTickets = async () => {
    const varToken = localStorage.getItem('token')
    try {
      const result = await api.post(
        GET_ALL_TICKETS,
        {
          metaData: {
            sortBy: null,
            sortOn: null,
            limit: null,
            offset: null,
            fields: null,
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      if (result?.data) {
        return result.data
      }
      return {}
    } catch (error) {
      console.error(error)
    }
  }

  const createTicket = async (appTicketDetails: any) => {
    const varToken = localStorage.getItem('token')
    try {
      const result = await api.post(CREATE_TICKET, appTicketDetails, {
        headers: {
          genie_access_token: 'Bearer ' + varToken,
        },
      })
      if (result?.data) {
        return result.data
      }
      return {}
    } catch (error) {
      console.error(error)
    }
  }

  const loadDepartments = async () => {
    const varToken = localStorage.getItem('token')
    try {
      const result = await api.post(
        GET_ALL_DEPARTMENTS,
        {
          search: null,
          metaData: {
            limit: -1,
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      if (result?.data) {
        return result.data
      }
      return {}
    } catch (err) {
      console.error(err)
    }
  }

  const loadEmployees = async (id: string) => {
    const varToken = localStorage.getItem('token')
    try {
      const result = await api.post(
        GET_ALL_USERS,
        {
          appDesignationId: null,
          appDepartmentId: id,
          search: null,
          metaData: {
            sortBy: null,
            sortOn: null,
            limit: -1,
            offset: null,
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      if (result?.data) {
        return result.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <TicketContext.Provider
      value={{
        modalState,
        filterOptions,
        ticketDeatils,
        ticketStatistics,
        selectAllChecked,
        checkedRows,
        refreshPage,
        limit,
        changeLimit,
        handleCheckboxChange,
        resetCheckedRows,
        onUpdateData,
        openModal,
        closeModal,
        loadData,
        loadTicketStatistics,
        loadFilterData,
        getAllTickets,
        createTicket,
        loadDepartments,
        loadEmployees,
      }}
    >
      {children}
    </TicketContext.Provider>
  )
}

export default TicketContextProvider
