import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MenuComponent } from "../../../../_metronic/assets/ts/components/MenuComponent";
import { PageTitle } from "../../../../_metronic/layout/core";
import { AppMenuTable } from "./AppMenuTable";
import { AppMenuModal } from "./AppMenuModel";


type ModalState = {
    showModal: boolean;
    editMode: boolean;
    readOnlyMode: boolean;
    appMenus: any | {};
};

export const AppMenu: FC = () => {
    const [modalState, setModalState] = useState<ModalState>({
        showModal: false,
        editMode: false,
        readOnlyMode: false,
        appMenus: {},
    });

    const openModal = (
        mode: "create" | "view" | "edit",
        appMenus: any = {}
    ) => {
        setModalState((prevState) => {
            return {
                ...prevState,
                showModal: true,
                editMode: mode === "edit",
                readOnlyMode: mode === "view",
                appMenus: appMenus,
            };
        });
    };
    const closeModal = () => {
        setModalState((prevState) => {
            return {
                ...prevState,
                showModal: false,
            };
        });
    };

    useEffect(() => {
        MenuComponent.reinitialization();
    }, []);

    return (
        <>
            <Helmet>
                <title> App Menu | Overview</title>
            </Helmet>
            <PageTitle
                breadcrumbs={[
                    {
                        isActive: false,
                        path: "",
                        isSeparator: true,
                        title: "Permissions",
                    },
                ]}
            >
                App Menu
            </PageTitle>
            <AppMenuTable openModal={openModal} reRenderComponent={!modalState.showModal} />
            <AppMenuModal closeModal={closeModal}
                editMode={modalState.editMode}
                readOnlyMode={modalState.readOnlyMode}
                showModal={modalState.showModal}
                appMenu={modalState.appMenus} />
        </>
    );
};