import React, {useEffect, useState} from 'react'
import api from '../RequestConfig'
import {useLocation, useNavigate} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import {UploadFileContext} from './FilesContext'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import {faEye} from '@fortawesome/free-solid-svg-icons'
import {faBan} from '@fortawesome/free-solid-svg-icons'
import {faUpload} from '@fortawesome/free-solid-svg-icons'
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import {showToast} from '../common/toastify/toastify.config'
import Swal from 'sweetalert2'
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_FILES = `${API_URL}/agent/account-files/get-files`
export const DELETE_FILE = `${API_URL}/agent/account-files/delete-file`
export const UPDATE_FILE = `${API_URL}/agent/account-files/update-file`
export const UPLOAD_SINGLE = `${API_URL}/agent/account-files/upload-single-file`

const errMsg: string = 'File must be uploaded first'
const uploadErrMsg: string = 'Upload All files to have edit access'

function EmployeeFiles() {
  // States to store the files.
  const {
    loadProfilePicture,
    loadAadharNumber,
    loadPanNumber,
    loadAadharCard,
    loadPanCard,
    loadAgentId,
    loadOtherFilesFunction,
  } = React.useContext(UploadFileContext)
  const {profilePicture, aadharNumber, panNumber, aadharCard, panCard, otherFiles} =
    React.useContext(UploadFileContext)
  const [loading, setLoading] = useState<boolean>(false)
  const {FilemodalFunction} = React.useContext(UploadFileContext)

  const location = useLocation()
  const isDisabled: boolean = (location?.state as any)?.isDisabled || false
  const id = (location?.state as any)?.id || ''

  async function delete_file(file_name: string, file_id: string) {
    const varToken = localStorage.getItem('token')
    let query: any = {}
    if (file_name === 'profile_pictureId') {
      query = {
        profile_pictureId: file_id,
      }
    } else if (file_name === 'aadhar_cardId') {
      query = {
        aadhar_cardId: file_id,
      }
    } else if (file_name === 'pan_cardId') {
      query = {
        pan_cardId: file_id,
      }
    } else if (file_name === 'otherFilesId') {
      query = {
        otherFileId: file_id,
      }
    }
    setLoading(true)
    try {
      const result = await api.post(DELETE_FILE, query, {
        headers: {
          genie_access_token: 'Bearer ' + varToken,
        },
      })
      if (result.data.error === false) {
        load_files(id)
        showToast(`${result.data.data.message}`, 'success')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  //api call to update files

  async function update_file(file: any, file_id: any, field_name: string) {
    const formData = new FormData()
    formData.append('appUserId', id)

    formData.append(field_name, file_id)
    if (field_name === 'profile_pictureId') {
      formData.append('profile_picture', file)
    }
    if (field_name === 'aadhar_cardId') {
      formData.append('aadhar_card', file)
    }
    if (field_name === 'pan_cardId') {
      formData.append('pan_card', file)
    }
    if (field_name === 'otherFilesId') {
      formData.append('otherFiles', file)
    }

    const varToken = localStorage.getItem('token')
    setLoading(true)
    try {
      const result = await api.post(
        UPDATE_FILE,
        formData,

        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      if (result.data.error === false) {
        load_files(id)
        showToast(`${result.data.data.message}`, 'success')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  //api call to update single file
  async function update_single_file(file: any, field_name: string) {
    const formData = new FormData()
    formData.append('appUserId', id)

    if (field_name === 'profile_pictureId') {
      formData.append('profile_picture', file)
    }
    if (field_name === 'aadhar_cardId') {
      formData.append('aadhar_card', file)
    }
    if (field_name === 'pan_cardId') {
      formData.append('pan_card', file)
    }
    if (field_name === 'otherFilesId') {
      formData.append('otherFiles', file)
    }

    const varToken = localStorage.getItem('token')
    setLoading(true)
    try {
      const result = await api.post(
        UPLOAD_SINGLE,
        formData,

        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      if (result.data.error === false) {
        load_files(id)
        showToast(`${result.data.data.message}`, 'success')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  //api call to get all files

  loadAgentId(id)
  useEffect(() => {
    if (!isDisabled) {
      ;(async () => {
        await load_files(id)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled])

  const navigate = useNavigate()

  useEffect(() => {
    if (isDisabled) {
      // Redirect to the home page
      navigate('/employee_management/company-employees')
      showToast(`User Not Active! Please activate user first`, 'info')
    }
  }, [isDisabled, navigate])

  async function load_files(id: any) {
    //function
    setLoading(true)
    const varToken = localStorage.getItem('token')
    try {
      const result = await api.post(
        GET_ALL_FILES,
        {
          appUserId: id,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )

      if (result?.data?.data?.appUserFiles?.length === 0) {
        loadProfilePicture({})
        loadPanCard({})
        loadAadharCard({})
        loadAadharNumber('')
        loadPanNumber('')
        // setPanCardNumber('')
        // setAadharCardNumber('')
        loadOtherFilesFunction([])
      } else {
        loadProfilePicture(result?.data?.data?.appUserFiles?.profile_picture)
        //setPanCardNumber(result.data.data.appUserFiles.pan_card_number)
        loadPanCard(result?.data?.data?.appUserFiles?.pan_card_file)
        loadAadharNumber(result?.data?.data?.appUserFiles?.aadhar_card_number)
        loadPanNumber(result?.data?.data?.appUserFiles?.pan_card_number)
        //setAadharCardNumber(result.data.data.appUserFiles.aadhar_card_number)
        loadAadharCard(result?.data?.data?.appUserFiles?.aadhar_card_file)
        loadOtherFilesFunction(result?.data?.data?.appUserFiles?.otherFiles)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function preview_file(file: any) {
    // // Server Code
    window.open(
      `${process.env.REACT_APP_FILE_SERVER_DOMAIN}/${process.env.REACT_APP_UPLOAD_FILE_PATH}${id}/documents/${file}`,
      '_blank'
    )
    // Local Code
    // window.open(`http://localhost:8000/${id}/documents/${file}`, '_blank')
  }

  return (
    <>
      {loading ? (
        <div className='d-flex align-items-center justify-content-center loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : !isDisabled ? (
        <div style={{display: 'grid'}}>
          {/* info card 01 */}
          <div className='card shadow' style={{width: '100%', margin: '0 auto'}}>
            <div className='card-body'>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <h1 style={{color: 'black'}} className='card-title'>
                    Profile Section
                  </h1>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <div className='form-group'>
                    <label
                      htmlFor='firstName'
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                      }}
                    >
                      Aaadhar Number:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em', fontWeight: 'bold'}}>
                      {aadharNumber ? aadharNumber : 'No Aadhar Card Number Uploaded'}
                    </span>
                  </div>

                  <div className='form-group' style={{marginTop: '20px'}}>
                    <label
                      htmlFor='lastName'
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                      }}
                    >
                      Pan Number:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em', fontWeight: 'bold'}}>
                      {panNumber ? panNumber : 'No Pan Card Number Uploaded'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          {/* file card 02 */}
          <div className='card shadow' style={{width: '100%', margin: '0 auto'}}>
            <div className='card-body'>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h1 style={{color: 'black'}} className='card-title'>
                  Employee Files
                </h1>
                {/* show button only if files are not uploaded */}

                {aadharNumber && !!otherFiles && (
                  <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      const fileInput = document.createElement('input')
                      fileInput.type = 'file'
                      fileInput.onchange = (event: any) => {
                        const files = event.target.files
                        update_single_file(files[0], 'otherFilesId')
                      }
                      fileInput.click()
                    }}
                  >
                    Upload Other Files
                  </button>
                )}

                {!aadharNumber && (
                  <button
                    onClick={() => {
                      FilemodalFunction(true)
                    }}
                    className='btn btn-primary btn-sm'
                  >
                    <FontAwesomeIcon icon={faUpload} size='1x' color='black' />
                  </button>
                )}
              </div>

              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  fontSize: '15px',
                }}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th scope='col'>Serial Number</th>
                    <th scope='col'>File Type</th>
                    <th scope='col'>File Names</th>
                    <th scope='col'>Delete</th>
                    <th style={{textAlign: 'center'}} scope='col'>
                      Update/Upload
                    </th>
                    <th style={{textAlign: 'right'}} scope='col'>
                      Preview
                    </th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
                  {/* profile picture row */}
                  <tr>
                    <td className='px-15'>1.</td>
                    <td>Profile Photo</td>
                    <td>
                      {profilePicture &&
                      profilePicture.original_name &&
                      profilePicture.isDeleted === false
                        ? profilePicture.original_name
                        : 'No Profile Picture Uploaded'}
                    </td>
                    <td>
                      {profilePicture &&
                      Object.keys(profilePicture).length > 0 &&
                      !profilePicture.isDeleted ? (
                        <button
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: `User's profile picture will be deleted. You won't be able to revert this!`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, delete it!',
                            }).then((result: any) => {
                              if (result.isConfirmed) {
                                delete_file('profile_pictureId', profilePicture._id)
                              }
                            })
                          }}
                          className='btn'
                          style={{color: 'red'}}
                        >
                          <FontAwesomeIcon icon={faTrash} size='1x' color='black' />
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(errMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                    <td style={{textAlign: 'center'}}>
                      {profilePicture && profilePicture.isDeleted === false && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            const fileInput = document.createElement('input')
                            fileInput.type = 'file'
                            fileInput.onchange = (event: any) => {
                              const files = event.target.files
                              update_file(files[0], profilePicture._id, 'profile_pictureId')
                            }
                            fileInput.click()
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} size='1x' color='black' />
                        </button>
                      )}
                      {/* {profilePicture && profilePicture.isDeleted === true && ( */}
                      {!profilePicture && aadharCard && panCard && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            const fileInput = document.createElement('input')
                            fileInput.type = 'file'
                            fileInput.onchange = (event: any) => {
                              const files = event.target.files
                              update_single_file(files[0], 'profile_pictureId')
                            }
                            fileInput.click()
                          }}
                        >
                          <FontAwesomeIcon icon={faUpload} size='1x' color='black' />
                        </button>
                      )}
                      {/* {profilePicture && Object.keys(profilePicture).length === 0 && ( */}
                      {!profilePicture && (!aadharCard || !panCard) && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(uploadErrMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                    <td style={{textAlign: 'right'}}>
                      {profilePicture && profilePicture.isDeleted === false ? (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            preview_file(profilePicture.name)
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} size='1x' color='black' />
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(errMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                  </tr>
                  {/* aadhar card  */}
                  <tr>
                    <td className='px-15'>2.</td>
                    <td>Aadhar Card</td>
                    <td>
                      {aadharCard && aadharCard.original_name && aadharCard.isDeleted === false
                        ? aadharCard.original_name
                        : 'No Aadhar Card Uploaded'}
                    </td>
                    <td>
                      {/* {Object.keys(aadharCard).length > 0 && !aadharCard.isDeleted ? ( */}
                      {aadharCard && Object.keys(aadharCard).length > 0 ? (
                        <button
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: `User's aadhar card will be deleted. You won't be able to revert this!`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, delete it!',
                            }).then((result: any) => {
                              if (result.isConfirmed) {
                                delete_file('aadhar_cardId', aadharCard._id)
                              }
                            })
                          }}
                          className='btn'
                          style={{color: 'red'}}
                        >
                          <FontAwesomeIcon icon={faTrash} size='1x' color='black' />
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(errMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                    <td style={{textAlign: 'center'}}>
                      {aadharCard && aadharCard.isDeleted === false && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            const fileInput = document.createElement('input')
                            fileInput.type = 'file'
                            fileInput.onchange = (event: any) => {
                              const files = event.target.files
                              update_file(files[0], aadharCard._id, 'aadhar_cardId')
                            }
                            fileInput.click()
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} size='1x' color='black' />
                        </button>
                      )}
                      {/* {!aadharCard && ( */}
                      {!aadharCard && panCard && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            const fileInput = document.createElement('input')
                            fileInput.type = 'file'
                            fileInput.onchange = (event: any) => {
                              const files = event.target.files
                              update_single_file(files[0], 'aadhar_cardId')
                            }
                            fileInput.click()
                          }}
                        >
                          <FontAwesomeIcon icon={faUpload} size='1x' color='black' />
                        </button>
                      )}
                      {/* {aadharCard && Object.keys(aadharCard).length === 0 && ( */}
                      {!aadharCard && !panCard && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(uploadErrMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                    <td style={{textAlign: 'right'}}>
                      {aadharCard && aadharCard.isDeleted === false ? (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            preview_file(aadharCard.name)
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} size='1x' color='black' />
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(errMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                  </tr>
                  {/* pan card */}
                  <tr>
                    <td className='px-15'>3.</td>
                    <td>Pan Card</td>
                    <td>
                      {panCard && panCard.original_name && panCard.isDeleted === false
                        ? panCard.original_name
                        : 'No Pan Card Uploaded'}
                    </td>
                    <td>
                      {panCard && Object.keys(panCard).length > 0 && !panCard.isDeleted ? (
                        <button
                          onClick={() => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: `User's pan card will be deleted. You won't be able to revert this!`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, delete it!',
                            }).then((result: any) => {
                              if (result.isConfirmed) {
                                delete_file('pan_cardId', panCard._id)
                              }
                            })
                          }}
                          className='btn'
                          style={{color: 'red'}}
                        >
                          <FontAwesomeIcon icon={faTrash} size='1x' color='black' />
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(errMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                    <td style={{textAlign: 'center'}}>
                      {panCard && panCard.isDeleted === false && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            const fileInput = document.createElement('input')
                            fileInput.type = 'file'
                            fileInput.onchange = (event: any) => {
                              const file = event.target.files
                              update_file(file[0], panCard._id, 'pan_cardId')
                            }
                            fileInput.click()
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} size='1x' color='black' />
                        </button>
                      )}
                      {/* {!panCard && ( */}
                      {!panCard && aadharCard && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            const fileInput = document.createElement('input')
                            fileInput.type = 'file'
                            fileInput.onchange = (event: any) => {
                              const file = event.target.files
                              update_single_file(file[0], 'pan_cardId')
                            }
                            fileInput.click()
                          }}
                        >
                          <FontAwesomeIcon icon={faUpload} size='1x' color='black' />
                        </button>
                      )}
                      {/* {panCard && Object.keys(panCard).length === 0 && ( */}
                      {!panCard && !aadharCard && (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(uploadErrMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                    <td style={{textAlign: 'right'}}>
                      {panCard && panCard.isDeleted === false ? (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => {
                            preview_file(panCard.name)
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} size='1x' color='black' />
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn'
                          onClick={() => showToast(errMsg, 'info')}
                        >
                          <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                        </button>
                      )}
                    </td>
                  </tr>
                  {/* other files */}
                  {otherFiles?.map((file: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className='px-15'>{index + 4}</td>
                        <td>Other Files</td>
                        <td>{file.original_name}</td>
                        <td>
                          {Object.keys(file).length > 0 && !file.isDeleted ? (
                            <button
                              onClick={() => {
                                Swal.fire({
                                  title: 'Are you sure?',
                                  text: `User's file will be deleted. You won't be able to revert this!`,
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonColor: '#3085d6',
                                  cancelButtonColor: '#d33',
                                  confirmButtonText: 'Yes, delete it!',
                                }).then((result: any) => {
                                  if (result.isConfirmed) {
                                    delete_file('otherFilesId', file._id)
                                  }
                                })
                              }}
                              className='btn'
                              style={{color: 'red'}}
                            >
                              <FontAwesomeIcon icon={faTrash} size='1x' color='black' />
                            </button>
                          ) : (
                            <button
                              type='button'
                              className='btn'
                              onClick={() => showToast(errMsg, 'info')}
                            >
                              <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                            </button>
                          )}
                        </td>
                        <td style={{textAlign: 'center'}}>
                          {file.isDeleted === false && (
                            <button
                              type='button'
                              className='btn'
                              onClick={() => {
                                const fileInput = document.createElement('input')
                                fileInput.type = 'file'
                                fileInput.onchange = (event: any) => {
                                  const files = event.target.files
                                  update_file(files[0], file._id, 'otherFilesId')
                                }
                                fileInput.click()
                              }}
                            >
                              {file.isDeleted !== undefined ? (
                                <FontAwesomeIcon icon={faPencilAlt} size='1x' color='black' />
                              ) : (
                                <FontAwesomeIcon icon={faUpload} size='1x' color='black' />
                              )}
                            </button>
                          )}
                          {Object.keys(file).length === 0 && (
                            <button
                              type='button'
                              className='btn'
                              onClick={() => showToast(uploadErrMsg, 'info')}
                            >
                              <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                            </button>
                          )}
                        </td>
                        <td style={{textAlign: 'right'}}>
                          {file.isDeleted === false ? (
                            <button
                              type='button'
                              className='btn'
                              onClick={() => {
                                preview_file(`otherfiles/${file.name}`)
                              }}
                            >
                              <FontAwesomeIcon icon={faEye} size='1x' color='black' />
                            </button>
                          ) : (
                            <button
                              type='button'
                              className='btn'
                              onClick={() => showToast(errMsg, 'info')}
                            >
                              <FontAwesomeIcon icon={faBan} size='1x' color='gray' />
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default EmployeeFiles
