import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MenuComponent } from "../../../../_metronic/assets/ts/components/MenuComponent";
import { PageTitle } from "../../../../_metronic/layout/core";
import { ServiceRouteTable } from "./ServiceRouteTable";
import { ServiceRouteModal } from "./RouteModal";


type ModalState = {
    showModal: boolean;
    editMode: boolean;
    readOnlyMode: boolean;
    appRoutes: any | {};
};

export const ServiceRoute: FC = () => {
    const [modalState, setModalState] = useState<ModalState>({
        showModal: false,
        editMode: false,
        readOnlyMode: false,
        appRoutes: {},
    });

    const openModal = (
        mode: "create" | "view" | "edit",
        appRoutes: any = {}
    ) => {
        setModalState((prevState) => {
            return {
                ...prevState,
                showModal: true,
                editMode: mode === "edit",
                readOnlyMode: mode === "view",
                appRoutes: appRoutes,
            };
        });
    };
    const closeModal = () => {
        setModalState((prevState) => {
            return {
                ...prevState,
                showModal: false,
            };
        });
    };

    useEffect(() => {
        MenuComponent.reinitialization();
    }, []);

    return (
        <>
            <Helmet>
                <title> ServiceRoute | Overview</title>
            </Helmet>
            <PageTitle
                breadcrumbs={[
                    {
                        isActive: false,
                        path: "",
                        isSeparator: true,
                        title: "Permissions",
                    },
                ]}
            >
                ServiceRoute
            </PageTitle>
            <ServiceRouteTable openModal={openModal} reRenderComponent={modalState.showModal === false} />
            <ServiceRouteModal closeModal={closeModal} editMode={modalState.editMode} readOnlyMode={modalState.readOnlyMode} showModal={modalState.showModal} routeDetails={modalState.appRoutes} />
        </>
    );
};