import {useContext, useEffect, useState} from 'react'
import {TicketContext} from '../../context/TicketContext'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Option} from '../../../common/globals/common.constants'
import {AppTicketService} from '../service/ticket.service'
import clsx from 'clsx'
import Select from 'react-select'
import Swal from 'sweetalert2'
import {showToast} from '../../../common/toastify/toastify.config'

type Ticket = {
  search: string
  appDepartmentId: string
  appUserIds: string[]
  ticketType: string
  priority: string
  status: string
}

const validationSchema = Yup.object().shape({
  search: Yup.string().trim(),
  appDepartmentId: Yup.string().required('Department is required'),
  appUserIds: Yup.array()
    .of(Yup.string())
    .min(1, 'Atleast one employee must be selected')
    .required('Employee is required'),
  ticketType: Yup.string()
    .oneOf(['Assigned Tickets', 'Created Tickets', 'All Tickets'])
    .required('Ticket type is required'),
  priority: Yup.string().oneOf(['Low', 'Medium', 'High']),
  status: Yup.string().oneOf(['Open', 'Closed', 'Doubt', 'Testing', 'On Hold', 'Deploy']),
})

let initialValues: Ticket = {
  search: '',
  appDepartmentId: '',
  appUserIds: [],
  ticketType: '',
  priority: '',
  status: '',
}

const ticketTypeOptions = [
  {value: 'Assigned Tickets', label: 'Assigned Tickets'},
  {value: 'Created Tickets', label: 'Created Tickets'},
  {value: 'All Tickets', label: 'All Tickets'},
]

function EmployeeTicketsHeader() {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [selectState, setSelectState] = useState<{
    appUserIds: Option[]
    appDepartmentId: Option
    ticketType: Option
  }>({
    appUserIds: [],
    appDepartmentId: {label: 'Choose One', value: null},
    ticketType: {label: 'Choose One', value: null},
  })
  const [departmentOptions, setDepartmentOptions] = useState<{value: string; label: string}[]>([])
  const [employeeOptions, setEmployeeOptions] = useState<{value: string; label: string}[]>([])

  const {openModal, loadFilterData, checkedRows, onUpdateData, resetCheckedRows, changeLimit} =
    useContext(TicketContext)

  const toggleMenu = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const filterStyle: any = {
    display: isFilterOpen ? 'block' : 'none',
    minWidth: 'auto',
    padding: '0',
    position: 'absolute',
    top: '70px',
    left: '60%',
    zIndex: '2',
  }

  const getDepartments = async () => {
    const response = await AppTicketService.loadDepartments()
    if ('data' in response && response.error === false) {
      const departments = response.data.AppDepartment
      setDepartmentOptions(
        departments?.map((department: any) => {
          return {value: department.appDepartmentId || '', label: department.name}
        })
      )
    }
  }

  const getEmployees = async (id: string) => {
    const response = await AppTicketService.loadEmployees(id)
    if ('data' in response && response.error === false) {
      const employees = response.data.AppUsers
      setEmployeeOptions(
        employees?.map((employee: any) => {
          return {value: employee.appUserId || '', label: employee?.company_email || employee.email}
        })
      )
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      loadFilterData(values)
      setSubmitting(false)
      toggleMenu()
    },
  })

  useEffect(() => {
    getDepartments()
  }, [])

  useEffect(() => {
    if (formik.values.appDepartmentId) {
      getEmployees(formik.values.appDepartmentId)
    } else {
      formik.setFieldValue('appUserIds', [])
      setSelectState((prevState) => {
        return {
          ...prevState,
          appUserIds: [],
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.appDepartmentId])

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>
        <div className='d-flex align-items-center position-relative my-1'>
          <select
            className='form-select form-select-solid mx-2'
            data-kt-select2='true'
            data-placeholder='Show records'
            defaultValue={10}
            onChange={(e) => {
              changeLimit(parseInt(e.target.value))
            }}
          >
            <option value='10'>10 Records</option>
            <option value='15'>15 Records</option>
            <option value='25'>25 Records</option>
            <option value='50'>50 Records</option>
          </select>
          {checkedRows?.length > 0 && (
            <button
              type='button'
              className='btn btn-light-danger me-3'
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: `${checkedRows.length} ${
                    checkedRows?.length > 1 ? 'tickets' : 'ticket'
                  } will be deleted. You won't be able to revert this!`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!',
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    const tokenResponse = await AppTicketService.getEmployeeViaToken()
                    const userId = tokenResponse?.User?.appUserId || ''
                    const request = await AppTicketService.deleteTicket(checkedRows, userId)
                    if ('data' in request && request.error === false) {
                      showToast(request.data.message, 'success')
                      onUpdateData()
                      resetCheckedRows()
                    }
                  }
                })
              }}
            >
              Delete
            </button>
          )}
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <h2 className='mb-0 fs-1 fw-bold'>Employee Tickets</h2>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <div className='dropdown'>
            <button
              type='button'
              className='btn btn-light-primary me-3'
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <i className='fs-5 pe-2 fa-solid fa-filter'></i>Filter
            </button>
          </div>
          {isFilterOpen && (
            <div
              className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
              data-kt-menu='true'
              style={filterStyle}
            >
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              <div className='separator border-gray-200'></div>
              <form onSubmit={formik.handleSubmit} id='ticket_filter' className='form'>
                <div className='px-7 py-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    <span>Search Tasks</span>
                  </label>
                  <input
                    type='text'
                    {...formik.getFieldProps('search')}
                    className={clsx('form-control bg-transparent py-2')}
                    placeholder='Search Tasks'
                  />
                  {formik.touched.search && formik.errors.search && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.search}</span>
                    </div>
                  )}
                </div>
                <div className='px-7 py-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    <span className={clsx({required: true})}>Select Department</span>
                  </label>
                  <Select
                    backspaceRemovesValue={true}
                    onChange={(option) => {
                      formik.setFieldValue('appDepartmentId', option?.value)
                      setSelectState((prevState) => {
                        return {
                          ...prevState,
                          appDepartmentId: option || {label: 'Choose One', value: null},
                        }
                      })
                    }}
                    value={selectState.appDepartmentId}
                    options={departmentOptions}
                    onBlur={formik.handleBlur}
                    isMulti={false}
                    isClearable={true}
                    placeholder={'Select Department'}
                  />
                  {formik.touched.appDepartmentId && formik.errors.appDepartmentId && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.appDepartmentId}</span>
                    </div>
                  )}
                </div>
                <div className='px-7 py-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    <span className={clsx({required: true})}>Select Employee</span>
                  </label>
                  <Select
                    backspaceRemovesValue={true}
                    onChange={(options) => {
                      let selectedValues: any = options?.map((option) => {
                        return option?.value
                      })
                      formik.setFieldValue('appUserIds', selectedValues || [])
                      selectedValues = options?.map((option) => {
                        return {label: option.label, value: option.value}
                      })
                      setSelectState((prevState) => {
                        return {
                          ...prevState,
                          appUserIds: selectedValues || [],
                        }
                      })
                    }}
                    value={selectState.appUserIds}
                    options={employeeOptions}
                    onBlur={formik.handleBlur}
                    isMulti={true}
                    placeholder={'Select Employees'}
                  />
                  {formik.touched.appUserIds && formik.errors.appUserIds && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.appUserIds}</span>
                    </div>
                  )}
                </div>
                <div className='px-7 py-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>
                    <span className={clsx({required: true})}>Ticket Type</span>
                  </label>
                  <Select
                    backspaceRemovesValue={true}
                    onChange={(option) => {
                      formik.setFieldValue('ticketType', option?.value)
                      setSelectState((prevState) => {
                        return {
                          ...prevState,
                          ticketType: option || {label: 'Choose One', value: null},
                        }
                      })
                    }}
                    value={selectState.ticketType}
                    options={ticketTypeOptions}
                    onBlur={formik.handleBlur}
                    isMulti={false}
                    isClearable={true}
                    placeholder={'Select Ticket Type'}
                  />
                  {formik.touched.ticketType && formik.errors.ticketType && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.ticketType}</span>
                    </div>
                  )}
                </div>
                <div className='px-7 py-5 col-12'>
                  <label
                    className={clsx('form-label fs-6 fw-bolder text-dark', {
                      required: false,
                    })}
                  >
                    Priority
                  </label>
                  <div className='fv-row row'>
                    <div className='col-4'>
                      <div data-kt-buttons='true'>
                        <label className='btn btn-sm btn-outline btn-outline-dashed btn-outline-success px-2 w-100'>
                          <div className='d-flex align-items-center justify-content-evenly'>
                            <div className='form-check form-check-custom form-check-solid form-check-success pe-1'>
                              <input
                                className='form-check-input w-15px h-15px'
                                type='radio'
                                {...formik.getFieldProps('priority')}
                                value={'Low'}
                                checked={formik.values.priority === 'Low'}
                              />
                            </div>
                            <div className='fw-bold'>Low</div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className='col-4'>
                      <div data-kt-buttons='true'>
                        <label className='btn btn-sm btn-outline btn-outline-dashed btn-outline-warning px-2 w-100'>
                          <div className='d-flex align-items-center justify-content-evenly'>
                            <div className='form-check form-check-custom form-check-solid form-check-warning pe-1'>
                              <input
                                className='form-check-input w-15px h-15px'
                                type='radio'
                                {...formik.getFieldProps('priority')}
                                value={'Medium'}
                                checked={formik.values.priority === 'Medium'}
                              />
                            </div>
                            <div className='fw-bold'>Mid</div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className='col-4'>
                      <div data-kt-buttons='true'>
                        <label className='btn btn-sm btn-outline btn-outline-dashed btn-outline-danger px-2 w-100'>
                          <div className='d-flex align-items-center justify-content-evenly'>
                            <div className='form-check form-check-custom form-check-solid form-check-danger pe-1'>
                              <input
                                className='form-check-input w-15px h-15px'
                                type='radio'
                                {...formik.getFieldProps('priority')}
                                value={'High'}
                                checked={formik.values.priority === 'High'}
                              />
                            </div>
                            <div className='fw-bold'>High</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {formik.touched.priority && formik.errors.priority && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{formik.errors.priority}</span>
                    </div>
                  )}
                </div>
                <div className='py-5 d-flex justify-content-evenly'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {formik.isSubmitting && formik.isValid ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <span className='indicator-label'>Submit</span>
                    )}
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      formik.resetForm()
                      setSelectState((prevState) => {
                        return {
                          ...prevState,
                          appUserIds: [],
                          appDepartmentId: {label: 'Choose One', value: null},
                          ticketType: {label: 'Choose One', value: null},
                        }
                      })
                    }}
                    className='btn btn-light-primary me-3'
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          )}
          <div className='dropdown'>
            <button
              type='button'
              className='btn btn-primary mb-3'
              onClick={() => openModal('create', {})}
            >
              Create New Ticket
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTicketsHeader
