import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import DepartmentContextProvider from '../modules/company_fields/context/DepartmentContext'
import DesignaitonContextProvider from '../modules/company_fields/context/DesignationContext'
import ReportingManagerContextProvider from '../modules/company_fields/context/ReportingManagerContext'
import SignatureContextProvider from '../modules/employee_management/manage_template/context/SignatureContext'
import DynamicFieldsContextProvider from '../modules/employee_management/FieldsContext'
import FilesContextProvider from '../modules/employee_management/FilesContext'
import CreateTicketMain from '../modules/tickets/CreatedTicket/CreateTicketMain'
import MyTicketMain from '../modules/tickets/MyTicket/MyTicketMain'
import SalaryContextProvider from '../modules/salary/context/SalaryContext'
import {AccessGroup} from '../modules/permissions/access_groups/AccessGroup'
import {AppMenu} from '../modules/permissions/menu/AppMenu'
import {AppSubMenu} from '../modules/permissions/sub_menu/AppSubMenu'
import {ServiceRoute} from '../modules/permissions/service_routes/ServiceRoute'
import TicketActions from '../modules/tickets/TicketActions'
import TicketContextProvider from '../modules/tickets/context/TicketContext'
import LeaveContextProvider from '../modules/leaves/context/LeaveContext'
import LeaveActions from '../modules/leaves/LeaveActions'

const PrivateRoutes = () => {
  //permission routes
  // const Menus = lazy(() => import('../modules/permissions/Menus'))
  // const SubMenus = lazy(() => import('../modules/permissions/SubMenus'))
  // const ServiceRoutes = lazy(() => import('../modules/permissions/ServiceRoutes'))
  // const AccessGroups = lazy(() => import('../modules/permissions/AccessGroups'))
  //employee management route
  const CompanyEmployees = lazy(() => import('../modules/employee_management/CompanyEmployees'))
  const EmployeeDetails = lazy(() => import('../modules/employee_management/EmployeeDetails'))
  const EditEmployeeDetails = lazy(
    () => import('../modules/employee_management/EditEmployeeDetails')
  )
  const ManageTemplatePage = lazy(
    () => import('../modules/employee_management/manage_template/ManageTemplatePage')
  )
  //const CreateEmployee = lazy(() => import('../modules/employee_management/CreateEmployee'))
  //company fields route
  const DepartmentActions = lazy(
    () => import('../modules/company_fields/Department/DepartmentActions')
  )
  const DesignationActions = lazy(
    () => import('../modules/company_fields/Designation/DesignationActions')
  )
  const ReportingManagerActions = lazy(
    () => import('../modules/company_fields/ReportingManager/ReportingManagerActions')
  )
  const CreateActions = lazy(() => import('../modules/employee_management/CreateActions'))
  const FileActions = lazy(() => import('../modules/employee_management/FileActions'))

  // Employee Workpage route
  const EmployeeWorkPage = lazy(() => import('../modules/employee_management/EmployeeWorkPage'))

  // Attendance Page Route
  const AttendancePage = lazy(() => import('../modules/employee_management/AttendancePage'))
  const EmployeeAttendance = lazy(() => import('../modules/employee_management/EmployeeAttendance'))

  // Holiday Module Route
  const HolidayCalendar = lazy(() => import('../modules/holiday/HolidayCalendar'))
  const HolidayList = lazy(() => import('../modules/holiday/HolidayList'))

  // Salary Module Route
  const SalaryActions = lazy(() => import('../modules/salary/SalaryActions'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/employee_management/company-employees' />} />
        {/* Pages */}
        <Route
          path='dashboard'
          element={<Navigate to={'/employee_management/company-employees/*'} />}
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='permissions/menus/*'
          element={
            <SuspensedView>
              <AppMenu />
            </SuspensedView>
          }
        />
        <Route
          path='permissions/sub-menus/*'
          element={
            <SuspensedView>
              <AppSubMenu />
            </SuspensedView>
          }
        />
        <Route
          path='permissions/service-routes/*'
          element={
            <SuspensedView>
              <ServiceRoute />
            </SuspensedView>
          }
        />
        <Route
          path='permissions/access-groups/*'
          element={
            <SuspensedView>
              <AccessGroup />
            </SuspensedView>
          }
        />
        <Route
          path='employee_management/company-employees/*'
          element={
            <SuspensedView>
              <CompanyEmployees />
            </SuspensedView>
          }
        />

        <Route
          path='employee_management/employee-details/*'
          element={
            <SuspensedView>
              <EmployeeDetails />
            </SuspensedView>
          }
        />
        <Route
          path='employee_management/create-employee/*'
          element={
            <DynamicFieldsContextProvider>
              <SuspensedView>
                <CreateActions />
              </SuspensedView>
            </DynamicFieldsContextProvider>
          }
        />
        <Route
          path='employee_management/employee-files/*'
          element={
            <FilesContextProvider>
              <SuspensedView>
                <FileActions />
              </SuspensedView>
            </FilesContextProvider>
          }
        />
        <Route
          path='employee_management/create-emp/*'
          element={
            <DynamicFieldsContextProvider>
              <SuspensedView>
                <CreateActions />
              </SuspensedView>
            </DynamicFieldsContextProvider>
          }
        />
        <Route
          path='employee_management/edit-details/*'
          element={
            <SuspensedView>
              <EditEmployeeDetails />
            </SuspensedView>
          }
        />
        <Route
          path='employee_management/employees-workpage/*'
          element={
            <SuspensedView>
              <EmployeeWorkPage />
            </SuspensedView>
          }
        />
        <Route
          path='employee_management/employees-attendance/*'
          element={
            <SuspensedView>
              <AttendancePage />
            </SuspensedView>
          }
        />
        <Route
          path='employee_management/employee-attendance-admin/*'
          element={
            <SuspensedView>
              <EmployeeAttendance />
            </SuspensedView>
          }
        />
        <Route
          path='employee_management/manage_template/manage-template/*'
          element={
            <SuspensedView>
              <SignatureContextProvider>
                <ManageTemplatePage />
              </SignatureContextProvider>
            </SuspensedView>
          }
        />
        <Route
          path='company_fields/department/*'
          element={
            <SuspensedView>
              <DepartmentContextProvider>
                <DepartmentActions />
              </DepartmentContextProvider>
            </SuspensedView>
          }
        />
        <Route
          path='company_fields/designation/*'
          element={
            <SuspensedView>
              <DesignaitonContextProvider>
                <DesignationActions />
              </DesignaitonContextProvider>
            </SuspensedView>
          }
        />
        <Route
          path='company_fields/reporting-managers/*'
          element={
            <SuspensedView>
              <ReportingManagerContextProvider>
                <ReportingManagerActions />
              </ReportingManagerContextProvider>
            </SuspensedView>
          }
        />
        <Route
          path='createticketmain/*'
          element={
            <SuspensedView>
              <CreateTicketMain />
            </SuspensedView>
          }
        />
        <Route
          path='myticketmain/*'
          element={
            <SuspensedView>
              <MyTicketMain />
            </SuspensedView>
          }
        />
        <Route
          path='holiday/holiday-calendar/*'
          element={
            <SuspensedView>
              <HolidayCalendar />
            </SuspensedView>
          }
        />
        <Route
          path='holiday/holiday-list/*'
          element={
            <SuspensedView>
              <HolidayList />
            </SuspensedView>
          }
        />
        <Route
          path='salary/employee-salary/*'
          element={
            <SuspensedView>
              <SalaryContextProvider>
                <SalaryActions />
              </SalaryContextProvider>
            </SuspensedView>
          }
        />
        <Route
          path='ticket/employee-tickets/*'
          element={
            <SuspensedView>
              <TicketContextProvider>
                <TicketActions />
              </TicketContextProvider>
            </SuspensedView>
          }
        />
        <Route
          path='leave/employee-leaves/*'
          element={
            <SuspensedView>
              <LeaveContextProvider>
                <LeaveActions />
              </LeaveContextProvider>
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
