import { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AUTH_LOCAL_STORAGE_KEY, AuthInit } from './modules/auth'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { REFRESH_TOKEN } from './modules/RequestConfig'
import jwtDecode from 'jwt-decode'

const App = () => {
  // eslint-disable-next-line
  const [token, setToken] = useState(localStorage.getItem('token'))

  useEffect(() => {
    let refreshTokenInterval: ReturnType<typeof setTimeout> | null = null

    const refreshAccessToken = async () => {
      try {
        const request = await axios.post(REFRESH_TOKEN, {}, { withCredentials: true })
        const response = request.data
        localStorage.setItem('token', response.data.jwtToken)
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(response.data))
      } catch (error) {
        console.error(`ERROR WHILE REFRESHING THE TOKEN: ${error}`)
      }
    }

    const checkTokenExpiration = async () => {
      try {
        const token = localStorage.getItem('token')
        const oneMinuteInMilliSec = 60000
        if (token) {
          const now = Date.now()
          const decoded: any = jwtDecode(token)
          const expirationTime = decoded.exp * 1000
          if (expirationTime > now) {
            const timeTillRefresh = expirationTime - now - oneMinuteInMilliSec
            if (timeTillRefresh < 0) {
              await refreshAccessToken()
            } else {
              refreshTokenInterval = setTimeout(refreshAccessToken, timeTillRefresh)
            }
          }
        }
      } catch (error) {
        console.error(`REFRESH TOKEN INTERVAL ERROR: ${error}`)
      }
    }

    checkTokenExpiration()

    return () => {
      if (refreshTokenInterval) clearTimeout(refreshTokenInterval)
    }
  }, [token])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer />
    </Suspense>
  )
}

export { App }
