/* eslint-disable jsx-a11y/anchor-is-valid */
import {ReactNode, useContext, useEffect, useState} from 'react'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {Spinner} from 'react-bootstrap'
import {TicketContext} from '../../context/TicketContext'
import {AppTicketService} from '../service/ticket.service'
import {IST_FORMAT, sortObj} from '../../../common/globals/common.constants'
import {format} from 'date-fns'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {showToast} from '../../../common/toastify/toastify.config'
import {PaginationComponent} from '../../../common/components/pagination/PaginationComponent'
import Swal from 'sweetalert2'

type RowState = {
  [key: number]: boolean
}

interface TicketUpdateValue {
  completedPercent?: string
  priority?: string
  status?: string
}

const percentCompletedOptions: number[] = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const priorityOptions: string[] = ['Low', 'Medium', 'High']
const statusOptions: string[] = ['Open', 'Closed', 'Doubt', 'Testing', 'On Hold', 'Deploy']

function EmployeeTicketsTable() {
  const location = useLocation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isActionOpen, setIsActionOpen] = useState<RowState>({})
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get('page'))

  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    showingFrom: 1,
    showingTill: 10,
    page: page ? page : 1,
  })

  const [currentPage, setCurrentPage] = useState(paginationState.page)

  const [sortState, setSortState] = useState<sortObj>({
    sortBy: null,
    sortOn: null,
  })

  const {
    ticketDeatils,
    openModal,
    loadTicketStatistics,
    loadData,
    filterOptions,
    selectAllChecked,
    checkedRows,
    handleCheckboxChange,
    refreshPage,
    onUpdateData,
    limit,
  } = useContext(TicketContext)

  const onPageChange = (page: any) => {
    const currentPage = page.selected + 1
    setPaginationState((previousState) => {
      return {...previousState, page: currentPage}
    })
    // setFilterOptions((prevState: any) => {
    //   return {...prevState, page: currentPage}
    // })
    // const params = createSearchParams({page: currentPage})
    // navigate({pathname: window.location.pathname, search: `?${params}`}, {replace: true})
  }

  useEffect(() => {
    if (filterOptions.appDepartmentId) {
      ;(async () => {
        setIsLoading(true)
        const tokenResponse = await AppTicketService.getEmployeeViaToken()
        const userId = tokenResponse?.User?.appUserId || ''
        let page = paginationState.page
        let offset = (page - 1) * paginationState.itemsPerPage
        const response = await AppTicketService.getTickets({
          appUserId: userId || null,
          search: filterOptions.search ? filterOptions.search : null,
          appDepartmentId: filterOptions.appDepartmentId,
          appUserIds: filterOptions.appUserIds ? filterOptions.appUserIds : [],
          priority: filterOptions.priority ? filterOptions.priority : null,
          status: filterOptions.status ? filterOptions.status : null,
          ticketType: filterOptions.ticketType ? filterOptions.ticketType : 'All Tickets',
          metaData: {
            sortBy: sortState?.sortBy,
            sortOn: sortState?.sortOn,
            limit,
            offset,
            fields: null,
          },
        })
        if ('data' in response && response.error === false) {
          loadData(response.data)
          if ('ticketStatistics' in response) {
            loadTicketStatistics(response.ticketStatistics)
          }
          const totalRecords = response.metaData.total_records || 0
          setTotalRecords(totalRecords)
          const currentPage = paginationState.page
          const itemsPerPage = paginationState.itemsPerPage
          let showingFrom = (currentPage - 1) * itemsPerPage + 1
          let showingTill: any
          if (totalRecords) showingTill = Math.min(totalRecords, currentPage * itemsPerPage)
          setPaginationState((prevState) => {
            return {
              ...prevState,
              showingFrom: showingFrom,
              showingTill: showingTill,
            }
          })
        }
        setIsLoading(false)
      })()
    } else {
      ;(async function getDepartmenFromId() {
        const tokenResponse = await AppTicketService.getEmployeeViaToken()
        const userId = tokenResponse?.User?.appUserId || ''
        let page = paginationState.page
        let offset = (page - 1) * paginationState.itemsPerPage
        const response = await AppTicketService.fetchEmployeeDetails(userId)
        if ('data' in response && response.error === false) {
          if ('company_details' in response.data) {
            const departmentId = response?.data?.company_details._id || ''
            if (departmentId) {
              setIsLoading(true)
              const response = await AppTicketService.getTickets({
                appUserId: userId || null,
                search: filterOptions.search ? filterOptions.search : null,
                appDepartmentId: filterOptions.appDepartmentId
                  ? filterOptions.appDepartmentId
                  : departmentId,
                appUserIds:
                  filterOptions.appUserIds.length > 0 ? filterOptions.appUserIds : [userId],
                priority: filterOptions.priority ? filterOptions.priority : null,
                status: filterOptions.status ? filterOptions.status : null,
                ticketType: filterOptions.ticketType ? filterOptions.ticketType : 'All Tickets',
                metaData: {
                  sortBy: null,
                  sortOn: null,
                  limit,
                  offset,
                  fields: null,
                },
              })
              if ('data' in response && response.error === false) {
                loadData(response.data)
                if ('ticketStatistics' in response) {
                  loadTicketStatistics(response.ticketStatistics)
                }
                setIsLoading(false)
                const totalRecords = response.metaData.total_records || 0
                setTotalRecords(totalRecords)
                const currentPage = paginationState.page
                const itemsPerPage = paginationState.itemsPerPage
                let showingFrom = (currentPage - 1) * itemsPerPage + 1
                let showingTill: any
                if (totalRecords) showingTill = Math.min(totalRecords, currentPage * itemsPerPage)
                setPaginationState((prevState) => {
                  return {
                    ...prevState,
                    showingFrom: showingFrom,
                    showingTill: showingTill,
                  }
                })
              }
              setIsLoading(false)
            }
          }
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterOptions.search,
    filterOptions.appDepartmentId,
    filterOptions.appUserIds,
    filterOptions.priority,
    filterOptions.status,
    filterOptions.ticketType,
    refreshPage,
    limit,
  ])

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search)
  //   const filter: any = {
  //     isAdministrator: Boolean(params.get('isAdministrator')) || null,
  //     search: params.get('search') || null,
  //     page: Number(params.get('page')) || currentPage,
  //     metaData: {
  //       limit: Number(JSON.parse(params.get('metaData') || '{}')?.limit) || 0,
  //       offset: Number(JSON.parse(params.get('metaData') || '{}')?.offset) || 0,
  //       sortBy: params.get('sortBy') || null,
  //       sortOn: params.get('sortOn') || null,
  //     },
  //   }
  //   //  setFilterOptions((prevState: any) => {
  //   //    return {...prevState, ...filter}
  //   //  })
  // }, [location.pathname])

  // useEffect(() => {
  //   ;(async () => {
  //     const response = await AppTicketService.getTickets({
  //       filterOptions,
  //       metaData: {
  //         sortBy: null,
  //         sortOn: null,
  //         limit: null,
  //         offset: null,
  //         fields: null,
  //       },
  //     })
  //     if ('data' in response && response.error === false) {
  //       loadData(response.data)
  //     }
  //   })()
  // }, [modalState, filterOptions])

  // const updateCompletedPercent = async (ticket: any, value: string) => {
  //   const userDetails = JSON.parse(localStorage.getItem('kt-auth-react-v') || '{}')
  //   const userId = userDetails?.data?.appUserAccDetails?.appUserId || ''
  //   const data = parseInt(value)
  //   const request = await AppTicketService.updateTicket({
  //     appUserId: userId,
  //     appTicketId: ticket.appTicketId,
  //     task: ticket.task,
  //     description: ticket.description,
  //     completedPercent: data,
  //     appDepartmentId: ticket.appDepartmentId,
  //     assignedTo: ticket.assignedTo,
  //     assignedBy: ticket.assignedBy,
  //     startDate: ticket.startDate,
  //     endDate: ticket.endDate,
  //     priority: ticket.priority,
  //     status: ticket.status,
  //   })
  //   if ('data' in request && request?.error === false) {
  //     if ('message' in request.data) {
  //       showToast(request.data.message, 'success')
  //       onUpdateData()
  //     }
  //   }
  // }

  const updateTicketData = async (ticket: any, value: TicketUpdateValue) => {
    const tokenResponse = await AppTicketService.getEmployeeViaToken()
    const userId = tokenResponse?.User?.appUserId || ''
    const {completedPercent, priority, status} = value
    const request = await AppTicketService.updateTicket({
      appUserId: userId,
      appTicketId: ticket.appTicketId,
      task: ticket.task,
      description: ticket.description,
      completedPercent: 'completedPercent' in value ? completedPercent : ticket.completedPercent,
      appDepartmentId: ticket.appDepartmentId,
      assignedTo: ticket.assignedTo,
      assignedBy: ticket.assignedBy,
      startDate: ticket.startDate,
      endDate: ticket.endDate,
      priority: 'priority' in value ? priority : ticket.priority,
      status: 'status' in value ? status : ticket.status,
    })
    if ('data' in request && request?.error === false) {
      if ('message' in request.data) {
        showToast(request.data.message, 'success')
        onUpdateData()
      }
    }
  }

  const toggleAction = (rowId: number) => {
    // Toggle the visibility state for the specified row
    setIsActionOpen((prevIsActionOpen) => ({
      ...prevIsActionOpen,
      [rowId]: !prevIsActionOpen[rowId] || false,
    }))
  }

  // Disable the action button if any action button is open
  const isAnyActionOpen = Object.values(isActionOpen).some((isOpen) => isOpen)

  return (
    <KTCardBody>
      {isLoading ? (
        <div className='d-flex align-items-center justify-content-center loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : (
        <>
          <table
            className='table align-middle table-row-dashed table-responsive fs-6 gy-5'
            id='ticketTable'
          >
            <thead>
              <tr
                className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0 sticky-top'
                style={{backgroundColor: 'white', zIndex: '1'}}
              >
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      id='selectAll'
                      className='form-check-input'
                      type='checkbox'
                      value='selectAll'
                      checked={selectAllChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </th>
                <th className='min-w-200px'>Task</th>
                <th className='text-center min-w-100px'>Assigned By</th>
                <th className='text-center min-w-150px'>Assigned To</th>
                <th className='text-center min-w-70px'>Priority</th>
                <th className='text-center min-w-100px'>Start Date</th>
                <th className='text-center min-w-100px'>End Date</th>
                <th className='text-center min-w-100px'>% Completed</th>
                <th className='text-center min-w-70px'>Status</th>
                <th className='text-center min-w-20px'></th>
              </tr>
            </thead>
            <tbody>
              {ticketDeatils?.map((ticket: any, index: number) => (
                <tr key={ticket.appTicketId}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        id={ticket.appTicketId}
                        className='form-check-input selectBox'
                        type='checkbox'
                        checked={checkedRows.includes(ticket.appTicketId || '')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </td>
                  <td className='mw-200px cursor-pointer'>
                    <span
                      className='badge badge-light-dark text-hover-primary text-gray-700 mb-1'
                      style={{fontSize: 'medium'}}
                      onClick={() => openModal('view', ticket)}
                    >
                      {`${
                        ticket.task.length > 20
                          ? ticket.task?.substring(0, 20) + '...'
                          : ticket.task?.substring(0, 20)
                      }`}
                    </span>
                  </td>
                  <td className='mw-100px text-center text-truncate'>{ticket.creator}</td>
                  <td className='mw-150px text-center'>
                    <select
                      className='form-select form-select-solid w-100'
                      defaultValue={ticket?.assignees[0]}
                      data-placeholder='Assigned To'
                    >
                      {ticket?.assignees &&
                        ticket?.assignees?.map((user: string, index: number) => (
                          <option disabled key={index} value={user}>
                            {user}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td className='mw-85px text-center text-truncate'>
                    <div className='row'>
                      <div className='col-7 ps-1 mt-3'>
                        <span
                          className={`badge ${
                            ticket.priority === 'Low'
                              ? 'badge-success'
                              : ticket.priority === 'Medium'
                              ? 'badge-warning'
                              : 'badge-danger'
                          }`}
                        >
                          {ticket.priority}
                        </span>
                      </div>
                      <div className='col-5 ps-1'>
                        <select
                          style={{height: '3px', width: '3px', cursor: 'pointer'}}
                          className='form-select border-gray-100 my-4'
                          onChange={(e) => updateTicketData(ticket, {priority: e.target.value})}
                          name='sendto'
                          defaultValue={'DEFAULT'}
                          aria-label='Default select example'
                        >
                          {priorityOptions?.map((priority: string, index: number) => (
                            <option key={index} value={priority}>
                              {priority}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className='mw-100px text-center text-truncate'>
                    {ticket.startDate
                      ? format(new Date(ticket.startDate), IST_FORMAT).split(',')[0]
                      : ('' as ReactNode)}
                  </td>
                  <td className='mw-100px text-center text-truncate'>
                    {ticket.endDate
                      ? format(new Date(ticket.endDate), IST_FORMAT).split(',')[0]
                      : ('' as ReactNode)}
                  </td>
                  <td className='mw-100px text-center text-truncate'>
                    <div className='row'>
                      <div className='col-8 pe-1'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>
                            {ticket.completedPercent}%
                          </span>
                        </div>
                        <div className='d-flex'>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-success'
                              role='progressbar'
                              style={{width: `${ticket.completedPercent}%`}}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className='col-4 ps-1'>
                        <select
                          style={{height: '7px', width: '6px', cursor: 'pointer'}}
                          className='form-select border-gray-100 my-4'
                          onChange={(e) =>
                            updateTicketData(ticket, {completedPercent: e.target.value})
                          }
                          name='sendto'
                          defaultValue={'DEFAULT'}
                          aria-label='Default select example'
                        >
                          {percentCompletedOptions?.map((num: number, index: number) => (
                            <option key={index} value={num}>{`${num}%`}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className='mw-85px text-center text-truncate'>
                    <div className='row'>
                      <div className='col-7 ps-1 mt-3'>
                        <span
                          className={`badge ${
                            ticket.status === 'Open'
                              ? 'badge-success'
                              : ticket.status === 'Doubt'
                              ? 'badge-warning'
                              : ticket.status === 'Testing'
                              ? 'badge-info'
                              : ticket.status === 'On Hold'
                              ? 'badge-secondary'
                              : ticket.status === 'Deploy'
                              ? 'badge-primary'
                              : 'badge-danger'
                          }`}
                        >
                          {ticket.status}
                        </span>
                      </div>
                      <div className='col-5 ps-1'>
                        <select
                          style={{height: '3px', width: '3px', cursor: 'pointer'}}
                          className='form-select border-gray-100 my-4'
                          onChange={(e) => updateTicketData(ticket, {status: e.target.value})}
                          name='sendto'
                          defaultValue={'DEFAULT'}
                          aria-label='Default select example'
                        >
                          {statusOptions?.map((status: string, index: number) => (
                            <option key={index} value={status}>
                              {status}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className='mw-20px text-center' style={{position: 'relative'}}>
                    <button
                      className='btn btn-light btn-active-light-primary btn-sm'
                      disabled={!isActionOpen[index] && isAnyActionOpen}
                      onClick={() => toggleAction(index)}
                      style={{transition: 'background-color 0.3s ease'}}
                    >
                      {/* <i className='fa-solid fa-chevron-down ms-3'></i> */}
                      <i className='fa-solid fa-ellipsis-vertical ms-3'></i>
                      {/* <KTSVG
                        path='media/icons/duotune/general/gen053.svg'
                        className='svg-icon-muted svg-icon-2hx'
                      /> */}
                    </button>
                    {isActionOpen && (
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                        style={{
                          display: isActionOpen[index] ? 'block' : 'none',
                          position: 'absolute',
                          left: '-268%',
                          top: `${index === 0 ? '80%' : '-165%'}`,
                          transition: 'opacity 0.3s ease, transform 0.3s ease',
                          opacity: isActionOpen[index] ? 1 : 0,
                          transform: isActionOpen[index] ? 'translateY(0)' : 'translateY(-10px)',
                          zIndex: 2,
                        }}
                      >
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3'
                            onClick={() => {
                              openModal('view', ticket)
                              toggleAction(index)
                            }}
                          >
                            View
                          </a>
                        </div>
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3'
                            onClick={() => {
                              openModal('edit', ticket)
                              toggleAction(index)
                            }}
                          >
                            Edit
                          </a>
                        </div>
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3 text-danger'
                            onClick={() => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: `Selected ticket will be deleted. You won't be able to revert this!`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!',
                              }).then(async (result: any) => {
                                if (result.isConfirmed) {
                                  const tokenResponse = await AppTicketService.getEmployeeViaToken()
                                  const userId = tokenResponse?.User?.appUserId || ''
                                  const request = await AppTicketService.deleteTicket(
                                    [ticket.appTicketId],
                                    userId
                                  )
                                  if ('data' in request && request.error === false) {
                                    showToast(request.data.message, 'success')
                                    onUpdateData()
                                  }
                                }
                              })
                              toggleAction(index)
                            }}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {ticketDeatils.length === 0 && (
            <div
              className='d-flex align-items-center justify-content-center'
              style={{height: '100px'}}
            >
              <h2>No Data Found</h2>
            </div>
          )}
          <div className='d-flex justify-content-between mt-5'>
            <PaginationComponent
              onPageChange={onPageChange}
              pageCount={Math.ceil(totalRecords / paginationState.itemsPerPage)}
              showingFrom={paginationState.showingFrom}
              showingTill={paginationState.showingTill}
              totalRecords={totalRecords}
              currentPage={currentPage}
            />
          </div>
        </>
      )}
    </KTCardBody>
  )
}

export default EmployeeTicketsTable
