import {createContext, useState} from 'react'

type ModalState = {
  showModal: boolean
  editMode: boolean
  readOnlyMode: boolean
  appLeaves: any | {}
}

type FilterState = {
  search: string
  appUserId: string
  leaveType: string
  status: string
  from: Date | null
  to: Date | null
}

interface LeaveContextProps {
  modalState: ModalState
  selectAllChecked: boolean
  checkedRows: string[]
  leaveDetails: any[]
  filterOptions: FilterState
  limit: number
  refreshPage: boolean
  openModal: any
  closeModal: any
  loadFilterData: any
  changeLimit: any
  loadData: any
  handleCheckboxChange: any
  reloadPage: any
  resetFilterData: any
}

export const LeaveContext = createContext<LeaveContextProps>({
  modalState: {showModal: false, editMode: false, readOnlyMode: false, appLeaves: {}},
  leaveDetails: [],
  selectAllChecked: false,
  checkedRows: [],
  filterOptions: {
    search: '',
    appUserId: '',
    leaveType: '',
    status: '',
    from: null,
    to: null,
  },
  limit: 10,
  refreshPage: false,
  openModal: () => {},
  closeModal: () => {},
  loadFilterData: () => {},
  changeLimit: () => {},
  loadData: () => {},
  handleCheckboxChange: () => {},
  reloadPage: () => {},
  resetFilterData: () => {},
})

function LeaveContextProvider({children}: any) {
  const [modalState, setModalState] = useState<ModalState>({
    showModal: false,
    editMode: false,
    readOnlyMode: false,
    appLeaves: {},
  })
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [checkedRows, setCheckedRows] = useState<string[]>([])
  const [leaveDetails, setLeaveDeatils] = useState<any[]>([])
  const [filterOptions, setFilterOptions] = useState<FilterState>({
    search: '',
    appUserId: '',
    leaveType: '',
    status: '',
    from: null,
    to: null,
  })
  const [limit, setLimit] = useState<number>(10)
  const [refreshPage, setRefreshPage] = useState<boolean>(false)

  const openModal = (mode: 'create' | 'view' | 'edit', appLeaves: any = {}) => {
    setModalState((prevState) => {
      return {
        ...prevState,
        showModal: true,
        editMode: mode === 'edit',
        readOnlyMode: mode === 'view',
        appLeaves: appLeaves,
      }
    })
  }
  const closeModal = () => {
    setModalState((prevState) => {
      return {
        ...prevState,
        showModal: false,
      }
    })
  }

  const loadFilterData = (values: FilterState) => {
    setFilterOptions(values)
  }

  const changeLimit = (value: number) => {
    setLimit(value)
  }

  const loadData = (data: any[]) => {
    setLeaveDeatils(data)
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'selectAll') {
      const {checked} = e.target
      setCheckedRows(checked ? leaveDetails?.map((leave: any) => leave._id || '') : [])
      setSelectAllChecked(checked)
    } else {
      const {checked, id} = e.target
      setCheckedRows((prevcheckedMenus) =>
        checked ? [...prevcheckedMenus, id] : prevcheckedMenus.filter((leaveId) => leaveId !== id)
      )
      setSelectAllChecked(false)
    }
  }

  const reloadPage = () => {
    setRefreshPage((prevState) => !prevState)
  }

  const resetFilterData = () => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        search: '',
        appUserId: '',
        leaveType: '',
        status: '',
        from: null,
        to: null,
      }
    })
  }

  return (
    <LeaveContext.Provider
      value={{
        modalState,
        selectAllChecked,
        checkedRows,
        leaveDetails,
        filterOptions,
        limit,
        refreshPage,
        openModal,
        closeModal,
        loadFilterData,
        changeLimit,
        loadData,
        handleCheckboxChange,
        reloadPage,
        resetFilterData,
      }}
    >
      {children}
    </LeaveContext.Provider>
  )
}

export default LeaveContextProvider
