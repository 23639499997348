import RequestConfig from '../../RequestConfig'

export class ServiceRouteService {
  private static baseUrl: string = `${process.env.REACT_APP_API_URL}/permission/service-route`

  private static token = localStorage.getItem('token')

  static async getServiceRoutes(options?: any): Promise<any> {
    try {
      const request = await RequestConfig.post(`${this.baseUrl}/get-route`, options, {
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async createServiceRoute(serviceRouteDetails: any): Promise<any> {
    try {
      const request = await RequestConfig.post(
        `${this.baseUrl}/create-route`,
        serviceRouteDetails,
        {
          headers: {
            genie_access_token: `Bearer ${this.token}`,
          },
        }
      )
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async updateServiceRoute(serviceRouteDetails: any): Promise<any> {
    try {
      const request = await RequestConfig.put(`${this.baseUrl}/update-route`, serviceRouteDetails, {
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }

  static async deleteServiceRoutes(appRouteIds: string[]): Promise<any> {
    try {
      const request = await RequestConfig.delete(`${this.baseUrl}/delete-route`, {
        data: {appRouteIds},
        headers: {
          genie_access_token: `Bearer ${this.token}`,
        },
      })
      const response = request.data
      return response
    } catch (error: any) {
      return error?.response
    }
  }
}
