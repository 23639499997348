import React, {useEffect, useState, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import axios from 'axios'
import {showToast} from '../../common/toastify/toastify.config'
import {KTSVG} from '../../../../_metronic/helpers'
import api from '../../RequestConfig'
import {user_id} from './modals/create_ticket'
const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_TOKEN = `${API_URL}/agent/auth/getAgentByToken`
const FormData = require('form-data')

const initialValues = {
  subject: '',
  content: '',
  priority: '',
  category: '',
  status: '',
}

const registrationSchema = Yup.object().shape({
  subject: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 15 symbols')
    .required('Subject name is required'),
  priority: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Priority name is required'),
  category: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Category name is required'),
})

const CreateNewTicket = (props: user_id) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (side: any, open: any) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    setState({...state, [side]: open})
  }

  const userIdRef = useRef('')

  // let userOptions = [{ value: '', label: '' }];
  const [userOptions, setUserOptions] = useState([{value: '', label: ''}])
  const [myfile, setMyfile] = useState({attachFile: {name: '', size: 0}})
  const [selectedOptions, setSelectedOptions] = useState([{value: '', label: ''}])
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        let response1
        const formdata = new FormData()

        if (myfile.attachFile.name.length !== 0) {
          if (Number(myfile.attachFile?.size) > 25000000) {
            showToast('File size should not exceed 25MB', 'error')
            return
          }
          formdata.append('myfile', myfile.attachFile, myfile.attachFile.name)
          formdata.append('From', userIdRef.current)
          let url = `${API_URL}/ticketroutes/upload_file`
          try {
            response1 = await axios.post(url, formdata)
          } catch (e) {}
        }
        if (selectedOptions.length === 0 || !selectedOptions[0].label) {
          showToast('Choose one assign user', 'error')
          return
        }
        let all_send_to = []
        for (let i = 0; i < selectedOptions.length; i++) {
          all_send_to.push(selectedOptions[i].value)
        }
        const response = await fetch(`${API_URL}/ticketroutes/create_ticket`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subject: values.subject,
            content: values.content,
            From: userIdRef.current,
            To: all_send_to,
            appTicketPriorityId: values.priority,
            appTicketCategoryId: values.category,
            file_id: response1?.data?.data?.attachmentDetailId,
          }),
        })
        const json = await response.json()
        // eslint-disable-next-line eqeqeq
        if (json.message == 'success') {
          toggleDrawer('right', false)
          window.location.reload()
          showToast('Ticket Created Successfully', 'success')
        } else {
          showToast(`Ticket Creation Failed, ${json.error.message}`, 'error')
        }
      } catch (error) {
        console.error(error)
        setSubmitting(false)
      }
    },
  })

  const postUpload = (e: any) => {
    setMyfile({...myfile, attachFile: e.target.files[0]})
  }

  const [priority, setPriority] = useState([{_id: '', name: ''}])
  const [category, setCategory] = useState([{_id: '', name: ''}])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState([{_id: '', name: ''}])
  const getAllPriority = async () => {
    const response = await fetch(`${API_URL}/ticketroutes/get_priority`, {
      method: 'Get',
    })
    const json_temp = await response.json()
    const json = json_temp.data
    let priority_array = []
    for (let i = 0; i < json.all_priority.length; i++) {
      priority_array.push({_id: json.all_priority[i]._id, name: json.all_priority[i].name})
    }
    setPriority(priority_array)
  }

  const getAllCategory = async () => {
    const response = await fetch(`${API_URL}/ticketroutes/get_category`, {
      method: 'Get',
    })
    const json_temp = await response.json()
    const json = json_temp.data
    let category_array = []
    for (let i = 0; i < json.all_category.length; i++) {
      category_array.push({_id: json.all_category[i]._id, name: json.all_category[i].name})
    }
    setCategory(category_array)
  }

  const getAllStatus = async () => {
    const response = await fetch(`${API_URL}/ticketroutes/get_status`, {
      method: 'Get',
    })
    const json_temp = await response.json()
    const json = json_temp.data
    let status_array = []
    for (let i = 0; i < json.all_status.length; i++) {
      status_array.push({_id: json.all_status[i]._id, name: json.all_status[i].name})
    }
    setStatus(status_array)
  }

  const getAllUser = async () => {
    const response = await fetch(`${API_URL}/ticketroutes/get_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        loginUserId: userIdRef.current,
      }),
    })
    const json_temp = await response.json()
    const json = json_temp.data
    setUserOptions(json.allUsers)
  }

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOptions(selectedOption)
  }

  useEffect(() => {
    const set_user_id = async () => {
      const varToken = localStorage.getItem('token')
      try {
        const result = await api.get(GET_USER_BY_TOKEN, {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        })
        userIdRef.current = result.data.User.appUserId
        getAllPriority()
        getAllCategory()
        getAllStatus()
        getAllUser()
      } catch (err: any) {
        console.error(err)
      }
    }
    set_user_id()
  }, [])
  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_1'
      >
        Create Ticket
      </button>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content' style={{width: '740px'}}>
            <div className='modal-header' style={{marginLeft: '260px'}}>
              <h1 className='mx-2 my-5'>Create Ticket</h1>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div style={{width: '700px'}} role='presentation'>
                <div style={{width: '550px', marginLeft: '60px'}}>
                  <form onSubmit={formik.handleSubmit} id='kt_sign_up_submit' noValidate>
                    <div className='mb-10'>
                      <label className='required fw-bold fs-6 mb-2'>Subject</label>
                      <input
                        {...formik.getFieldProps('subject')}
                        type='text'
                        id='subject'
                        name='subject'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        placeholder='Enter Subject'
                      />
                      {formik.touched.subject && formik.errors.subject && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.subject}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='mb-10'>
                      <label className='fw-bold fs-6 mb-2'>Content</label>
                      <textarea
                        {...formik.getFieldProps('content')}
                        id='content'
                        rows={2}
                        name='content'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        placeholder='Enter Content'
                      ></textarea>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group Priority and Category */}
                    <div className='row fv-row mb-38'>
                      <div className='col-xl-6'>
                        <div className='mb-10'>
                          <label className='required fw-bold fs-6 mb-2'>Priority</label>
                          <select
                            className='form-control form-control-solid mb-3 mb-lg-0t my-3'
                            {...formik.getFieldProps('priority')}
                            name='priority'
                            defaultValue={'DEFAULT'}
                            aria-label='Default select example'
                          >
                            <option value=''>Choose Priority</option>
                            {priority.map((_priority) => (
                              <option key={_priority._id} value={_priority._id}>
                                {_priority.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.priority && formik.errors.priority && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.priority}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-xl-6'>
                        <div className='mb-10'>
                          <label className='required fw-bold fs-6 mb-2'>Category</label>
                          <select
                            className='form-control form-control-solid mb-3 mb-lg-0 my-3'
                            {...formik.getFieldProps('category')}
                            name='category'
                            defaultValue={'DEFAULT'}
                            aria-label='Default select example'
                          >
                            <option value=''>Choose Category</option>
                            {category.map((_category) => (
                              <option key={_category._id} value={_category._id}>
                                {_category.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.category && formik.errors.category && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.category}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group Status and SendTo */}
                    <div className='row fv-row mb-38 my-5'>
                      <div className='col-xl-6'>
                        <div className='mb-10 my-5'>
                          <div className='frame' style={{width: '550px'}}>
                            <div className='center'>
                              <div className='title'>
                                <label className='fw-bold fs-6 mb-2'>Attachment</label>
                              </div>

                              <div className='dropzone'>
                                <p className=''>Maximum file Size 25MB</p>
                                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                <img
                                  src='http://100dayscss.com/codepen/upload.svg'
                                  className='upload-icon my-1 mx-2'
                                />
                                <input type='file' onChange={postUpload} id='post' name='post' />
                              </div>

                              {/* <button type="button" className="btn" name="uploadbutton">Upload file</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group Status and SendTo */}
                    <div className='mb-10 my-5'>
                      <label className='required fw-bold fs-6 mb-2'>Choose Assign User</label>
                      <Select
                        isMulti
                        name='name'
                        onChange={handleSelectChange}
                        options={userOptions}
                        className='basic-multi-select'
                        classNamePrefix='select'
                      />
                    </div>
                    {/* end::Form group */}
                    <div className='modal-footer' style={{marginRight: '120px'}}>
                      <button type='button' className='btn btn-danger mx-4' data-bs-dismiss='modal'>
                        Cancel
                      </button>
                      <button id='kt_sign_up_submit' type='submit' className='btn btn-primary'>
                        Create Ticket
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateNewTicket
