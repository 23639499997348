import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {showToast} from './common/toastify/toastify.config'

const API_URL = process.env.REACT_APP_API_URL
export const REFRESH_TOKEN = `${API_URL}/agent/auth/refresh`

const api = axios.create()
function isTokenExpired() {
  const currentTime = Date.now() / 1000
  const decodedToken: any = jwt_decode(localStorage.getItem('token') || '')
  if (decodedToken.exp < currentTime) {
    return true
  }
  return false
}
api.interceptors.request.use(
  async (config: any) => {
    if (isTokenExpired()) {
      try {
        const response = await axios.post(REFRESH_TOKEN, {}, {withCredentials: true})
        const token = response.data?.data?.jwtToken
        localStorage.setItem('kt-auth-react-v', JSON.stringify(response.data))
        localStorage.setItem('token', token)
        config.headers.genie_access_token = `Bearer ${token}`
      } catch (error) {
        console.error(error)
      }
    }
    return config
  },
  (error) => {
    // Handle request error
    console.error('Request error:', error)
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response: any) => {
    return response
  },
  async (error: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true
      try {
        const response = await axios.post(REFRESH_TOKEN, {}, {withCredentials: true})
        const token = response.data?.data?.jwtToken
        localStorage.setItem('kt-auth-react-v', JSON.stringify(response.data))
        localStorage.setItem('token', token)
        originalRequest.headers.genie_access_token = `Bearer ${token}`
        const retryRequest = await api(originalRequest)
        return Promise.resolve(retryRequest)
      } catch (error) {
        console.error(error)
        // localStorage.clear()
        // window.location.replace('/auth')
      }
    }
    if (error.response && error.response?.status !== 401) {
      const message = error.response?.data?.error?.message
      showToast(message || 'Unable to process the request', 'error')
    }
    return Promise.reject(error)
  }
)

export default api
