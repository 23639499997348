import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { APP_NAME } from '../common/globals/common.constants'
import { Helmet } from 'react-helmet'

const randomNumber = (): Number => {
  return Math.floor(Math.random() * 4) + 1;
};

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  },[])

  return (
    <>
      <Helmet>
        <title>NourishGenie | {APP_NAME} | Auth | Login</title>
      </Helmet>
      <div
        className="d-flex flex-column flex-root"
        id="kt_app_root"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            `/media/auth/bg/${randomNumber()}.jpg`
          )})`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPositionY: "center",
        }}
      >
        <div className="d-flex flex-column flex-column-fluid flex-lg-row justify-content-center">
          <div className="d-flex row-cols-1 flex-center row-cols-lg-auto w-lg-50 p-lg-10 mx-10 mx-lg-0">
            <div className="card rounded-3 w-md-550px">
              <div className="card-body d-flex flex-column p-10 p-lg-20 pb-lg-10">
                <div className="flex-center flex-column-fluid pb-0 pb-lg-5">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { AuthLayout }
