import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MenuComponent } from "../../../../_metronic/assets/ts/components/MenuComponent";
import { PageTitle } from "../../../../_metronic/layout/core";
import { AccessGroupTable } from "./AccessGroupTable";
import { AccessGroupModal } from "./AccessGroupModal";
// import { AccessGroupModel } from "../../models/access_group.model";
// import { AccessGroupModal } from "./AccessGroupModal";
// import { AccessGroupTable } from "./AccessGroupTable";

type ModalState = {
    showModal: boolean;
    editMode: boolean;
    readOnlyMode: boolean;
    appAccessGroups: any | {};
};

export const AccessGroup: FC = () => {
    const [modalState, setModalState] = useState<ModalState>({
        showModal: false,
        editMode: false,
        readOnlyMode: false,
        appAccessGroups: {},
    });

    const openModal = (
        mode: "create" | "view" | "edit",
        appAccessGroups: any = {}
    ) => {
        setModalState((prevState) => {
            return {
                ...prevState,
                showModal: true,
                editMode: mode === "edit",
                readOnlyMode: mode === "view",
                appAccessGroups: appAccessGroups,
            };
        });
    };
    const closeModal = () => {
        setModalState((prevState) => {
            return {
                ...prevState,
                showModal: false,
            };
        });
    };

    useEffect(() => {
        MenuComponent.reinitialization();
    }, []);

    return (
        <>
            <Helmet>
                <title> Access Groups | Overview</title>
            </Helmet>
            <PageTitle
                breadcrumbs={[
                    {
                        isActive: false,
                        path: "",
                        isSeparator: true,
                        title: "Permissions",
                    },
                ]}
            >
                Access Groups
            </PageTitle>
            <AccessGroupTable
                openModal={openModal}
                reRenderComponent={!modalState.showModal}
            />

            <AccessGroupModal
                closeModal={closeModal}
                editMode={modalState.editMode}
                readOnlyMode={modalState.readOnlyMode}
                showModal={modalState.showModal}
                accessGroupDetails={modalState.appAccessGroups}
            />
        </>
    );
};