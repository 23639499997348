export const APP_NAME = "Workforce";

export const REACT_APP_GENIE_RESOLVE_API = `${process.env.REACT_APP_GENIE_RESOLVE_API}`;

export const REACT_APP_GENIE_RESOLVE_VERSION = `${process.env.REACT_APP_GENIE_RESOLVE_VERSION}`;

export const PAGE = 1;

export const CURRENTPAGE = 1;

export type sortObj = { sortOn: string | null; sortBy: "asc" | "desc" | null };

export const onChangeSortObj = (event: any, sortObj: sortObj) => {
  const id = event?.target?.id;
  if (!sortObj?.sortBy) sortObj = { sortOn: id, sortBy: "asc" };
  else {
    if (sortObj.sortOn === id && sortObj.sortBy === "asc")
      sortObj.sortBy = "desc";
    else if (sortObj.sortOn === id && sortObj.sortBy === "desc")
      sortObj = { sortOn: "_id", sortBy: "asc" };
    else sortObj = { sortOn: id, sortBy: "asc" };
  }
  return sortObj;
};

export type Option = {
  value: any,
  label: string
}

export const getRequestMethodColor = (method: String) => {
  switch (method) {
    case "POST":
      return "warning";
    case "GET":
      return "success";
    case "PUT":
      return "info";
    case "DELETE":
      return "danger";
    case "PATCH":
      return "primary";
    default:
      return "primary";
  }
};

export const methods = ["GET", "POST", "PUT", "PATCH", "DELETE"];

export const methodOptions = methods.map((method) => {
  return { value: method, label: method };
});

export const IST_FORMAT = 'dd MMM yy, hh:mm aaa'