import {useContext, useEffect, useState} from 'react'
import {LeaveContext} from '../../context/LeaveContext'
import {Helmet} from 'react-helmet'
import {ModalComponent} from '../../../common/components/modal/Modal'
import * as Yup from 'yup'
import {AppLeaveService} from '../service/leave.service'
import {useFormik} from 'formik'
import clsx from 'clsx'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import {LoadingButton} from '../../../common/components/LoadingButton/LoadingButton'
import {showToast} from '../../../common/toastify/toastify.config'

const applyLeaveSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  appUserId: Yup.string().required('appUserId is required'),
  leaveType: Yup.string().required('Leave Type is Required'),
  from: Yup.date().required('Start date of leave is required'),
  to: Yup.date().required('End Date of leave is required'),
  leaveReason: Yup.string()
    .trim()
    .min(3, 'Leave Reason must be at least 3 characters')
    .max(300, 'Leave Reason must be at most 300 characters')
    .required(),
  appDepartmentId: Yup.string().required(),
})

type Leave = {
  name: string
  appUserId: string
  leaveType: string
  leaveReason: string
  from: Date | string
  to: Date | string
  appDepartmentId: string
}

let initialValues: Leave = {
  name: '',
  appUserId: '',
  leaveType: '',
  leaveReason: '',
  from: '',
  to: '',
  appDepartmentId: '',
}

function EmployeeLeavesModal() {
  const [loading, setLoading] = useState(false)
  const [employeeLeave, setEmployeeLeave] = useState<any>({})
  const [currentStatus, setCurrentStatus] = useState('')

  const leaveOptions = [
    {
      value: 'Sick Leave',
      label: `Sick Leave (Taken Leaves - ${
        employeeLeave?.sickLeave?.takenLeave ? employeeLeave?.sickLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.sickLeave?.totalLeave ? employeeLeave?.sickLeave?.totalLeave : 0
      })`,
      isdisabled: employeeLeave?.sickLeave?.takenLeave >= employeeLeave?.sickLeave?.totalLeave,
    },
    {
      value: 'Casual Leave',
      label: `Casual Leave (Taken Leaves - ${
        employeeLeave?.casualLeave?.takenLeave ? employeeLeave?.casualLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.casualLeave?.totalLeave ? employeeLeave?.casualLeave?.totalLeave : 0
      })`,
      isdisabled: employeeLeave?.casualLeave?.takenLeave >= employeeLeave?.casualLeave?.totalLeave,
    },
    {
      value: 'Paid Leave',
      label: `Paid Leave (Taken Leaves - ${
        employeeLeave?.privilegeLeave?.takenLeave ? employeeLeave?.privilegeLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.privilegeLeave?.totalLeave ? employeeLeave?.privilegeLeave?.totalLeave : 0
      })`,
      isdisabled:
        employeeLeave?.privilegeLeave?.takenLeave >= employeeLeave?.privilegeLeave?.totalLeave,
    },
  ]

  const {modalState, closeModal, reloadPage} = useContext(LeaveContext)

  const getEmployeeLeaves = async (id: string) => {
    const response = await AppLeaveService.getEmployeeLeaves(id)
    if ('data' in response && 'UserLeaveDetails' in response.data) {
      setEmployeeLeave(response.data.UserLeaveDetails)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: applyLeaveSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      if (!modalState.editMode && !modalState.readOnlyMode) {
        const request = await AppLeaveService.createLeave(values)
        if ('data' in request && request?.error === false) {
          if ('message' in request.data) {
            showToast(request.data.message, 'success')
          }
          reloadPage()
          closeModal()
        }
      } else if (modalState.editMode) {
        const newValues = {...values, appLeaveId: modalState?.appLeaves?._id}
        const request = await AppLeaveService.updateLeave(newValues)
        if ('data' in request && request?.error === false) {
          if ('message' in request.data) {
            showToast(request.data.message, 'success')
          }
          reloadPage()
          closeModal()
        }
      }
      setLoading(false)
      setSubmitting(false)
    },
  })

  const handleLeaveChange = (selectedOption: any) => {
    setCurrentStatus(selectedOption.value)
    formik.setFieldValue('leaveType', selectedOption.value)
  }

  useEffect(() => {
    let id = ''
    let name = ''
    let departmentId = ''
    ;(async () => {
      const tokenResponse = await AppLeaveService.getEmployeeViaToken()
      if ('User' in tokenResponse && 'appUserId' in tokenResponse.User)
        id = tokenResponse.User.appUserId
      if ('User' in tokenResponse && 'username' in tokenResponse.User)
        name = tokenResponse.User.username
      const employeeResponse = await AppLeaveService.fetchEmployeeDetails(id)
      if (
        'data' in employeeResponse &&
        'user_details' in employeeResponse.data &&
        'appDepartmentId' in employeeResponse.data.user_details
      )
        departmentId = employeeResponse.data.user_details.appDepartmentId._id
      if (!modalState.readOnlyMode) {
        await getEmployeeLeaves(id)
      }
      formik.resetForm()
      if (!modalState.editMode && !modalState.readOnlyMode) {
        formik.setFieldValue('appUserId', id)
        formik.setFieldValue('name', name)
        formik.setFieldValue('appDepartmentId', departmentId)
      }
    })()
    if ((modalState.editMode || modalState.readOnlyMode) && modalState.appLeaves) {
      initialValues = {
        name: modalState.appLeaves?.name || '',
        appUserId: modalState.appLeaves?.appUserId || '',
        leaveType: modalState.appLeaves?.leaveType || '',
        leaveReason: modalState.appLeaves?.leaveReason || '',
        from: modalState.appLeaves?.from || '',
        to: modalState.appLeaves?.to || '',
        appDepartmentId: modalState.appLeaves?.appDepartmentId || '',
      }
      setCurrentStatus(modalState.appLeaves?.leaveType)
      setCurrentStatus((prevState) => {
        return prevState
      })
    } else {
      initialValues = {
        name: '',
        appUserId: '',
        leaveType: '',
        leaveReason: '',
        from: '',
        to: '',
        appDepartmentId: '',
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.showModal])

  return (
    <>
      {modalState.showModal && (
        <Helmet>
          <title>
            Leaves
            {modalState.showModal
              ? ` | ${
                  modalState.readOnlyMode
                    ? 'View Leave'
                    : modalState.editMode
                    ? 'Update Leave'
                    : 'Apply Leave'
                }`
              : ''}
          </title>
        </Helmet>
      )}
      <ModalComponent
        handleClose={closeModal}
        show={modalState.showModal}
        modalTitle={
          modalState.readOnlyMode
            ? 'View Leave'
            : modalState.editMode
            ? 'Update Leave'
            : 'Apply For Leave'
        }
        id={'leaveModal'}
      >
        <form onSubmit={formik.handleSubmit} id='ticket_form' className='form'>
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              <span className={clsx({required: !modalState.readOnlyMode})}>Name</span>
            </label>
            <input
              disabled
              type='text'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control bg-transparent py-2',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            )}
          </div>
          <div className='fv-row row mb-5'>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                <span className={clsx({required: !modalState.readOnlyMode})}>Start Date</span>
              </label>
              <div className='w-100'>
                <DatePicker
                  selected={formik.values.from ? moment(formik.values.from).toDate() : null}
                  minDate={moment(new Date()).toDate()}
                  onChange={(date) => formik.setFieldValue('from', date)}
                  className='form-select form-select-solid w-100'
                  placeholderText='Select Start Date'
                />
              </div>
              {formik.touched.from && formik.errors.from && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block' style={{paddingLeft: '68px'}}>
                    {formik.errors.from}
                  </div>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                <span className={clsx({required: !modalState.readOnlyMode})}>End Date</span>
              </label>
              <div className='w-100'>
                <DatePicker
                  selected={formik.values.to ? moment(formik.values.to).toDate() : null}
                  onChange={(date) => formik.setFieldValue('to', date)}
                  disabled={!formik.values.from}
                  className='form-select form-select-solid w-100'
                  placeholderText='Select End Date'
                />
              </div>
              {formik.touched.to && formik.errors.to && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block' style={{paddingLeft: '68px'}}>
                    {formik.errors.to}
                  </div>
                </div>
              )}
            </div>
          </div>
          {modalState.readOnlyMode ? (
            <div className='fv-row row mb-5'>
              <div className='col-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <span className={clsx({required: !modalState.readOnlyMode})}>Leave Type</span>
                </label>
                <Select
                  name='leaveType'
                  onChange={handleLeaveChange}
                  value={[
                    {
                      value: currentStatus || null,
                      label: currentStatus || 'Choose One',
                      isdisabled: false,
                    },
                  ]}
                  isDisabled={modalState.readOnlyMode}
                  options={leaveOptions}
                  isOptionDisabled={(option) => option.isdisabled}
                  className='basic-single'
                  classNamePrefix='select'
                />
              </div>
              <div className='col-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <span>Status</span>
                </label>
                <input
                  disabled
                  type='text'
                  value={modalState?.appLeaves?.status}
                  className='form-control bg-transparent py-2'
                />
              </div>
            </div>
          ) : (
            <div className='fv-row row mb-5'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                <span className={clsx({required: !modalState.readOnlyMode})}>Leave Type</span>
              </label>
              <Select
                name='leaveType'
                onChange={handleLeaveChange}
                value={[
                  {
                    value: currentStatus || null,
                    label: currentStatus || 'Choose One',
                    isdisabled: false,
                  },
                ]}
                isDisabled={modalState.readOnlyMode}
                options={leaveOptions}
                isOptionDisabled={(option) => option.isdisabled}
                className='basic-single'
                classNamePrefix='select'
              />
            </div>
          )}
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              <span className={clsx({required: !modalState.readOnlyMode})}>Leave Reason</span>
            </label>
            <textarea
              disabled={modalState.readOnlyMode}
              {...formik.getFieldProps('leaveReason')}
              className={clsx(
                'form-control bg-transparent py-2',
                {'is-invalid': formik.touched.leaveReason && formik.errors.leaveReason},
                {'is-valid': formik.touched.leaveReason && !formik.errors.leaveReason}
              )}
              placeholder='Reason for applying leave'
              style={{resize: 'none'}}
            />
            {formik.touched.leaveReason && formik.errors.leaveReason && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.leaveReason}</span>
              </div>
            )}
          </div>
          {!modalState.readOnlyMode && (
            <>
              <div className='d-flex flex-wrap justify-content-evenly pb-lg-0 pt-lg-10 pt-5'>
                <LoadingButton
                  btnText={modalState.editMode ? 'Update' : 'Submit'}
                  loading={loading}
                  disableBtn={formik.isSubmitting || !formik.isValid || loading}
                  btnClass={'btn btn-primary me-4'}
                />
                <button
                  type='button'
                  onClick={closeModal}
                  className='btn btn-secondary'
                  disabled={formik.isSubmitting || loading}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </form>
      </ModalComponent>
    </>
  )
}

export default EmployeeLeavesModal
