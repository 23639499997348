/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {FC, ReactNode, useEffect, useState} from 'react'
import {PaginationComponent} from '../../common/components/pagination/PaginationComponent'
import CopyToClipboard from 'react-copy-to-clipboard'
import {format} from 'date-fns'
import {createSearchParams, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {AppMenuService} from './menu.service'
import {IST_FORMAT, onChangeSortObj, sortObj} from '../../common/globals/common.constants'
import {MenuComponent} from '../../../../_metronic/assets/ts/components/MenuComponent'
import {handleAlert} from '../../common/components/sweetalert/AlertComponent'
import {showToast} from '../../common/toastify/toastify.config'

type Props = {
  openModal: (mode: 'create' | 'edit' | 'view', groupDetails: any | {}) => any
  reRenderComponent: boolean
}

type RowState = {
  [key: number]: boolean
}

export const AppMenuTable: FC<Props> = ({openModal, reRenderComponent}) => {
  const location = useLocation()
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [checkedMenus, setCheckedMenus] = useState<string[]>([])
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get('page'))

  const [state, setState] = useState<{
    appMenus: any[]
    appMenuCount: number
  }>({
    appMenus: [],
    appMenuCount: 0,
  })

  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    showingFrom: 1,
    showingTill: 10,
    page: page ? page : 1,
  })

  const [currentPage, setCurrentPage] = useState(paginationState.page)

  const [sortState, setSortState] = useState<sortObj>({
    sortBy: null,
    sortOn: null,
  })

  const [filterOptions, setFilterOptions] = useState<any>({
    search: null,
  })

  const [isActionOpen, setIsActionOpen] = useState<RowState>({})

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'selectAll') {
      const {checked} = e.target
      setCheckedMenus(checked ? state.appMenus?.map((menu) => menu.appMenuId || '') : [])
      setSelectAllChecked(checked)
    } else {
      const {checked, id} = e.target
      setCheckedMenus((prevcheckedMenus) =>
        checked ? [...prevcheckedMenus, id] : prevcheckedMenus.filter((menuId) => menuId !== id)
      )
      setSelectAllChecked(false)
    }
  }

  const onClickDelete = () => {
    handleAlert(
      `Are you sure?`,
      `You won't be able to revert this! </br> Total ${checkedMenus.length} record${
        checkedMenus.length > 1 ? 's' : ''
      } will be deleted.`,
      'question',
      'Confirm',
      async () => {
        const request = await AppMenuService.deleteAppMenus(checkedMenus)
        if ('data' in request) {
          const filter: any = {
            search: filterOptions.search,
          }
          getAppMenus({
            ...filter,
            metaData: {
              limit: paginationState.itemsPerPage,
              offset: (paginationState.page - 1) * paginationState.itemsPerPage,
              sortBy: sortState.sortBy,
              sortOn: sortState.sortOn,
            },
          })
          // showToast(request.data.message, "success");
          setCheckedMenus([])
          setSelectAllChecked(false)
        }
      }
    )
  }

  const deleteAppMenus = (menu: any) => {
    handleAlert(
      `Are you sure?`,
      `Delete this menu <br> ${menu.name}`,
      'question',
      'Confirm',
      async () => {
        const request = await AppMenuService.deleteAppMenus([menu.appMenuId || ''])
        if ('data' in request) {
          if (request?.error === false) {
            showToast(request.data.message, 'success')
          }
          const filter: any = {
            search: filterOptions.search,
          }
          getAppMenus({
            ...filter,
            metaData: {
              limit: paginationState.itemsPerPage,
              offset: (paginationState.page - 1) * paginationState.itemsPerPage,
              sortBy: sortState.sortBy,
              sortOn: sortState.sortOn,
            },
          })
          // showToast(request.data.message, "success");
          setCheckedMenus([])
          setSelectAllChecked(false)
        }
      }
    )
  }

  const getAppMenus = async (options?: any) => {
    try {
      setLoading(true)
      const request = await AppMenuService.getAppMenus(options)
      const response = request
      if ('data' in response && 'message' in response.data) {
        const totalRecords = response.data.metaData.total_records || 0
        setState((previousState) => {
          return {
            ...previousState,
            appMenus: response.data.appMenus,
            appMenuCount: totalRecords,
          }
        })
        const currentPage = paginationState.page
        const itemsPerPage = paginationState.itemsPerPage
        let showingFrom = (currentPage - 1) * itemsPerPage + 1
        let showingTill: any
        if (totalRecords) showingTill = Math.min(totalRecords, currentPage * itemsPerPage)
        setPaginationState((prevState) => {
          return {
            ...prevState,
            showingFrom: showingFrom,
            showingTill: showingTill,
          }
        })
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  const onPageChange = (page: any) => {
    const currentPage = page.selected + 1
    setPaginationState((previousState) => {
      return {...previousState, page: currentPage}
    })
    setFilterOptions((prevState: any) => {
      return {...prevState, page: currentPage}
    })
    const params = createSearchParams({page: currentPage})
    navigate({pathname: window.location.pathname, search: `?${params}`}, {replace: true})
  }

  const onClickTableHeader = (event: any) => {
    const newSortObj = onChangeSortObj(event, sortState)
    setSortState({
      sortBy: newSortObj.sortBy,
      sortOn: newSortObj.sortOn,
    })
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const filter: any = {
      isAdministrator: Boolean(params.get('isAdministrator')) || null,
      search: params.get('search') || null,
      page: Number(params.get('page')) || currentPage,
      metaData: {
        limit: Number(JSON.parse(params.get('metaData') || '{}')?.limit) || 0,
        offset: Number(JSON.parse(params.get('metaData') || '{}')?.offset) || 0,
        sortBy: params.get('sortBy') || null,
        sortOn: params.get('sortOn') || null,
      },
    }
    setFilterOptions((prevState: any) => {
      return {...prevState, ...filter}
    })
  }, [location.pathname])

  useEffect(() => {
    if (reRenderComponent) {
      let page = paginationState.page
      if (filterOptions.page && filterOptions.page > 1) {
        page = filterOptions.page
      }
      let offset = (page - 1) * paginationState.itemsPerPage
      if (state.appMenuCount > 0 && offset > state.appMenuCount) {
        const lastPage = Math.ceil(state.appMenuCount / paginationState.itemsPerPage)
        offset = (lastPage - 1) * paginationState.itemsPerPage
        setCurrentPage(lastPage)
      } else {
        setCurrentPage(page)
      }
      getAppMenus({
        search: filterOptions.search || null,
        metaData: {
          offset,
        },
      })
    } else {
      let page = paginationState.page
      if (filterOptions.page && filterOptions.page > 1) {
        page = filterOptions.page
      }
      let offset = (page - 1) * paginationState.itemsPerPage
      getAppMenus({
        search: filterOptions.search || null,
        metaData: {
          offset,
        },
      })
    }
  }, [
    paginationState.page,
    paginationState.itemsPerPage,
    sortState.sortOn,
    sortState.sortBy,
    filterOptions.search,
    filterOptions.page,
    reRenderComponent,
  ])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const toggleAction = (rowId: number) => {
    // Toggle the visibility state for the specified row
    setIsActionOpen((prevIsActionOpen) => ({
      ...prevIsActionOpen,
      [rowId]: !prevIsActionOpen[rowId] || false,
    }))
  }

  // Disable the action button if any action button is open
  const isAnyActionOpen = Object.values(isActionOpen).some((isOpen) => isOpen)

  return (
    <div className='card'>
      <div className='card-header py-5'>
        <div className='card-title'>
          <select
            className='form-select form-select-solid fw-bold w-auto w-lg-150px'
            data-kt-select2='true'
            data-placeholder='Show records'
            defaultValue={10}
            onChange={(e: any) => {
              setPaginationState((prevState) => {
                return {
                  ...prevState,
                  itemsPerPage: Number(e.target.value),
                }
              })
              getAppMenus({metaData: {limit: e.target.value}})
            }}
          >
            <option value='10'>10 Records</option>
            <option value='15'>15 Records</option>
            <option value='25'>25 Records</option>
            <option value='50'>50 Records</option>
          </select>
        </div>

        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
            {checkedMenus?.length > 0 ? (
              <button type='button' className='btn btn-light-danger me-3' onClick={onClickDelete}>
                Delete
              </button>
            ) : (
              <>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='fs-5 pe-2 fa-solid fa-filter'></i>Filter
                </button>
                <div
                  className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                  data-kt-menu='true'
                >
                  {/* begin::Header */}
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                  </div>
                  {/* end::Header */}
                  {/* begin::Separator */}
                  <div className='separator border-gray-200'></div>
                  {/* end::Separator */}
                  {/* begin::Dropdown for selecting department */}
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Search Menus:</div>
                    <div className='py-3'>
                      <div className='form-group'>
                        <input
                          type='text'
                          className='form-control form-control-solid fw-bold ps-10'
                          placeholder='Search Menus'
                          value={filterOptions.search || ''}
                          onChange={(e) => {
                            // setFilterOptions({...filterOptions, search: e.target.value})
                            setFilterOptions((previousState: any) => {
                              return {...previousState, search: e.target.value}
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <button
                      style={{marginBottom: '10px'}}
                      type='reset'
                      className='btn btn-light btn-light-primary me-2'
                      onClick={() => {
                        setFilterOptions({...filterOptions, search: null})
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </>
            )}

            <button
              onClick={() => openModal('create', {})}
              type='button'
              className='btn btn-primary'
            >
              Create App Menu
            </button>
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        <table
          className='table align-middle table-row-dashed table-responsive fs-6 gy-5'
          id='kt_service_group_table'
        >
          <thead>
            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
              <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    id='selectAll'
                    className='form-check-input'
                    type='checkbox'
                    value='selectAll'
                    checked={selectAllChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </th>
              <th
                id='_id'
                onClick={onClickTableHeader}
                className={clsx(
                  'min-w-75px table-sort cursor-pointer',
                  {
                    'table-sort-asc': sortState.sortOn === '_id' && sortState.sortBy === 'asc',
                  },
                  {
                    'table-sort-desc': sortState.sortOn === '_id' && sortState.sortBy === 'desc',
                  }
                )}
              >
                ID
              </th>
              <th
                id='name'
                onClick={onClickTableHeader}
                className={clsx(
                  'min-w-125px table-sort cursor-pointer',
                  {
                    'table-sort-asc': sortState.sortOn === 'name' && sortState.sortBy === 'asc',
                  },
                  {
                    'table-sort-desc': sortState.sortOn === 'name' && sortState.sortBy === 'desc',
                  }
                )}
              >
                Name
              </th>
              <th
                id='description'
                onClick={onClickTableHeader}
                className={clsx(
                  'min-w-125px table-sort cursor-pointer',
                  {
                    'table-sort-asc':
                      sortState.sortOn === 'description' && sortState.sortBy === 'asc',
                  },
                  {
                    'table-sort-desc':
                      sortState.sortOn === 'description' && sortState.sortBy === 'desc',
                  }
                )}
              >
                {' '}
                Description
              </th>
              <th className='text-center min-w-100px'>Created Date</th>
              <th className='text-center min-w-50px'>Actions</th>
            </tr>
          </thead>
          <tbody className='fw-semibold text-gray-600'>
            {state.appMenus?.map((menu, index) => {
              return (
                <tr key={menu.appMenuId}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        id={menu.appMenuId}
                        className='form-check-input selectBox'
                        type='checkbox'
                        checked={checkedMenus.includes(menu.appMenuId || '')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </td>
                  <td className='cursor-pointer'>
                    <CopyToClipboard onCopy={(text, result) => {}} text={menu.appMenuId || ''}>
                      <span className='badge badge-light-dark text-hover-primary text-gray-700 mb-1'>
                        {`${menu.appMenuId?.substring(0, 10)}...`}
                      </span>
                    </CopyToClipboard>
                  </td>
                  <td className='mw-150px text-truncate'>
                    <span className='text-gray-800 mb-1'>{menu.name}</span>
                  </td>
                  <td className='mw-125px text-truncate'>
                    <span className='text-gray-800 mb-1'>{menu.description}</span>
                  </td>
                  <td className='mw-150px fw-bold text-center'>
                    <span>
                      {menu?.createdAt
                        ? format(new Date(menu?.createdAt), IST_FORMAT)
                        : ('' as ReactNode)}
                    </span>
                  </td>
                  <td className='text-center' style={{position: 'relative'}}>
                    <button
                      className='btn btn-light btn-active-light-primary btn-sm'
                      disabled={!isActionOpen[index] && isAnyActionOpen}
                      onClick={() => toggleAction(index)}
                      style={{transition: 'background-color 0.3s ease'}}
                    >
                      Actions
                      <i className='fa-solid fa-chevron-down ms-3'></i>
                    </button>
                    {isActionOpen && (
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                        style={{
                          display: isActionOpen[index] ? 'block' : 'none',
                          position: 'absolute',
                          left: '20%',
                          top: `${index === 0 ? '80%' : '-197%'}`,
                          transition: 'opacity 0.3s ease, transform 0.3s ease',
                          opacity: isActionOpen[index] ? 1 : 0,
                          transform: isActionOpen[index] ? 'translateY(0)' : 'translateY(-10px)',
                        }}
                      >
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3'
                            onClick={() => {
                              openModal('view', menu)
                              toggleAction(index)
                            }}
                          >
                            View
                          </a>
                        </div>
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3'
                            onClick={() => {
                              openModal('edit', menu)
                              toggleAction(index)
                            }}
                          >
                            Edit
                          </a>
                        </div>
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3 text-danger'
                            onClick={() => {
                              deleteAppMenus(menu)
                              toggleAction(index)
                            }}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='d-flex justify-content-between mt-5'>
          <PaginationComponent
            onPageChange={onPageChange}
            pageCount={Math.ceil(state.appMenuCount / paginationState.itemsPerPage)}
            showingFrom={paginationState.showingFrom}
            showingTill={paginationState.showingTill}
            totalRecords={state.appMenuCount}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  )
}
