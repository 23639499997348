/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import api from '../../../../app/modules/RequestConfig'
import Swal from 'sweetalert2'
import ResetPasswordEmployee from '../../../../app/modules/employee_management/modals/Reset_Password_modals/ResetPasswordEmployee'
import {showToast} from '../../../../app/modules/common/toastify/toastify.config'
const API_URL = process.env.REACT_APP_API_URL
export const GET_PROFILE_PICTURE = `${API_URL}/agent/account-files/get-profile-picture`
const ACCOUNT_ACTION = `${API_URL}/agent/account/user-account-disable`

type Props = {
  color?: string
  avatar?: string
  online?: boolean
  name: string
  employeeType: string
  id?: string
  isDisabled?: boolean
  DeleteSingleUser?: any
  reloadEmployees: any
}

const EmployeeCard: FC<Props> = ({
  color = '',
  avatar = '',
  online = false,
  name,
  employeeType,
  DeleteSingleUser,
  id,
  isDisabled,
  reloadEmployees,
}) => {
  useEffect(() => {
    getUserProfile()
    // eslint-disable-next-line
  }, [isDisabled])
  // const [isTooltipVisible, setTooltipVisible] = useState(false);

  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const [profilePicture, setProfilePicture] = useState('')
  const first_name = name.split(' ')[0]
  const last_name = name.split(' ')[1]
  const getUserProfile = async () => {
    setProfilePicture(`https://ui-avatars.com/api/?name=${first_name}+${last_name}`)
  }

  const token = localStorage.getItem('token')
  const handleAccountAction = () => {
    const actionText = isDisabled ? 'Enable' : 'Disable'
    const confirmText = isDisabled
      ? 'Are you sure you want to activate the account?'
      : 'Are you sure you want to deactivate the account?'

    Swal.fire({
      title: `Confirm ${actionText}`,
      text: confirmText,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle the account action
        if (isDisabled) {
          // Handle enabling the account
          enableAccount()
        } else {
          // Handle disabling the account
          disableAccount()
        }
      }
    })
  }

  const enableAccount = async () => {
    try {
      const response = await api.post(
        ACCOUNT_ACTION,
        {
          appUserId: id,
          isDisabled: false,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + token,
          },
        }
      )
      if (response.status === 200) {
        showToast('Account has been activated', 'success')
        reloadEmployees()
      } else {
        console.error('Error enabling account. HTTP Status:', response.status)
        showToast(`Error enabling account. HTTP Status: ${response.status}`, 'error')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const disableAccount = async () => {
    try {
      const response = await api.post(
        ACCOUNT_ACTION,
        {
          appUserId: id,
          isDisabled: true,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + token,
          },
        }
      )
      if (response.status === 200) {
        showToast('Account has been disabled', 'success')
        reloadEmployees()
      } else {
        console.error('Error disabling account. HTTP Status:', response.status)
        showToast(`Error disabling account. HTTP Status: ${response.status}`, 'error')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleResetPasswordModal = () => {
    setResetPasswordModalOpen(!resetPasswordModalOpen);
  };

  return (
    <>
      <div className='card' style={{padding: '10px', margin: '5px'}}>
        <div className='dropdown' style={{textAlign: 'right'}}>
          <button
            className='btn'
            type='button'
            id='resetPasswordDropdown'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            <i className='fas fa-ellipsis-v'></i>
          </button>
          <ul className='dropdown-menu' aria-labelledby='resetPasswordDropdown'>
            <li style={{marginBottom: '-6px'}}>
              <a
                className='dropdown-item'
                href='#'
                onClick={toggleResetPasswordModal}
              >
                Reset Password
              </a>
            </li>
            <li style={{marginBottom: '-6px'}}>
              <a
                className='dropdown-item'
                href='#'
                data-bs-toggle='modal'
                onClick={handleAccountAction}
              >
                {isDisabled ? 'Enable' : 'Disable'}
              </a>
            </li>
            <li style={{marginBottom: '-6px'}}>
              <Link
                to={'/employee_management/edit-details'}
                state={{id, isDisabled}}
                className='dropdown-item'
              >
                Edit
              </Link>
            </li>
            <li style={{marginBottom: '0px'}}>
              <Link
                to={'/employee_management/employee-files'}
                state={{id, isDisabled}}
                className='dropdown-item'
              >
                Files
              </Link>
            </li>
          </ul> 
        </div>
        {resetPasswordModalOpen ? <ResetPasswordEmployee id={id}  closeModal={toggleResetPasswordModal} />:null}

        <div className='card-body d-flex flex-center flex-column p-0'>
          <div className='mb-5'>
            <div className='symbol symbol-75px symbol-circle'>
              {color ? (
                <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                  {name.charAt(0)}
                </span>
              ) : (
                <img alt='Pic' src={profilePicture} />
              )}
              {online && (
                <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>
              )}
            </div>
          </div>

          <Link
            to={'/employee_management/employee-details/'}
            state={id}
            className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'
          >
            {name}
          </Link>

          <div className='fw-bold text-gray-700 '>{employeeType}</div>
          {isDisabled ? (
            <span className='badge badge-light-danger badge-lg fw-bold mb-1'>Disabled</span>
          ) : (
            <span className='badge badge-light-success badge-lg fw-bold mb-1'>Active</span>
          )}
        </div>
      </div>
    </>
  )
}

export {EmployeeCard}
