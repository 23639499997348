import {FC} from 'react'
import './CustomActivityStyle.css'
import {KTSVG} from '../../../helpers'

type Props = {
  activityData: any[]
}

const ActivityCard: FC<Props> = ({activityData}) => {
  const cardStyle = {
    height: '350px',
    overflow: 'auto',
  }

  return (
    <div className='card card-border border-4' style={cardStyle}>
      <div className='card-body border border-gray-300 p-9'>
        <div className='fs-3 fw-bolder text-dark'>Activity</div>
        <div className='activity-feed'>
          {activityData.map((activity, index) => (
            <div className='activity-item' key={index}>
              <div className={`activity-line ${activity.activity}`} />
              <div>
                {activity.activity === 'checkin' ? (
                  <span className='fs-5 fw-bold text-dark'>Check In At</span>
                ) : activity.activity === 'breakin' ? (
                  <span className='fs-5 fw-bold text-dark'>Break At</span>
                ) : activity.activity === 'breakout' ? (
                  <span className='fs-5 fw-bold text-dark'>Available At</span>
                ) : (
                  <span className='fs-5 fw-bold text-dark'>Check Out At</span>
                )}
                <br></br>
                <KTSVG
                  path='/media/icons/duotune/general/gen013.svg'
                  className='svg-icon-muted svg-icon-2'
                />
                <span>{activity.time}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export {ActivityCard}
