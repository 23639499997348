import React, {useState, useEffect} from 'react'
import Fullcalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import listPlugin from '@fullcalendar/list'
import api from '../RequestConfig'
import {Spinner} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

import './customCalendarStyles.css'
import {showToast} from '../common/toastify/toastify.config'
import {useFormik} from 'formik'
import * as Yup from 'yup'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_EVENTS = `${API_URL}/calendar/get-event`
export const CREATE_EVENT = `${API_URL}/calendar/create-event`
export const UPDATE_EVENT = `${API_URL}/calendar/update-event`
export const DELETE_EVENT = `${API_URL}/calendar/delete-event`

interface EventObject {
  startDate: string
  endDate: string
}

const descriptionOption = [
  {
    value: 'PUBLIC_HOLIDAY',
    name: 'Public Holiday',
  },
  {
    value: 'NOURISH_GENIE_HOLIDAY',
    name: 'Nourish Genie Holiday',
  },
  {
    value: 'SATURDAY_LEAVE',
    name: 'Saturday Holiday',
  },
  {
    value: 'HALF_SATURDAY_LEAVE',
    name: 'Saturday Halfday',
  },
]

let isDateClicked: boolean = false

const addEventSchema = Yup.object().shape({
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date()
    .required('End Date is required')
    .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
  title: Yup.string().required('Title is required'),
  description: Yup.string()
    .required('Description is required')
    .test(
      'isSaturdayLeave',
      'Selected date range does not qualify for Saturday Holiday',
      function (value) {
        if (this.parent.startDate instanceof Date && !isNaN(this.parent.startDate)) {
          const eventStartDate = this.parent.startDate
          let eventEndDate = this.parent.endDate
          if (!isDateClicked) {
            eventEndDate = new Date(eventEndDate.getTime() - 24 * 60 * 60 * 1000)
          }
          if (
            eventStartDate &&
            eventEndDate &&
            eventStartDate.getDay() === 6 &&
            eventEndDate.getDay() === 6 &&
            eventStartDate.toDateString() === eventEndDate.toDateString()
          ) {
            return true
          }
        }
        return value === 'PUBLIC_HOLIDAY' || value === 'NOURISH_GENIE_HOLIDAY'
      }
    ),
  id: Yup.string(),
})

function HolidayCalendar() {
  const [calendarEvents, setCalendarEvents] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEventUpdating, setIsEventUpdating] = useState(false)

  // Function  to get all events
  const getCalendarEvents = async () => {
    const varToken = localStorage.getItem('token')

    try {
      const result = await api.post(
        GET_ALL_EVENTS,
        {
          startDate: null,
          endDate: null,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )

      const eventsArray = result.data.data.appCalendarEvents
      const updatedEventsArray = eventsArray.map((obj: EventObject) => {
        const {startDate, endDate, ...rest} = obj
        return {start: startDate, end: endDate, ...rest}
      })
      setCalendarEvents(updatedEventsArray)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      await getCalendarEvents()
      setIsLoading(false)
    })()
  }, [])

  const handleEventCreation = async (values: any) => {
    const varToken = localStorage.getItem('token')

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await api.post(
        CREATE_EVENT,
        {
          title: values.title,
          startDate: values.startDate,
          endDate: values.endDate,
          description: values.description,
          editable: true,
          url: null,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )

      if (result.data.error === false) {
        showToast(`${result.data.data.message}`, 'success')
        handleCloseModal()
        getCalendarEvents()
      }
    } catch (error: any) {
      console.error(error.message)
    }
  }

  const handleEventUpdation = async (values: any) => {
    const varToken = localStorage.getItem('token')

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await api.put(
        UPDATE_EVENT,
        {
          appCalendarEventId: values.id,
          title: values.title,
          startDate: values.startDate,
          endDate: values.endDate,
          description: values.description,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )

      if (result.data.error === false) {
        showToast(`${result.data.data.message}`, 'success')
        handleCloseModal()
        getCalendarEvents()
      }
    } catch (error: any) {
      console.error(error.message)
    }
  }

  const handleEventDeletion = async () => {
    const varToken = localStorage.getItem('token')
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await api.delete(DELETE_EVENT, {
        data: {
          appCalendarEventId: [formik.values.id],
        },
        headers: {
          genie_access_token: 'Bearer ' + varToken,
        },
      })

      if (result.data.error === false) {
        showToast(`${result.data.data.message}`, 'success')
        getCalendarEvents()
      }
    } catch (error: any) {
      console.error(error.message)
    } finally {
      handleCloseModal()
    }
  }

  const initialValues = {
    startDate: '',
    endDate: '',
    title: '',
    description: '',
    id: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addEventSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (isEventUpdating) {
        await handleEventUpdation(values)
      } else {
        await handleEventCreation(values)
      }
      setSubmitting(false)
      handleCloseModal()
    },
  })

  const handleEventMouseEnter = (info: any) => {
    const eventElement = info.el
    const eventTitle = info.event.title
    // Create a popover element
    const popover = document.createElement('div')
    popover.className = 'event-popover'
    popover.textContent = eventTitle
    // Append the popover element to the event element
    eventElement.appendChild(popover)
  }

  const handleEventMouseLeave = (info: any) => {
    // Remove the popover element when mouse leaves the event
    const eventElement = info.el
    const popover = eventElement.querySelector('.event-popover')
    if (popover) {
      popover.remove()
    }
  }

  const handleCloseModal = () => {
    // Close the modal
    isDateClicked = false
    setIsModalOpen(false)
    formik.setFieldValue('title', '')
    formik.setFieldValue('startDate', '')
    formik.setFieldValue('endDate', '')
    formik.setFieldValue('description', '')
    formik.setFieldValue('id', '')
    formik.setErrors({})
    formik.resetForm()
  }

  const handleDateClick = (e: any) => {
    // formik.setErrors({})
    isDateClicked = true
    setIsModalOpen(true)
    formik.setFieldValue('startDate', e.date)
    setIsEventUpdating(false)
  }

  const headerStyles = {
    fontSize: '24px',
    fontWeight: 'bold',
  }

  function renderDayHeader(arg: any) {
    return <div style={headerStyles}>{arg.text}</div>
  }

  const asteriskStyle = {
    color: 'red',
  }

  return (
    <>
      {isLoading ? (
        <div className='d-flex align-items-center justify-content-center loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : (
        <div className='my-custom-calendar'>
          <Fullcalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              bootstrapPlugin,
              listPlugin,
            ]}
            dayHeaderContent={renderDayHeader}
            initialView={'dayGridMonth'}
            headerToolbar={{
              start: 'today prev,next',
              center: 'title',
              end: 'dayGridMonth,listMonth',
            }}
            height={'90vh'}
            themeSystem='bootstrap'
            dateClick={handleDateClick}
            // nextDayThreshold={'23:59:59'}
            events={calendarEvents}
            eventClick={(info) => {
              // formik.resetForm()
              // formik.setErrors({})
              setIsEventUpdating(true)
              info.jsEvent.preventDefault()

              if (info.event.url !== 'null') {
                window.open(info.event.url)
              }
              formik.resetForm()
              formik.setFieldValue('title', info.event.title)
              formik.setFieldValue('startDate', info.event.start)
              formik.setFieldValue('id', info.event._def.extendedProps._id)
              formik.setFieldValue('description', info.event._def.extendedProps.description)
              formik.setFieldValue('endDate', info.event._instance?.range.end)
              setIsModalOpen(true)
            }}
            eventMouseEnter={handleEventMouseEnter}
            eventMouseLeave={handleEventMouseLeave}
            timeZone='Asia/Kolkata'
          />
        </div>
      )}
      {/* Modal Begins */}
      <div
        className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
        tabIndex={-1}
        id='kt_modal_1'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              {isEventUpdating ? (
                <h5 className='modal-title'>Update Holiday Event</h5>
              ) : (
                <h5 className='modal-title'>Create Holiday Event</h5>
              )}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={handleCloseModal}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className='modal-body'>
                {/* Row 1 */}
                <div className='form-group mb-10'>
                  <label htmlFor='title' className='form-label fs-5 text-dark fw-bolder'>
                    Title: <span style={asteriskStyle}>*</span>
                  </label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    {...formik.getFieldProps('title')}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.title}</div>
                    </div>
                  )}
                </div>
                {/* Row 2 */}
                <div style={{display: 'flex', width: '100%'}}>
                  <div className='form-group w-50 mr-5'>
                    <div className='form-group'>
                      <label htmlFor='startDate' className='form-label fs-5 text-dark fw-bolder'>
                        Start Date: <span style={asteriskStyle}>*</span>
                      </label>
                      <div style={{display: 'flex'}}>
                        <DatePicker
                          className='form-control'
                          selected={
                            formik.values.startDate
                              ? moment(formik.values.startDate).toDate()
                              : null
                          }
                          onChange={(date) => formik.setFieldValue('startDate', date)}
                          dateFormat='EEE dd-MM-yyyy'
                        />
                      </div>
                      {formik.touched.startDate && formik.errors.startDate && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.startDate}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='form-group' style={{width: '50%'}}>
                    <div className='form-group'>
                      <label htmlFor='endDate' className='form-label fs-5 text-dark fw-bolder'>
                        {' '}
                        End Date: <span style={asteriskStyle}>*</span>
                      </label>
                      <DatePicker
                        className='form-control'
                        selected={
                          formik.values.endDate ? moment(formik.values.endDate).toDate() : null
                        }
                        onChange={(date) => {
                          if (date !== null) {
                            const endDateTime = new Date(date)
                            endDateTime.setHours(23)
                            endDateTime.setMinutes(59)
                            formik.setFieldValue('endDate', endDateTime)
                          }
                        }}
                        dateFormat='EEE dd-MM-yyyy'
                      />
                      {formik.touched.endDate && formik.errors.endDate && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.endDate}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <br></br>
                <br></br>
                {/* Row 3 */}
                <div className='form-group mb-10'>
                  <label htmlFor='description' className='form-label fs-5 text-dark fw-bolder'>
                    Description: <span style={asteriskStyle}>*</span>
                  </label>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    {...formik.getFieldProps('description')}
                  >
                    <option value=''>Select Holiday Description</option>
                    {descriptionOption.map((dep: any, index: number) => (
                      <option key={index} value={dep.value}>
                        {dep.name}
                      </option>
                    ))}
                  </select>

                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
                {isEventUpdating ? (
                  <div className='d-flex justify-content-center align-items-center'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {formik.isSubmitting && formik.isValid ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <span className='indicator-label'>Update Event</span>
                      )}
                    </button>
                    <button
                      type='button'
                      onClick={handleEventDeletion}
                      className='btn btn-light'
                      data-bs-dismiss='modal'
                    >
                      Delete Event
                    </button>
                  </div>
                ) : (
                  <div className='d-flex justify-content-center align-items-center'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {formik.isSubmitting && formik.isValid ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <span className='indicator-label'>Create Event</span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default HolidayCalendar
