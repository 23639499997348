import {Helmet} from 'react-helmet'
import {KTCard} from '../../../_metronic/helpers'
import EmployeeTicketsHeader from './components/header/EmployeeTicketsHeader'
import EmployeeTicketsTable from './components/table/EmployeeTicketsTable'
import {PageTitle} from '../../../_metronic/layout/core'
import {useContext} from 'react'
import {TicketContext} from './context/TicketContext'

function EmployeeTickets() {
  const {ticketStatistics} = useContext(TicketContext)

  return (
    <>
      <Helmet>
        <title> Tickets | Overview</title>
      </Helmet>
      <PageTitle
        breadcrumbs={[
          {
            isActive: false,
            path: '',
            isSeparator: true,
            title: 'Tickets',
          },
        ]}
      >
        Employee Tickets
      </PageTitle>
      <div className='row mb-10'>
        <div className='col-4'>
          <div className='card card-custom card-flush card-rounded'>
            <div className='card-header'>
              <h3 className='card-title'>Open Tickets</h3>
              <div className='card-toolbar'>
                <span className='text-muted me-2 fs-2 fw-semibold'>{`${ticketStatistics.percentOpenTickets}%`}</span>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-1 fw-semibold'>
                  {ticketStatistics.openTickets}
                </span>
              </div>
              <div className='d-flex'>
                <div className='progress h-10px w-100'>
                  <div
                    className='progress-bar bg-success'
                    role='progressbar'
                    style={{width: `${ticketStatistics.percentOpenTickets}%`}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='card card-custom card-flush card-rounded'>
            <div className='card-header'>
              <h3 className='card-title'>Closed Tickets</h3>
              <div className='card-toolbar'>
                <span className='text-muted me-2 fs-2 fw-semibold'>{`${ticketStatistics.percentClosedTickets}%`}</span>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-1 fw-semibold'>
                  {ticketStatistics.closedTickets}
                </span>
              </div>
              <div className='d-flex'>
                <div className='progress h-10px w-100'>
                  <div
                    className='progress-bar bg-danger'
                    role='progressbar'
                    style={{width: `${ticketStatistics.percentClosedTickets}%`}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='card card-custom card-flush card-rounded'>
            <div className='card-header'>
              <h3 className='card-title'>Pending Tickets</h3>
              <div className='card-toolbar'>
                <span className='text-muted me-2 fs-2 fw-semibold'>{`${ticketStatistics.percentPendingTickets}%`}</span>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-1 fw-semibold'>
                  {ticketStatistics.pendingTickets}
                </span>
              </div>
              <div className='d-flex'>
                <div className='progress h-10px w-100'>
                  <div
                    className='progress-bar bg-warning'
                    role='progressbar'
                    style={{width: `${ticketStatistics.percentPendingTickets}%`}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KTCard>
        <EmployeeTicketsHeader />
        <EmployeeTicketsTable />
      </KTCard>
    </>
  )
}

export default EmployeeTickets
