export function MenuInner() {
  // const intl = useIntl()
  return (
    <>
      {/* <MenuInnerWithSub
        title='Employee Management'
        to='/apps'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem
          title='Company Employees'
          to='/employee_management/company-employees'
          fontIcon='bi-archive'
        ></MenuItem>
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='Company Fields'
        menuPlacement='bottom-start'
        menuTrigger='click'
        to={''}
      >
        <MenuItem
          title='Departments'
          to='/company_fields/department'
          fontIcon='bi-archive'
        ></MenuItem>
        <MenuItem
          title='Designations'
          to='/company_fields/designation'
          fontIcon='bi-archive'
        ></MenuItem>
        <MenuItem
          title='Reporting Managers'
          to='/company_fields/reporting-managers'
          fontIcon='bi-archive'
        ></MenuItem>
      </MenuInnerWithSub> */}
    </>
  )
}
