import RequestConfig from '../../RequestConfig'

export class AppSubMenuService {
    private static baseUrl: string = `${process.env.REACT_APP_API_URL}/permission/menu`;

    private static token = localStorage.getItem('token')

    static async getAppSubMenus(
        options?: any
    ): Promise<any> {
        try {
            const request = await RequestConfig.post(
                `${this.baseUrl}/get-sub-menu`,
                options,
                {
                    headers: {
                        genie_access_token: `Bearer ${this.token}`
                    }
                }
            );
            const response = request.data;
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    static async createAppSubMenu(
        appMenuDetails: any
    ): Promise<any> {
        try {
            const request = await RequestConfig.post(
                `${this.baseUrl}/create-sub-menu`,
                appMenuDetails,
                {
                    headers: {
                        genie_access_token: `Bearer ${this.token}`
                    }
                }
            );
            const response = request.data;
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    static async updateAppSubMenu(
        appMenuDetails: any
    ): Promise<any> {
        try {
            const request = await RequestConfig.put(
                `${this.baseUrl}/update-sub-menu`,
                appMenuDetails,
                {
                    headers: {
                        genie_access_token: `Bearer ${this.token}`
                    }
                }
            );
            const response = request.data;
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    static async deleteAppSubMenus(
        appSubMenuIds: string[]
    ): Promise<any> {
        try {
            const request = await RequestConfig.delete(`${this.baseUrl}/delete-sub-menu`, {
                data: { appSubMenuIds },
                headers: {
                    genie_access_token: `Bearer ${this.token}`
                }
            });
            const response = request.data;
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }
}