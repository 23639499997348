/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {FC, ReactNode, useEffect, useState} from 'react'
import {PaginationComponent} from '../../common/components/pagination/PaginationComponent'
import CopyToClipboard from 'react-copy-to-clipboard'
import {format} from 'date-fns'
import {createSearchParams, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {
  IST_FORMAT,
  getRequestMethodColor,
  onChangeSortObj,
  sortObj,
} from '../../common/globals/common.constants'
import {MenuComponent} from '../../../../_metronic/assets/ts/components/MenuComponent'
import {handleAlert} from '../../common/components/sweetalert/AlertComponent'
import {ServiceRouteService} from './service_route.service'

type Props = {
  openModal: (mode: 'create' | 'edit' | 'view', groupDetails: any | {}) => any
  reRenderComponent: boolean
}

type RowState = {
  [key: number]: boolean
}

export const ServiceRouteTable: FC<Props> = ({openModal, reRenderComponent}) => {
  const location = useLocation()
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [checkedRoutes, setCheckedRoutes] = useState<string[]>([])
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get('page'))

  const [state, setState] = useState<{
    appRoutes: any[]
    appRouteCount: number
  }>({
    appRoutes: [],
    appRouteCount: 0,
  })

  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    showingFrom: 1,
    showingTill: 10,
    page: page ? page : 1,
  })

  const [currentPage, setCurrentPage] = useState(paginationState.page)

  const [sortState, setSortState] = useState<sortObj>({
    sortBy: null,
    sortOn: null,
  })

  const [filterOptions, setFilterOptions] = useState<any>({
    search: null,
  })

  const [isActionOpen, setIsActionOpen] = useState<RowState>({})

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'selectAll') {
      const {checked} = e.target
      setCheckedRoutes(checked ? state.appRoutes?.map((route) => route.appRouteId || '') : [])
      setSelectAllChecked(checked)
    } else {
      const {checked, id} = e.target
      setCheckedRoutes((prevcheckedRoutes) =>
        checked ? [...prevcheckedRoutes, id] : prevcheckedRoutes.filter((menuId) => menuId !== id)
      )
      setSelectAllChecked(false)
    }
  }

  const onClickDelete = () => {
    handleAlert(
      `Are you sure?`,
      `You won't be able to revert this! </br> Total ${checkedRoutes.length} record${
        checkedRoutes.length > 1 ? 's' : ''
      } will be deleted.`,
      'question',
      'Confirm',
      async () => {
        const request = await ServiceRouteService.deleteServiceRoutes(checkedRoutes)
        if ('data' in request) {
          const filter: any = {
            appRouteId: filterOptions.appRouteId,
            isAdministrator: filterOptions.isAdministrator,
            search: filterOptions.search,
          }
          getServiceRoutes({
            ...filter,
            metaData: {
              limit: paginationState.itemsPerPage,
              offset: (paginationState.page - 1) * paginationState.itemsPerPage,
              sortBy: sortState.sortBy,
              sortOn: sortState.sortOn,
            },
          })
          // showToast(request.data.message, "success");
          setCheckedRoutes([])
          setSelectAllChecked(false)
        }
      }
    )
  }

  const deleteServiceRoutes = (route: any) => {
    handleAlert(
      `Are you sure?`,
      `Delete this route <br> ${route.name}`,
      'question',
      'Confirm',
      async () => {
        const request = await ServiceRouteService.deleteServiceRoutes([route.appRouteId || ''])
        if ('data' in request) {
          const filter: any = {
            appRouteId: filterOptions.appRouteId,
            isAdministrator: filterOptions.isAdministrator,
            search: filterOptions.search,
          }
          getServiceRoutes({
            ...filter,
            metaData: {
              limit: paginationState.itemsPerPage,
              offset: (paginationState.page - 1) * paginationState.itemsPerPage,
              sortBy: sortState.sortBy,
              sortOn: sortState.sortOn,
            },
          })
          // showToast(request.data.message, "success");
          setCheckedRoutes([])
          setSelectAllChecked(false)
        }
      }
    )
  }

  const getServiceRoutes = async (options?: any) => {
    try {
      setLoading(true)
      const request = await ServiceRouteService.getServiceRoutes(options)
      const response = request
      if ('data' in response && 'message' in response.data) {
        const totalRecords = response.data.metaData.total_records || 0
        setState((previousState) => {
          return {
            ...previousState,
            appRoutes: response.data.appRoutes,
            appRouteCount: totalRecords,
          }
        })
        const currentPage = paginationState.page
        const itemsPerPage = paginationState.itemsPerPage
        let showingFrom = (currentPage - 1) * itemsPerPage + 1
        let showingTill: any
        if (totalRecords) showingTill = Math.min(totalRecords, currentPage * itemsPerPage)
        setPaginationState((prevState) => {
          return {
            ...prevState,
            showingFrom: showingFrom,
            showingTill: showingTill,
          }
        })
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  const onPageChange = (page: any) => {
    const currentPage = page.selected + 1
    setPaginationState((previousState) => {
      return {...previousState, page: currentPage}
    })
    setFilterOptions((prevState: any) => {
      return {...prevState, page: currentPage}
    })
    const params = createSearchParams({page: currentPage})
    navigate({pathname: window.location.pathname, search: `?${params}`}, {replace: true})
  }

  const onClickTableHeader = (event: any) => {
    const newSortObj = onChangeSortObj(event, sortState)
    setSortState({
      sortBy: newSortObj.sortBy,
      sortOn: newSortObj.sortOn,
    })
  }

  const toggleAction = (rowId: number) => {
    // Toggle the visibility state for the specified row
    setIsActionOpen((prevIsActionOpen) => ({
      ...prevIsActionOpen,
      [rowId]: !prevIsActionOpen[rowId] || false,
    }))
  }

  // Disable the action button if any action button is open
  const isAnyActionOpen = Object.values(isActionOpen).some((isOpen) => isOpen)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const filter: any = {
      search: params.get('search') || null,
      page: Number(params.get('page')) || currentPage,
      metaData: {
        limit: Number(JSON.parse(params.get('metaData') || '{}')?.limit) || 0,
        offset: Number(JSON.parse(params.get('metaData') || '{}')?.offset) || 0,
        sortBy: params.get('sortBy') || null,
        sortOn: params.get('sortOn') || null,
      },
    }
    setFilterOptions((prevState: any) => {
      return {...prevState, ...filter}
    })
  }, [location.pathname])

  useEffect(() => {
    if (reRenderComponent) {
      let page = paginationState.page
      if (filterOptions.page && filterOptions.page > 1) {
        page = filterOptions.page
      }
      let offset = (page - 1) * paginationState.itemsPerPage
      if (state.appRouteCount > 0 && offset > state.appRouteCount) {
        const lastPage = Math.ceil(state.appRouteCount / paginationState.itemsPerPage)
        offset = (lastPage - 1) * paginationState.itemsPerPage
        setCurrentPage(lastPage)
      } else {
        setCurrentPage(page)
      }
      getServiceRoutes({
        search: filterOptions.search || null,
        metaData: {
          offset,
        },
      })
    } else {
      getServiceRoutes({
        search: filterOptions.search || null,
      })
    }
  }, [
    paginationState.page,
    paginationState.itemsPerPage,
    sortState.sortOn,
    sortState.sortBy,
    filterOptions.search,
    filterOptions.page,
    reRenderComponent,
  ])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <div className='card'>
      <div className='card-header py-5'>
        <div className='card-title'>
          <select
            className='form-select form-select-solid fw-bold w-auto w-lg-150px'
            data-kt-select2='true'
            data-placeholder='Show records'
            defaultValue={10}
            onChange={(e: any) => {
              setPaginationState((prevState) => {
                return {
                  ...prevState,
                  itemsPerPage: Number(e.target.value),
                }
              })
              getServiceRoutes({metaData: {limit: e.target.value}})
            }}
          >
            <option value='10'>10 Records</option>
            <option value='15'>15 Records</option>
            <option value='25'>25 Records</option>
            <option value='50'>50 Records</option>
          </select>
          {checkedRoutes?.length > 0 && (
            <button type='button' className='btn btn-light-danger me-3' onClick={onClickDelete}>
              Delete
            </button>
          )}
        </div>

        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
            <button
              type='button'
              className='btn btn-light-primary me-3'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <i className='fs-5 pe-2 fa-solid fa-filter'></i>Filter
            </button>
            <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              {/* end::Header */}
              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}
              {/* begin::Dropdown for selecting department */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Search Routes:</div>
                <div className='py-3'>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control form-control-solid fw-bold ps-10'
                      placeholder='Search Routes'
                      value={filterOptions.search}
                      onChange={(e) => {
                        // setFilterOptions({...filterOptions, search: e.target.value})
                        setFilterOptions((previousState: any) => {
                          return {...previousState, search: e.target.value}
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                <button
                  style={{marginBottom: '10px'}}
                  type='reset'
                  className='btn btn-light btn-light-primary me-2'
                  onClick={() => {
                    setFilterOptions({...filterOptions, search: null})
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
            <button
              onClick={() => openModal('create', {})}
              type='button'
              className='btn btn-primary'
            >
              Create Service Route
            </button>
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        <table
          className='table align-middle table-row-dashed table-responsive fs-6 gy-5'
          id='kt_service_group_table'
        >
          <thead>
            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
              <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    id='selectAll'
                    className='form-check-input'
                    type='checkbox'
                    value='selectAll'
                    checked={selectAllChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </th>
              <th
                id='_id'
                onClick={onClickTableHeader}
                className={clsx(
                  'min-w-75px table-sort cursor-pointer',
                  {
                    'table-sort-asc': sortState.sortOn === '_id' && sortState.sortBy === 'asc',
                  },
                  {
                    'table-sort-desc': sortState.sortOn === '_id' && sortState.sortBy === 'desc',
                  }
                )}
              >
                ID
              </th>
              <th
                id='path'
                onClick={onClickTableHeader}
                className={clsx(
                  'min-w-125px table-sort cursor-pointer',
                  {
                    'table-sort-asc': sortState.sortOn === 'path' && sortState.sortBy === 'asc',
                  },
                  {
                    'table-sort-desc': sortState.sortOn === 'path' && sortState.sortBy === 'desc',
                  }
                )}
              >
                Path
              </th>
              <th
                id='method'
                onClick={onClickTableHeader}
                className={clsx(
                  'min-w-125px table-sort cursor-pointer text-center',
                  {
                    'table-sort-asc': sortState.sortOn === 'method' && sortState.sortBy === 'asc',
                  },
                  {
                    'table-sort-desc': sortState.sortOn === 'method' && sortState.sortBy === 'desc',
                  }
                )}
              >
                {' '}
                Method
              </th>
              <th
                id='secure'
                onClick={onClickTableHeader}
                className={clsx(
                  'min-w-75px text-center table-sort cursor-pointer',
                  {
                    'table-sort-asc': sortState.sortOn === 'secure' && sortState.sortBy === 'asc',
                  },
                  {
                    'table-sort-desc': sortState.sortOn === 'secure' && sortState.sortBy === 'desc',
                  }
                )}
              >
                Secure
              </th>
              <th className='text-center min-w-100px'>Created Date</th>
              <th className='text-center min-w-50px'>Actions</th>
            </tr>
          </thead>
          <tbody className='fw-semibold text-gray-600'>
            {state.appRoutes?.map((route, index) => {
              return (
                <tr key={route.appRouteId}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        id={route.appRouteId}
                        className='form-check-input selectBox'
                        type='checkbox'
                        checked={checkedRoutes.includes(route.appRouteId || '')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </td>
                  <td className='cursor-pointer'>
                    <CopyToClipboard onCopy={(text, result) => {}} text={route.appRouteId || ''}>
                      <span className='badge badge-light-dark text-hover-primary text-gray-700 mb-1'>
                        {`${route.appRouteId?.substring(0, 10)}...`}
                      </span>
                    </CopyToClipboard>
                  </td>
                  <td className='mw-150px text-truncate'>
                    <span className='text-gray-800 mb-1'>{route.path}</span>
                  </td>
                  <td className='mw-75px text-center'>
                    <span
                      className={`badge badge-sm px-2 badge-light-${getRequestMethodColor(
                        route.method ? route.method : ''
                      )} badge-inline`}
                    >
                      {route.method}
                    </span>
                  </td>
                  <td className='mw-75px text-center'>
                    <span
                      className={clsx(
                        'badge badge-sm badge-inline px-2',
                        {'badge-light-success': route.secure},
                        {'badge-light-danger': !route.secure}
                      )}
                    >
                      {route.secure ? 'Yes' : 'No'}
                    </span>
                  </td>
                  <td className='mw-150px fw-bold text-center'>
                    <span>
                      {route?.createdAt
                        ? format(new Date(route?.createdAt), IST_FORMAT)
                        : ('' as ReactNode)}
                    </span>
                  </td>
                  {/* <td className='text-end'>
                    <a
                      className='btn btn-light btn-active-light-primary btn-sm'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      Actions
                      <i className='fa-solid fa-chevron-down ms-3'></i>
                    </a>
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                      data-kt-menu='true'
                    >
                      <div className='menu-item px-3'>
                        <a className='menu-link px-3' onClick={() => openModal('view', route)}>
                          View
                        </a>
                      </div>
                      <div className='menu-item px-3'>
                        <a className='menu-link px-3' onClick={() => openModal('edit', route)}>
                          Edit
                        </a>
                      </div>
                      <div className='menu-item px-3'>
                        <a
                          className='menu-link px-3 text-danger'
                          onClick={() => deleteServiceRoutes(route)}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </td> */}
                  <td className='text-center' style={{position: 'relative'}}>
                    <button
                      className='btn btn-light btn-active-light-primary btn-sm'
                      disabled={!isActionOpen[index] && isAnyActionOpen}
                      onClick={() => toggleAction(index)}
                      style={{transition: 'background-color 0.3s ease'}}
                    >
                      Actions
                      <i className='fa-solid fa-chevron-down ms-3'></i>
                    </button>
                    {isActionOpen && (
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                        style={{
                          display: isActionOpen[index] ? 'block' : 'none',
                          position: 'absolute',
                          left: '20%',
                          // top: '80%',
                          top: `${index === 0 ? '80%' : '-190%'}`,
                          transition: 'opacity 0.3s ease, transform 0.3s ease',
                          opacity: isActionOpen[index] ? 1 : 0,
                          transform: isActionOpen[index] ? 'translateY(0)' : 'translateY(-10px)',
                        }}
                      >
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3'
                            onClick={() => {
                              openModal('view', route)
                              toggleAction(index)
                            }}
                          >
                            View
                          </a>
                        </div>
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3'
                            onClick={() => {
                              openModal('edit', route)
                              toggleAction(index)
                            }}
                          >
                            Edit
                          </a>
                        </div>
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3 text-danger'
                            onClick={() => {
                              deleteServiceRoutes(route)
                              toggleAction(index)
                            }}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='d-flex justify-content-between mt-5'>
          <PaginationComponent
            onPageChange={onPageChange}
            pageCount={Math.ceil(state.appRouteCount / paginationState.itemsPerPage)}
            showingFrom={paginationState.showingFrom}
            showingTill={paginationState.showingTill}
            totalRecords={state.appRouteCount}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  )
}
