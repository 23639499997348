import {Helmet} from 'react-helmet'
import {PageTitle} from '../../../_metronic/layout/core'
import {KTCard} from '../../../_metronic/helpers'
import EmployeeLeavesHeader from './components/header/EmployeeLeavesHeader'
import EmployeeLeavesTable from './components/table/EmployeeLeavesTable'
import {useEffect, useState} from 'react'
import {AppLeaveService} from './components/service/leave.service'

function EmployeeLeaves() {
  const [paidLeave, setPaidLeave] = useState<number>(0)
  const [sickLeave, setSickLeave] = useState<number>(0)
  const [casualLeave, setCasualLeave] = useState<number>(0)
  const [totalPaidLeave, setTotalPaidLeave] = useState<number>(0)
  const [totalSickLeave, setTotalSickLeave] = useState<number>(0)
  const [totalCasualLeave, setTotalCasualLeave] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      let id = ''
      const tokenResponse = await AppLeaveService.getEmployeeViaToken()
      if ('User' in tokenResponse && 'appUserId' in tokenResponse.User)
        id = tokenResponse.User.appUserId
      const response = await AppLeaveService.getEmployeeLeaves(id)
      if ('data' in response && 'UserLeaveDetails' in response.data) {
        setCasualLeave(response.data.UserLeaveDetails?.casualLeave?.takenLeave)
        setTotalCasualLeave(response.data.UserLeaveDetails?.casualLeave?.totalLeave)
        setPaidLeave(response.data.UserLeaveDetails?.privilegeLeave?.takenLeave)
        setTotalPaidLeave(response.data.UserLeaveDetails?.privilegeLeave?.totalLeave)
        setSickLeave(response.data.UserLeaveDetails?.sickLeave?.takenLeave)
        setTotalSickLeave(response.data.UserLeaveDetails?.sickLeave?.totalLeave)
      }
    })()
  }, [])

  return (
    <>
      <Helmet>
        <title> Leaves | Overview</title>
      </Helmet>
      <PageTitle
        breadcrumbs={[
          {
            isActive: false,
            path: '',
            isSeparator: true,
            title: 'Leaves',
          },
        ]}
      >
        Employee Leaves
      </PageTitle>
      <div className='row mb-10'>
        <div className='col-4'>
          <div className='card card-custom card-flush card-rounded'>
            <div className='card-header'>
              <h3 className='card-title'>Paid Leaves</h3>
              <div className='card-toolbar'>
                <span className='text-muted me-2 fs-2 fw-semibold'>{`Total: ${totalPaidLeave}`}</span>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-1 fw-semibold'>{`Taken: ${paidLeave}`}</span>
              </div>
              <div className='d-flex'>
                <div className='progress h-10px w-100'>
                  <div
                    className='progress-bar bg-success'
                    role='progressbar'
                    style={{
                      width: totalPaidLeave > 0 ? `${(paidLeave / totalPaidLeave) * 100}%` : '0%',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='card card-custom card-flush card-rounded'>
            <div className='card-header'>
              <h3 className='card-title'>Casual Leaves</h3>
              <div className='card-toolbar'>
                <span className='text-muted me-2 fs-2 fw-semibold'>{`Total: ${totalCasualLeave}`}</span>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-1 fw-semibold'>{`Taken: ${casualLeave}`}</span>
              </div>
              <div className='d-flex'>
                <div className='progress h-10px w-100'>
                  <div
                    className='progress-bar bg-warning'
                    role='progressbar'
                    style={{
                      width:
                        totalCasualLeave > 0 ? `${(casualLeave / totalCasualLeave) * 100}%` : '0%',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='card card-custom card-flush card-rounded'>
            <div className='card-header'>
              <h3 className='card-title'>Sick Leaves</h3>
              <div className='card-toolbar'>
                <span className='text-muted me-2 fs-2 fw-semibold'>{`Total: ${totalSickLeave}`}</span>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-1 fw-semibold'>{`Taken: ${sickLeave}`}</span>
              </div>
              <div className='d-flex'>
                <div className='progress h-10px w-100'>
                  <div
                    className='progress-bar bg-danger'
                    role='progressbar'
                    style={{
                      width: totalSickLeave > 0 ? `${(sickLeave / totalSickLeave) * 100}%` : '0%',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KTCard>
        <EmployeeLeavesHeader />
        <EmployeeLeavesTable />
      </KTCard>
    </>
  )
}

export default EmployeeLeaves
