import {FC, useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {MenuComponent} from '../../../../_metronic/assets/ts/components/MenuComponent'
import {PageTitle} from '../../../../_metronic/layout/core'
import {AppSubMenuTable} from './AppSubMenuTable'
import {AddSubMenuModal} from './AddSubMenuModal'
import {UpdateSubMenuModal} from './UpdateSubMenuModal'

type ModalState = {
  showModal: boolean
  editMode: boolean
  readOnlyMode: boolean
  appSubMenus: any | {}
}

export const AppSubMenu: FC = () => {
  const [modalState, setModalState] = useState<ModalState>({
    showModal: false,
    editMode: false,
    readOnlyMode: false,
    appSubMenus: {},
  })

  const openModal = (mode: 'create' | 'view' | 'edit', appSubMenus: any = {}) => {
    setModalState((prevState) => {
      return {
        ...prevState,
        showModal: true,
        editMode: mode === 'edit',
        readOnlyMode: mode === 'view',
        appSubMenus: appSubMenus,
      }
    })
  }
  const closeModal = () => {
    setModalState((prevState) => {
      return {
        ...prevState,
        showModal: false,
      }
    })
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <Helmet>
        <title> App Sub-Menu | Overview</title>
      </Helmet>
      <PageTitle
        breadcrumbs={[
          {
            isActive: false,
            path: '',
            isSeparator: true,
            title: 'Permissions',
          },
        ]}
      >
        App Sub-Menu
      </PageTitle>
      <AppSubMenuTable openModal={openModal} reRenderComponent={modalState.showModal === false} />
      <AddSubMenuModal
        closeModal={closeModal}
        showModal={modalState.showModal && !(modalState.editMode || modalState.readOnlyMode)}
      />
      <UpdateSubMenuModal
        closeModal={closeModal}
        editMode={modalState.editMode}
        readOnlyMode={modalState.readOnlyMode}
        showModal={modalState.showModal && (modalState.editMode || modalState.readOnlyMode)}
        appSubMenu={modalState.appSubMenus}
      />
    </>
  )
}
