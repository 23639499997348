import React, {useState, Dispatch, SetStateAction} from 'react'

type ModalFunction = Dispatch<SetStateAction<boolean>>

interface ReportingManagerContextProps {
  isCreateModalOpen: boolean
  modalFunction: ModalFunction
  ReportingManagers: any[]
  managerSelected: any
  loadReportingManagerFunction: Dispatch<SetStateAction<any[]>>
  resetManagerSelected: any
  selectManager: any
}

export const ReportingManagerContext = React.createContext<ReportingManagerContextProps>({
  isCreateModalOpen: false,
  modalFunction: () => {}, // Default empty function
  ReportingManagers: [],
  managerSelected: {},
  loadReportingManagerFunction: () => {}, // Default empty function
  resetManagerSelected: () => {},
  selectManager: () => {},
})

function ReportingManagerContextProvider({children}: any) {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [ReportingManagers, setReportingManagers] = useState<any[]>([])
  const [managerSelected, setManagerSelected] = useState<any>({})

  const modalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsCreateModalOpen(value)
  }

  const loadReportingManagerFunction: Dispatch<SetStateAction<any[]>> = (
    value: SetStateAction<any[]>
  ) => {
    setReportingManagers(value)
  }

  const resetManagerSelected = () => {
    setManagerSelected({})
  }

  const selectManager = (value: any) => {
    setManagerSelected(value)
  }

  return (
    <ReportingManagerContext.Provider
      value={{
        isCreateModalOpen,
        modalFunction,
        ReportingManagers,
        managerSelected,
        loadReportingManagerFunction,
        resetManagerSelected,
        selectManager,
      }}
    >
      {children}
    </ReportingManagerContext.Provider>
  )
}

export default ReportingManagerContextProvider
