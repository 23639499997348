/* eslint-disable jsx-a11y/anchor-is-valid */
import {ReactNode, useContext, useEffect, useState} from 'react'
import {LeaveContext} from '../../context/LeaveContext'
import {AppLeaveService} from '../service/leave.service'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {Spinner} from 'react-bootstrap'
import {format} from 'date-fns'
import {IST_FORMAT} from '../../../common/globals/common.constants'
import {useSearchParams} from 'react-router-dom'
import {PaginationComponent} from '../../../common/components/pagination/PaginationComponent'

type RowState = {
  [key: number]: boolean
}

function EmployeeLeavesTable() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isActionOpen, setIsActionOpen] = useState<RowState>({})
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get('page'))

  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    showingFrom: 1,
    showingTill: 10,
    page: page ? page : 1,
  })

  const currentPage = paginationState.page

  const onPageChange = (page: any) => {
    const currentPage = page.selected + 1
    setPaginationState((previousState) => {
      return {...previousState, page: currentPage}
    })
  }

  const {
    leaveDetails,
    filterOptions,
    loadData,
    selectAllChecked,
    handleCheckboxChange,
    checkedRows,
    openModal,
    refreshPage,
    limit,
  } = useContext(LeaveContext)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      let id = ''
      const tokenResponse = await AppLeaveService.getEmployeeViaToken()
      if ('User' in tokenResponse && 'appUserId' in tokenResponse.User)
        id = tokenResponse.User.appUserId
      let page = paginationState.page
      let offset = (page - 1) * paginationState.itemsPerPage
      const response = await AppLeaveService.getLeaves({
        appUserId: filterOptions?.appUserId ? filterOptions.appUserId : id,
        search: filterOptions?.search ? filterOptions.search : null,
        from: filterOptions?.from ? filterOptions.from : null,
        to: filterOptions?.to ? filterOptions.to : null,
        leaveType: filterOptions?.leaveType ? filterOptions.leaveType : null,
        status: filterOptions?.status ? filterOptions.status : null,
        metaData: {
          sortBy: null,
          sortOn: null,
          limit,
          offset,
          fields: null,
        },
      })
      if ('data' in response && response.error === false) {
        loadData(response.data)
        const totalRecords = response.metaData.total_records || 0
        setTotalRecords(totalRecords)
        const currentPage = paginationState.page
        const itemsPerPage = paginationState.itemsPerPage
        let showingFrom = (currentPage - 1) * itemsPerPage + 1
        let showingTill: any
        if (totalRecords) showingTill = Math.min(totalRecords, currentPage * itemsPerPage)
        setPaginationState((prevState) => {
          return {
            ...prevState,
            showingFrom: showingFrom,
            showingTill: showingTill,
          }
        })
      }
      setIsLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterOptions.appUserId,
    filterOptions.from,
    filterOptions.to,
    filterOptions.leaveType,
    filterOptions.search,
    filterOptions.status,
    refreshPage,
    limit,
  ])

  const toggleAction = (rowId: number) => {
    // Toggle the visibility state for the specified row
    setIsActionOpen((prevIsActionOpen) => ({
      ...prevIsActionOpen,
      [rowId]: !prevIsActionOpen[rowId] || false,
    }))
  }

  // Disable the action button if any action button is open
  const isAnyActionOpen = Object.values(isActionOpen).some((isOpen) => isOpen)

  return (
    <KTCardBody>
      {isLoading ? (
        <div className='d-flex align-items-center justify-content-center loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : (
        <>
          <table
            className='table align-middle table-row-dashed table-responsive fs-6 gy-5'
            id='leavesTable'
          >
            <thead>
              <tr
                className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0 sticky-top'
                style={{backgroundColor: 'white', zIndex: '1'}}
              >
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      id='selectAll'
                      className='form-check-input'
                      type='checkbox'
                      value='selectAll'
                      checked={selectAllChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </th>
                <th className='text-center min-w-150px'>Leave Type</th>
                <th className='text-center min-w-100px'>From</th>
                <th className='text-center min-w-100px'>To</th>
                <th className='text-center min-w-200px'>Leave Reason</th>
                <th className='text-center min-w-100px'>Status</th>
                <th className='text-center min-w-20px'></th>
              </tr>
            </thead>
            <tbody>
              {leaveDetails?.map((leave: any, index: number) => (
                <tr key={leave._id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        id={leave._id}
                        className='form-check-input selectBox'
                        type='checkbox'
                        checked={checkedRows.includes(leave._id || '')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </td>
                  <td className='mw-150px text-center cursor-pointer'>
                    <span
                      className='badge badge-light-dark text-hover-primary text-gray-700 mb-1'
                      style={{fontSize: 'medium'}}
                      onClick={() => openModal('view', leave)}
                    >
                      {leave?.leaveType}
                    </span>
                  </td>
                  <td className='mw-100px text-center text-truncate'>
                    {leave?.from
                      ? format(new Date(leave.from), IST_FORMAT).split(',')[0]
                      : ('' as ReactNode)}
                  </td>
                  <td className='mw-100px text-center text-truncate'>
                    {leave?.to
                      ? format(new Date(leave.to), IST_FORMAT).split(',')[0]
                      : ('' as ReactNode)}
                  </td>
                  <td className='mw-200px text-center text-truncate'>{leave?.leaveReason}</td>
                  <td className='mw-100px text-center text-truncate'>
                    <span
                      className={`badge ${
                        leave?.status === 'Approved'
                          ? 'badge-success'
                          : leave?.status === 'Pending'
                          ? 'badge-warning'
                          : 'badge-danger'
                      }`}
                    >
                      {leave?.status}
                    </span>
                  </td>
                  <td className='mw-20px text-center' style={{position: 'relative'}}>
                    <button
                      className='btn btn-light btn-active-light-primary btn-sm'
                      disabled={!isActionOpen[index] && isAnyActionOpen}
                      onClick={() => toggleAction(index)}
                      style={{transition: 'background-color 0.3s ease'}}
                    >
                      <i className='fa-solid fa-ellipsis-vertical ms-3'></i>
                    </button>
                    {isActionOpen && (
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                        style={{
                          display: isActionOpen[index] ? 'block' : 'none',
                          position: 'absolute',
                          left: '-268%',
                          top: `${index === 0 ? '80%' : '-165%'}`,
                          transition: 'opacity 0.3s ease, transform 0.3s ease',
                          opacity: isActionOpen[index] ? 1 : 0,
                          transform: isActionOpen[index] ? 'translateY(0)' : 'translateY(-10px)',
                          zIndex: 2,
                        }}
                      >
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3'
                            onClick={() => {
                              openModal('view', leave)
                              toggleAction(index)
                            }}
                          >
                            View
                          </a>
                        </div>
                        <div className='menu-item px-3'>
                          <a
                            className='menu-link px-3'
                            onClick={() => {
                              openModal('edit', leave)
                              toggleAction(index)
                            }}
                          >
                            Edit
                          </a>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {leaveDetails.length === 0 && (
            <div
              className='d-flex align-items-center justify-content-center'
              style={{height: '100px'}}
            >
              <h2>No Data Found</h2>
            </div>
          )}
          <div className='d-flex justify-content-between mt-5'>
            <PaginationComponent
              onPageChange={onPageChange}
              pageCount={Math.ceil(totalRecords / paginationState.itemsPerPage)}
              showingFrom={paginationState.showingFrom}
              showingTill={paginationState.showingTill}
              totalRecords={totalRecords}
              currentPage={currentPage}
            />
          </div>
        </>
      )}
    </KTCardBody>
  )
}

export default EmployeeLeavesTable
