import {useContext} from 'react'
import EmployeeLeaves from './EmployeeLeaves'
import {LeaveContext} from './context/LeaveContext'
import EmployeeLeavesModal from './components/modal/EmployeeLeavesModal'

function LeaveActions() {
  const {modalState} = useContext(LeaveContext)

  return (
    <>
      <EmployeeLeaves />
      {modalState.showModal && <EmployeeLeavesModal />}
    </>
  )
}

export default LeaveActions
