import {useEffect, useState} from 'react'
import React from 'react'
import {useLocation} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import api from '../RequestConfig'
import '../employee_management/modals/employee_leave_model/exampleleave.css'
import {GET_ALL_FILES} from '../employee_management/EmployeeFiles'
import EmployeeEmail from './modals/employee_email_modals/EmployeeEmail'
// import { preview_file } from "../employee_management/EmployeeFiles";
const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_DETAILS = `${API_URL}/agent/account/get-agent-details`
const EMPLOYEE_LEAVE = `${API_URL}/user/attendance/get-user-attendance`
const GET_SINGLE_HISTORY = `${API_URL}/template/get-single-template-history`

interface UserDetails {
  _id: string
  first_name: string
  last_name: string
  email: string
  password: string
  appReportingManagerId: {
    _id: string
    appUserId: {
      _id: string
      first_name: string
      last_name: string
      email: string
      password: string
      appReportingManagerId: string | null
      appAccessGroupId: string
      appDepartmentId: string
      appDesignationId: string | null
      employee_type: string
      isDeleted: boolean
      isAdminstrator: boolean
      createdAt: string
      updatedAt: string
      appUserIdOfDepartment: string
      __v: number
    } | null
    isDeleted: boolean
    createdAt: string
    updatedAt: string
    __v: number
  } | null

  appAccessGroupId: {
    _id: string
    name: string
    description: string | null
    isAdministrator: boolean
    isDeleted: boolean
    __v: number
  }
  appDepartmentId: {
    _id: string
    name: string
    description: string | null
    isDeleted: boolean
    createdAt: string
    updatedAt: string
    __v: number
  }
  appDesignationId: {
    _id: string
    name: string
    description: string | null
    isDeleted: boolean
    createdAt: string
    updatedAt: string
    __v: number
  } | null
  employee_type: string
  isDeleted: boolean
  isAdminstrator: boolean
  createdAt: string
  updatedAt: string
  appUserIdOfDepartment: string
  __v: number
}

interface CompanyDetails {
  _id: string
  appUserId: string
  company_email: string
  gender: string
  contact_number: number
  date_of_birth: string | null
  date_of_joining: string
  working_hours: string
  salary: string
  marital_status: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  __v: number
}

interface BankDetails {
  _id: string
  appUserId: string
  account_number: string | null
  name_as_per_bank: string | null
  bank_name: string | null
  ifsc_code: string | null
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  __v: number
}

interface AddressDetails {
  _id: string
  appUserId: string
  country: {
    _id: string
    countryShortId: string
    sortname: string
    name: string
  }
  state: {
    _id: string
    stateShortId: string
    name: string
    country_id: string
  }
  city: {
    _id: string
    cityShortId: string
    name: string
    state_id: string
  }
  address: string
  pincode: number
  landmark: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  __v: number
}

interface ContactDetails {
  _id: string
  appUserId: string
  number: string
  relation: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  __v: number
}

interface LeaveDetails {
  totalYearLeave: {
    totalLeave: number
    takenLeave: number
  }
  casualLeave: {
    totalLeave: number
    takenLeave: number
  }
  sickLeave: {
    totalLeave: number
    takenLeave: number
  }
  privilegeLeave: {
    totalLeave: number
    takenLeave: number
  }
  _id: string
  appUserId: string
  swapLeave: number
  saturday_leave: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  __v: number
}

interface Details {
  user_details: UserDetails
  company_details: CompanyDetails
  bank_details: BankDetails
  address_details: AddressDetails
  contact_details: ContactDetails
  leave_details: LeaveDetails
}

interface LeaveData {
  error: boolean
  data: {
    usersAttendances: {
      userAttendanceId: string
      status: string
      createdAt: string
    }[]
  }
}

interface HistoryData {
  _id: string
  appTemplateId: string
  appSignatureId: string | null
  receiverId: string
  receiverName: string
  date: string
  subject: string
  discription: string
  recipients: string[]
  title: string
  ccRecipients: string[]
  emailBody: string
  emailSubject: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  __v: number
}

function EmployeeDetails() {
  const location: any = useLocation()
  const [details, setDetails] = React.useState<Details>()
  const [isLoading, setIsLoading] = React.useState(false)

  //getall files states
  const [Profile, setProfile] = useState('')
  const [Aadhar, setAadhar] = useState('')
  const [Pan, setPan] = useState('')
  const [otherfile, setOtherfile] = useState(' ')

  // eslint-disable-next-line
  const [email, setEmail] = useState<Details>()
  //manage templeted states
  const [allhistory, setAllhistory] = useState<HistoryData[]>([])

  useEffect(() => {
    ;(async () => {
      const id = location.state
      await load_details(id)
    })()
    // eslint-disable-next-line
  }, [])

  function formatDate(date: any) {
    const date1 = new Date(date)
    const month = String(date1.getMonth() + 1).padStart(2, '0')
    const day = String(date1.getDate()).padStart(2, '0')
    const year = date1.getFullYear()

    return `${month}/${day}/${year}`
  }

  async function load_details(id: any) {
    setIsLoading(true)
    const varToken = localStorage.getItem('token')

    try {
      const result = await api.post(
        GET_USER_DETAILS,
        {
          appUserId: id,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      setDetails(result.data.data)

      const userEmail = result.data.data.user_details.email
      await getSingleHistory(userEmail)
      setEmail(userEmail)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  //

  const id = location.state
  // eslint-disable-next-line
  const [token, setToken] = useState<string | null>(null)
  // eslint-disable-next-line
  const [leavedata, setleavedata] = useState<LeaveData | null>(null)

  // const filterLeaveData = (leaveType: string) => {
  //   return leavedata?.data.usersAttendances.filter((attendance) => attendance.status === leaveType)
  // }

  // const sickLeaveData = filterLeaveData('SICK_LEAVE')
  // const casualLeaveData = filterLeaveData('CASUAL_LEAVE')
  // const privilegeLeaveData = filterLeaveData('PRIVILEGE_LEAVE')

  useEffect(() => {
    // Fetch the token from localStorage
    const storedToken = localStorage.getItem('token')
    if (storedToken) {
      setToken(storedToken)
      employeeLeaveAttendance(storedToken)
    }
    // eslint-disable-next-line
  }, [])

  const employeeLeaveAttendance = async (accessToken: string | null) => {
    if (!accessToken) {
      return
    }

    try {
      const response = await api.post(
        EMPLOYEE_LEAVE,
        {
          appUserId: id,
          date: {
            from: '2023-01-01',
            to: '2023-11-30',
          },
          metaData: {
            sortBy: null,
            sortOn: null,
            limit: 360,
            offset: 0,
            fields: [],
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + accessToken,
          },
        }
      )
      setleavedata(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  // get all files

  const varToken = localStorage.getItem('token')
  const getALLFiles = async () => {
    try {
      const result = await api.post(
        GET_ALL_FILES,
        {
          appUserId: id,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      setProfile(result?.data?.data?.appUserFiles?.profile_picture?.original_name)
      setAadhar(result?.data?.data?.appUserFiles?.aadhar_card_file?.original_name)
      setPan(result?.data?.data?.appUserFiles?.pan_card_file?.original_name)
      setOtherfile(result?.data?.data?.appUserFiles?.otherFiles[0]?.original_name)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getALLFiles()
    // eslint-disable-next-line
  }, [])

  // preview file
  const preview_file = async (file: any) => {
    const id = location.state
    window.open(
      `${process.env.REACT_APP_FILE_SERVER_DOMAIN}/${process.env.REACT_APP_UPLOAD_FILE_PATH}${id}/documents/${file}`,
      '_blank'
    )
  }

  const getSingleHistory = async (email: any) => {
    const userEmail = `${email}`
    try {
      const result = await api.post(
        GET_SINGLE_HISTORY,
        {
          appTemplateId: null,
          appSignatureId: null,
          recipients: [userEmail],
          search: null,
          metaData: {
            sortBy: null,
            sortOn: null,
            limit: null,
            offset: null,
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      setAllhistory(result.data.data.singleTempleteHistory)
    } catch (error) {
      console.error(error)
    }
  }

  const isDisabled = location?.state?.isDisabled || false

  return (
    <>
      {isLoading ? (
        <div className='d-flex align-items-center justify-content-center loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : !isDisabled ? (
        <div>
          {/* {Profile Section} */}
          <div className='card shadow' style={{width: '100%', margin: '0 auto'}}>
            <div className='card-body'>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                    Profile Section
                  </h1>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <div className='form-group'>
                    <label
                      htmlFor='firstName'
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                    >
                      First Name:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.user_details ? details.user_details.first_name : ''}
                    </span>
                  </div>

                  <div className='form-group' style={{marginTop: '20px'}}>
                    <label
                      htmlFor='lastName'
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                    >
                      Last Name:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.user_details ? details.user_details.last_name : ''}
                    </span>
                  </div>
                </div>

                <div style={{flex: '1'}}>
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='email'
                    >
                      Email:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.user_details ? details.user_details.email : ''}
                    </span>
                  </div>

                  <div className='form-group' style={{marginTop: '20px'}}>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='appUserIdOfDepartment'
                    >
                      Department Id:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.user_details.appUserIdOfDepartment
                        ? details.user_details.appUserIdOfDepartment
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* end of profile section */}
          {/* main div with display flex */}
          <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
            {/* sub-div with display grid  (left side) */}
            <div style={{display: 'grid', width: '48%'}}>
              {/* {Basic Details} */}
              <div
                className='card shadow'
                style={{
                  alignContent: 'left',
                  marginTop: '20px',
                  padding: '20px',
                }}
              >
                <div className='card-body'>
                  <div>
                    <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                      Basic Details
                    </h1>
                    <br />
                  </div>
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='gender'
                    >
                      Gender
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.company_details ? details.company_details.gender : ''}
                    </span>
                  </div>

                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='marital_status'
                    >
                      Marital Status:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.company_details ? details.company_details.marital_status : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='contact_number'
                    >
                      Contact Number:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.company_details ? details.company_details.contact_number : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='date_of_birth'
                    >
                      Date of Birth:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.company_details.date_of_birth
                        ? formatDate(details.company_details.date_of_birth)
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
              {/* Bank Information */}
              <div
                className='card shadow'
                style={{
                  alignContent: 'left',
                  marginTop: '20px',
                  padding: '20px',
                }}
              >
                <div className='card-body'>
                  <div>
                    <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                      Bank Information
                    </h1>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='account_number'
                    >
                      Account Number:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.bank_details.account_number
                        ? details.bank_details.account_number
                        : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='bank_name'
                    >
                      Bank Name:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.bank_details.bank_name ? details.bank_details.bank_name : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='name_as_per_bank'
                    >
                      Name as per bank:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.bank_details.name_as_per_bank
                        ? details.bank_details.name_as_per_bank
                        : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='ifsc_code'
                    >
                      IFSC CODE:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.bank_details.ifsc_code ? details.bank_details.ifsc_code : ''}
                    </span>
                  </div>
                </div>
              </div>
              {/* Address Information */}
              <div
                className='card shadow'
                style={{
                  alignContent: 'left',
                  marginTop: '20px',
                  padding: '20px',
                }}
              >
                <div className='card-body'>
                  <div>
                    <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                      Address Information
                    </h1>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='country'
                    >
                      Country:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.address_details ? details.address_details.country.name : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='state'
                    >
                      State:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.address_details ? details.address_details.state.name : ''}
                    </span>
                  </div>
                  <br />

                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='city'
                    >
                      City:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.address_details ? details.address_details.city.name : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='address'
                    >
                      Address:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.address_details ? details.address_details.address : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='landmark'
                    >
                      Landmark:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.address_details ? details.address_details.landmark : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='pincode'
                    >
                      Pincode:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.address_details ? details.address_details.pincode : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* sub div with display grid (right side) */}
            <div style={{display: 'grid', width: '48%'}}>
              {/* {Work Details} */}
              <div
                className='card shadow'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                  padding: '20px',
                }}
              >
                <div className='card-body'>
                  <div>
                    <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                      Work Information
                    </h1>

                    <br />
                    {/* primary email */}
                    {/* <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='primary_email'
                      >
                        Primary Email:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details.company_details ? details.company_details.primary_email : ''}
                      </span>
                    </div>
                    <br /> */}
                    {/* company email */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='company_email'
                      >
                        Company Email:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.company_details.company_email
                          ? details.company_details.company_email
                          : ''}
                      </span>
                    </div>
                    <br />
                    {/* salary */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='salary'
                      >
                        Salary:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.company_details ? details.company_details.salary : ''}
                      </span>
                    </div>
                    <br />
                    {/* date of joining */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='date_of_joining'
                      >
                        Date of Joining:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.company_details
                          ? formatDate(details.company_details.date_of_joining)
                          : ''}
                      </span>
                    </div>
                    <br />
                    {/* employee type */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='employee_type'
                      >
                        Employee Type:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.user_details ? details.user_details.employee_type : ''}
                      </span>
                    </div>
                    <br />
                    {/* working hours */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='working_hours'
                      >
                        Working Hours:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.company_details ? details.company_details.working_hours : ''}
                      </span>
                    </div>
                    <br />
                    {/* department */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='department'
                      >
                        Department:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.user_details ? details.user_details.appDepartmentId.name : ''}
                      </span>
                    </div>
                    <br />
                    {/* designation */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='designation'
                      >
                        Designation:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.user_details.appDesignationId
                          ? details.user_details.appDesignationId.name
                          : ''}
                      </span>
                      <div style={{display: 'flex'}}></div>
                    </div>
                    <br />
                    {/* access group */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='department'
                      >
                        Access Group:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.user_details ? details.user_details.appAccessGroupId.name : ''}
                      </span>
                      <div style={{display: 'flex'}}></div>
                    </div>
                    <br />
                    {/* reporting managers */}
                    <div className='form-group'>
                      <label
                        style={{
                          marginBottom: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                        htmlFor='reporting_manager'
                      >
                        Reporting Manager:
                      </label>
                      <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                        {details?.user_details.appReportingManagerId
                          ? details.user_details.appReportingManagerId.appUserId
                            ? details.user_details.appReportingManagerId.appUserId.email
                            : 'No Manager Assigned'
                          : ''}
                      </span>
                      <div style={{display: 'flex'}}></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Start Leave Information */}
              <div
                className='card shadow'
                style={{
                  alignContent: 'left',
                  marginTop: '20px',
                  padding: '20px',
                }}
              >
                <div className='card-body'>
                  <div>
                    <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                      Leave Information
                    </h1>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='number'
                    >
                      Total Allowed Leave:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.leave_details
                        ? details.leave_details.totalYearLeave.totalLeave
                        : ''}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='number'
                    >
                      Sick Leave:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.leave_details ? details.leave_details.sickLeave.takenLeave : ''} /{' '}
                      {details?.leave_details ? details.leave_details.sickLeave.totalLeave : ''}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='number'
                    >
                      Casual Leave:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.leave_details ? details.leave_details.casualLeave.takenLeave : ''} /{' '}
                      {details?.leave_details ? details.leave_details.casualLeave.totalLeave : ''}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='number'
                    >
                      Privilage Leave:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.leave_details
                        ? details.leave_details.privilegeLeave.takenLeave
                        : ''}{' '}
                      /{' '}
                      {details?.leave_details
                        ? details.leave_details.privilegeLeave.totalLeave
                        : ''}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='number'
                    >
                      Swap Leave:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.leave_details ? details.leave_details.swapLeave : ''}
                    </span>
                  </div>
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='number'
                    >
                      Saturday Leave:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.leave_details ? details.leave_details.saturday_leave : ''}
                    </span>
                  </div>
                </div>
              </div>
              {/* end of Leave information */}

              {/* Emergency contact information */}
              <div
                className='card shadow'
                style={{
                  alignContent: 'left',
                  marginTop: '20px',
                  padding: '20px',
                }}
              >
                <div className='card-body'>
                  <div>
                    <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                      Emergency Contact Information
                    </h1>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='number'
                    >
                      Emergency Contact Number:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.contact_details ? details.contact_details.number : ''}
                    </span>
                  </div>
                  <br />
                  <div className='form-group'>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='relation'
                    >
                      Relation with Employee:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>
                      {details?.contact_details ? details.contact_details.relation : ''}
                    </span>
                  </div>
                </div>
              </div>
              {/* end of emergency information */}
            </div>
          </div>

          {/* {Files and Documents} */}
          {/* <div style={{width: '100%'}}>
            <div
              className='card shadow'
              style={{
                marginTop: '20px',
                padding: '20px',
              }}
            >
              <div className='card-body'>
                <div>
                  <h1 style={{color: 'grey', }} className='card-title'>
                    Files and Documents
                  </h1>
                  <br />
                  aadhar card number
                </div>
                <div className='form-group'>
                  <label htmlFor='aadhar_number'>Aadhar Number:</label>
                </div>
                <br />
                aadhar card file
                <div className='form-group'>
                  <label htmlFor='aadhar_number'>Aadhar File:</label>
                </div>
                <br />
                Pan number 
                <div className='form-group'>
                  <label htmlFor='aadhar_number'>Pan Number:</label>
                </div>
                <br />
                Pan card file
                <div className='form-group'>
                  <label htmlFor='pan_card'>Pan File:</label>
                </div>
                <br />
                Documents
                <div className='form-group'>
                  <label htmlFor='aadhar_number'>Documents:</label>
                </div>
              </div>
            </div>
          </div> */}

          {/* upload file information */}

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div
              className='card shadow'
              style={{flex: 1, margin: '20px 13px 20px 0', padding: '20px'}}
            >
              <div className='card-body'>
                <div>
                  <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                    Uploaded Files Information
                  </h1>
                </div>
                <br />
                <div
                  className='form-group'
                  style={{display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center'}}
                >
                  <div>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='Aadhar'
                    >
                      Aadhar:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>{Aadhar}</span>
                  </div>
                  <i
                    className='fas fa-info-circle'
                    style={{cursor: 'pointer', color: 'grey', fontSize: '1.2em'}}
                    onClick={() => preview_file(Aadhar)}
                  ></i>
                </div>
                <br />
                <div
                  className='form-group'
                  style={{display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center'}}
                >
                  <div>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='Pan'
                    >
                      Pan:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>{Pan}</span>
                  </div>
                  <i
                    className='fas fa-info-circle'
                    style={{cursor: 'pointer', color: 'grey', fontSize: '1.2em'}}
                    onClick={() => preview_file(Pan)}
                  ></i>
                </div>
                <br />
                <div
                  className='form-group'
                  style={{display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center'}}
                >
                  <div>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='Profile'
                    >
                      Profile:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>{Profile}</span>
                  </div>
                  <i
                    className='fas fa-info-circle'
                    style={{cursor: 'pointer', color: 'grey', fontSize: '1.2em'}}
                    onClick={() => preview_file(Profile)}
                  ></i>
                </div>
                <br />
                <div
                  className='form-group'
                  style={{display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center'}}
                >
                  <div>
                    <label
                      style={{
                        marginBottom: '8px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1.2em',
                      }}
                      htmlFor='Other_File'
                    >
                      Other File:
                    </label>
                    <span style={{marginLeft: '8px', fontSize: '1.2em'}}>{otherfile}</span>
                  </div>
                  <i
                    className='fas fa-info-circle'
                    style={{cursor: 'pointer', color: 'grey', fontSize: '1.2em'}}
                    onClick={() => preview_file(otherfile)}
                  ></i>
                </div>
              </div>
            </div>

            <div
              className='card shadow'
              style={{flex: 1, margin: '20px 0px 20px 13px', padding: '20px'}}
            >
              <div className='card-body'>
                <div>
                  <h1 style={{color: 'darkorange', fontSize: '30px'}} className='card-title'>
                    Email Information
                  </h1>
                </div>
                <br />
                {allhistory.length === 0 ? (
                  <p style={{fontSize: '17px', textAlign: 'center', marginTop: '60px'}}>
                    No Emails Sent yet
                  </p>
                ) : (
                  allhistory.map((entry, index) => (
                    <div
                      className='form-group'
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <label
                          style={{
                            marginBottom: '8px',
                            fontWeight: 'bold',
                            color: 'black',
                            fontSize: '1.2em',
                          }}
                        >
                          {entry.title} :
                        </label>
                        <span
                          style={{
                            marginLeft: '8px',
                            fontSize: '1.2em',
                            color: 'black',
                          }}
                        >
                          {entry.date}
                        </span>
                      </div>
                      <div>
                        <i
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                          className='fas fa-eye'
                          style={{
                            cursor: 'pointer',
                            color: 'grey',
                            fontSize: '1.2em',
                          }}
                        ></i>
                        <EmployeeEmail
                          title={entry.title}
                          date={entry.date}
                          discription={entry.discription}
                          ccRecipients={entry.ccRecipients}
                          emailBody={entry.emailBody}
                          emailSubject={entry.emailSubject}
                          recipients={entry.recipients}
                          receiverName={entry.receiverName}
                          subject={entry.subject}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
export default EmployeeDetails