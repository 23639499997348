import {FC} from 'react'
type Props = {
  title?: string
  date?: string
  discription?: string
  ccRecipients?: string[]
  emailBody?: string
  emailSubject?: string
  recipients?: string[]
  receiverName?: string
  subject?: string
}

const EmployeeEmail: FC<Props> = ({
  title,
  date,
  discription,
  ccRecipients,
  emailBody,
  emailSubject,
  recipients,
  receiverName,
  subject,
}) => {
  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1' style={{paddingLeft: '263px'}}>
        <div className='modal-dialog ' style={{maxWidth: '1000px'}}>
          <div className='modal-content'>
            <div className='modal-body' style={{display: 'flex', width: '100%'}}>
              <div
                style={{flex: 1, display: 'flex', paddingRight: '10px', flexDirection: 'column'}}
              >
                <h2 style={{marginBottom: '25px', textAlign: 'center'}}>Email Content </h2>
                <div style={{marginBottom: '7px'}}>
                  <label
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '1.2em',
                    }}
                  >
                    To :
                  </label>
                  <span
                    style={{
                      fontSize: '1.2em',
                      color: 'black',
                    }}
                  >
                    {'  '} {recipients}
                  </span>
                </div>
                <div style={{marginBottom: '7px'}}>
                  <label
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '1.2em',
                    }}
                  >
                    CC :
                  </label>
                  <span
                    style={{
                      fontSize: '1.2em',
                      color: 'black',
                    }}
                  >
                    {'  '} {ccRecipients}
                  </span>
                </div>

                <div style={{marginBottom: '7px'}}>
                  <label
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '1.2em',
                    }}
                  >
                    Emal Subject :
                  </label>
                  <span
                    style={{
                      fontSize: '1.2em',
                      color: 'black',
                    }}
                  >
                    {'  '}
                    {emailSubject}
                  </span>
                </div>
                <div style={{marginBottom: '7px'}}>
                  <label
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '1.2em',
                    }}
                  >
                    Email Body :
                  </label>
                  <span
                    style={{
                      fontSize: '1.2em',
                      color: 'black',
                    }}
                  >
                    {'  '} {emailBody}
                  </span>
                </div>
              </div>

              <div style={{flex: 1, paddingLeft: '30px', borderLeft: '1px solid #ccc'}}>
                <h2 style={{marginBottom: '25px', textAlign: 'center'}}>Attechment </h2>
                <div style={{fontSize: 'small'}}>
                  <p className='text-start'>To,</p>
                  <p className='text-start'>The Employee,</p>
                  <p className='text-start'>{receiverName}</p>
                  <p className='text-start'>Dear Sir,</p>
                  <div className='inline'>
                    <p>
                      <div style={{marginLeft: '110px'}}>
                        <b>Sub: </b>
                        {subject}
                      </div>
                    </p>
                  </div>
                  <p className='text-start'>
                    <b>Ref: </b>ovefoodlovelife Health Services Private Limited
                  </p>
                  <div>
                    <div className='text-start'>
                      <textarea
                        className='form-control'
                        id='exampleFormControlTextarea1'
                        value={discription}
                        style={{
                          border: 'none !importnat',
                          width: '446px',
                          height: '162px',
                          fontSize: '13px',
                          fontWeight: '400',
                          color: '#424040',
                          marginBottom: '10px',
                        }}
                      ></textarea>
                    </div>
                    <p className='text-start'>Thank you for your assistance.</p>
                    <div className='flex'>
                      <div className='d-inline-block text-start'>
                        <p>Date - {date}</p>
                      </div>
                      <div className='d-inline-block float-end'>
                        <p>Yours faithfully,</p>
                      </div>
                    </div>
                    <div className='flex'>
                      <div className='d-inline-block text-start'></div>
                      <div className='d-inline-block float-end'>
                        <p className=''>For Lovefoodlovelife Health Services Private Limited</p>
                      </div>
                    </div>
                  </div>
                  <div className='float-end'></div>
                </div>
              </div>
            </div>
            <div
              className='modal-footer'
              style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeEmail
