import RequestConfig from '../../RequestConfig'

export class AppMenuService {
    private static baseUrl: string = `${process.env.REACT_APP_API_URL}/permission/menu`;

    private static token = localStorage.getItem('token')

    static async getAppMenus(
        options?: any
    ): Promise<any> {
        try {
            const request = await RequestConfig.post(
                `${this.baseUrl}/get-menu`,
                options,
                {
                    headers: {
                        genie_access_token: `Bearer ${this.token}`
                    }
                }
            );
            const response = request.data;
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    static async createAppMenu(
        appMenuDetails: any
    ): Promise<any> {
        try {
            const request = await RequestConfig.post(
                `${this.baseUrl}/create-menu`,
                appMenuDetails,
                {
                    headers: {
                        genie_access_token: `Bearer ${this.token}`
                    }
                }
            );
            const response = request.data;
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    static async updateAppMenu(
        appMenuDetails: any
    ): Promise<any> {
        try {
            const request = await RequestConfig.put(
                `${this.baseUrl}/update-menu`,
                appMenuDetails,
                {
                    headers: {
                        genie_access_token: `Bearer ${this.token}`
                    }
                }
            );
            const response = request.data;
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    static async deleteAppMenus(
        appMenuIds: string[]
    ): Promise<any> {
        try {
            const request = await RequestConfig.delete(`${this.baseUrl}/delete-menu`, {
                data: { appMenuIds },
                headers: {
                    genie_access_token: `Bearer ${this.token}`
                }
            });
            const response = request.data;
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }
}