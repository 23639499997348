import React, {useEffect, useState} from 'react'
import api from '../RequestConfig'
import {Spinner} from 'react-bootstrap'
import Select from 'react-select'

import {Link} from 'react-router-dom'
import {GET_ALL_DEPARTMENTS} from './CompanyEmployees'
import {TimesheetCard} from '../../../_metronic/partials/content/cards/TimesheetCard'
import {ActivityCard} from '../../../_metronic/partials/content/cards/ActivityCard'
import {KTSVG} from '../../../_metronic/helpers'
import {KTCardBody} from '../../../_metronic/helpers'
import {KTCard} from '../../../_metronic/helpers'
import ReactPaginate from 'react-paginate'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import './customAttendanceStyle.css'
import {GET_ALL_EVENTS} from '../holiday/HolidayCalendar'
import Swal from 'sweetalert2'
import 'react-toastify/dist/ReactToastify.css'
import {showToast} from '../common/toastify/toastify.config'
const API_URL = process.env.REACT_APP_API_URL
export const GET_EMPLOYEES_ATTENDANCE = `${API_URL}/user/attendance/get-attendance`
export const GET_EMPLOYEE_ACTIVITY = `${API_URL}/agent/activity/get-user-activity`
export const GET_WORKING_DETAILS = `${API_URL}/agent/activity/get-user-working-details`
export const GET_EMPLOYEE_LEAVE = `${API_URL}/agent/activity/get-user-leave`
export const UPDATE_ATTENDANCE = `${API_URL}/user/attendance/update-attendance`

const permissionsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Employee Management',
    path: '/employee_management/company-employees',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface Employee {
  appUserId: string
  fullName: string
  email: string
  profilePicturePath: string | null
  userAttendances: Attendance[]
}

interface Attendance {
  totalLoginTimeWithoutBreak: any
  _id: string
  status: string
  createdAt: string
  event: string
  leaveDescription: string
  isAutoLoggedOut: boolean
}

function AttendancePage() {
  const [allEmployees, setAllEmployees] = useState<any>([])
  const [employeeActivity, setEmployeeActivity] = useState<any>([])
  const [employeeLeave, setEmployeeLeave] = useState<any>({})
  const [departments, setDepartments] = useState([])
  const [currentDepartment, setCurrentDepartment] = useState<string | null>(null)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [timeLast, setTimeLast] = useState<any>([])
  const [workingDetails, setWorkingDetails] = useState<any>([])
  const [calendarEvents, setCalendarEvents] = useState([])
  const [employeeName, setEmployeeName] = useState<any>('')
  const [selectedDate, setSelectedDate] = useState<any>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  // Variables for Year and Month
  const monthOptions = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]
  const year = new Date().getFullYear()
  const yearOptions = Array.from({length: 20}, (_, index) => year + index - 10)

  const [currentMonth, setCurrentMonth] = useState<string>(monthOptions[new Date().getMonth()])
  const [currentYear, setCurrentYear] = useState<string>(new Date().getFullYear().toString())
  const daysInMonth = new Date(
    parseInt(currentYear),
    monthOptions.indexOf(currentMonth) + 1,
    0
  ).getDate()
  const dates = Array.from({length: daysInMonth}, (_, index) => index + 1)

  // Function to get all the days in the month eg: Mon, Tue etc
  function getDaysInMonthArray(year: number, month: number): string[] {
    const daysInMonthArray: string[] = []
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    // Ensure the month is within the valid range (0-11)
    if (month < 0 || month > 11) {
      throw new Error('Invalid month. Month should be between 0 and 11.')
    }

    // Loop through the days of the month
    for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
      const currentDay = new Date(year, month, day)
      const dayOfWeek = daysOfWeek[currentDay.getDay()]
      daysInMonthArray.push(dayOfWeek)
    }

    return daysInMonthArray
  }

  const daysInMonthArray = getDaysInMonthArray(
    parseInt(currentYear),
    monthOptions.indexOf(currentMonth)
  )

  // Variables for Present/Absent Function
  const [attendanceId, setAttendanceId] = useState('')
  const [userId, setUserId] = useState('')
  const [compAvailability, setCompAvailability] = useState('')

  // Defining variables for the pagination part
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(5)
  const lastIndex = currentPage * recordsPerPage
  const firstIndex = lastIndex - recordsPerPage
  const records = allEmployees.slice(firstIndex, lastIndex)
  const noOfPages = Math.ceil(allEmployees.length / recordsPerPage)
  const totalRecords = allEmployees.length

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  // Function to get all employees attendance
  const loadAttendance = async () => {
    setIsLoading(true)
    const varToken = localStorage.getItem('token')

    try {
      const result = await api.post(
        GET_EMPLOYEES_ATTENDANCE,
        {
          search: search !== '' && search !== ' ' ? search : null,
          month: currentMonth,
          year: currentYear,
          appDepartmentId: currentDepartment,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      setAllEmployees(result?.data?.data?.usersAttendances)
    } catch (error) {
      console.error('Attendance Page error = ', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Function to load departments
  const loadDepartments = async () => {
    const varToken = localStorage.getItem('token')
    try {
      const result = await api.post(
        GET_ALL_DEPARTMENTS,
        {
          search: null,
          metaData: {
            limit: 0,
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      setDepartments(result.data.data.AppDepartment)
    } catch (err) {
      console.error(err)
    }
  }

  // Function to convert 24 hour time to 12 hour time
  function convertTo12HourFormat(timeStr: string): string {
    try {
      const [hoursStr, minutesStr] = timeStr.split(':')
      const hours = parseInt(hoursStr)

      const period = hours < 12 ? 'AM' : 'PM'

      let formattedHours = hours % 12
      if (formattedHours === 0) {
        formattedHours = 12
      }

      const formattedTime = `${String(formattedHours).padStart(2, '0')}:${minutesStr} ${period}`

      return formattedTime
    } catch (error) {
      return 'Invalid time format'
    }
  }

  // Function to change the date format
  function convertDateFormat(dateStr: any) {
    try {
      const [year, month, day] = dateStr.split('-')
      return `${day}-${month}-${year}`
    } catch (error) {
      return 'Invalid date format'
    }
  }

  // Function to load employee activity
  const loadActivity = async (
    dateStr: string,
    email: string,
    aId: string,
    uId: string,
    avail: string
  ) => {
    // setIsLoading(true)
    setAttendanceId(aId)
    setUserId(uId)
    setCompAvailability(avail)
    const varToken = localStorage.getItem('token')
    try {
      const result = await api.post(
        GET_EMPLOYEE_ACTIVITY,
        {
          appActivityId: null,
          appUserId: uId,
          date: {
            from: dateStr,
            to: dateStr,
          },
          search: null,
          metaData: {
            sortBy: null,
            sortOn: null,
            limit: 0,
            offset: 0,
            fields: [],
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )

      const leave_result = await api.post(
        GET_EMPLOYEE_LEAVE,
        {
          appUserId: uId,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )

      const userActivities = result.data.data.UserActivities.map((userActivity: any) => ({
        ...userActivity,
        date: convertDateFormat(userActivity.createdAt.split('T')[0]),
        activities: userActivity.activities.map((activity: any) => ({
          ...activity,
          time: convertTo12HourFormat(activity.time),
        })),
      }))

      setEmployeeLeave(leave_result.data.data.UserLeaveDetails)
      setEmployeeActivity(userActivities)
      setTimeLast(userActivities[0].activities.slice(-1))
    } catch (err) {
      console.error(err)
    } finally {
      // setIsLoading(false)
    }
  }

  // Function to load the working details
  const loadWorkingDetails = async (id: string, date: string) => {
    const varToken = localStorage.getItem('token')

    try {
      const result = await api.post(
        GET_WORKING_DETAILS,
        {
          appUserId: id,
          date: {
            from: date,
            to: date,
          },
          search: null,
          metaData: {
            sortBy: null,
            sortOn: null,
            limit: 0,
            offset: 0,
            fields: [],
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      setWorkingDetails(result.data.data.UserActivities)
    } catch (error) {
      console.error(error)
    }
  }

  const getLastDayOfMonth = (month: string, year: number): string | null => {
    const monthIndex = monthOptions.indexOf(month)

    if (monthIndex === -1) {
      return 'Invalid Month'
    }

    // Create a Date object for the next month's first day
    const nextMonthFirstDay = new Date(year, monthIndex + 1, 1)

    // Subtract one day from it to get the last day of the current month
    const lastDay = new Date(nextMonthFirstDay.getTime() - 1)

    // Extract the day of the month as a string
    const lastDayOfMonth = lastDay.getDate().toString()

    return lastDayOfMonth
  }

  // Function  to get all events
  const getCalendarEvents = async () => {
    const varToken = localStorage.getItem('token')

    const lastDate = getLastDayOfMonth(currentMonth, parseInt(currentYear))
    try {
      const result = await api.post(
        GET_ALL_EVENTS,
        {
          startDate: `01-${currentMonth}-${currentYear}`,
          endDate: `${lastDate}-${currentMonth}-${currentYear}`,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )

      setCalendarEvents(result.data.data.appCalendarEvents)
    } catch (error) {
      console.error('Error in get calendar events = ', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadAttendance()
    loadDepartments()
    getCalendarEvents()
    // eslint-disable-next-line
  }, [currentDepartment, search, currentMonth, currentYear])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // Functions to extract all the sundays in a month
  const getDatesInMonth = (year: number, month: number) => {
    const firstDay: Date = new Date(year, month, 1)
    const lastDay: Date = new Date(year, month + 1, 0)
    const datesInMonth: Date[] = []

    for (let date: Date = firstDay; date <= lastDay; date.setDate(date.getDate() + 1)) {
      datesInMonth.push(new Date(date))
    }

    return datesInMonth
  }

  const extractSundays = (year: number, month: number) => {
    const datesInMonth: Date[] = getDatesInMonth(year, month)
    const sundays: number[] = datesInMonth
      .filter((date) => date.getDay() === 0)
      .map((date) => date.getDate())

    return sundays
  }

  const sundays: number[] = extractSundays(
    parseInt(currentYear),
    monthOptions.indexOf(currentMonth)
  )

  const [changeLeave, setChangeLeave] = useState('')
  const [currentStatus, setCurrentStatus] = useState('')
  const [currentDescription, setCurrentDescription] = useState('')

  // Function to Mark Present/Absent
  const markPresentAbsent = async () => {
    const varToken = localStorage.getItem('token')
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await api.put(
        UPDATE_ATTENDANCE,
        {
          appUserAttendanceId: attendanceId,
          appUserId: userId,
          availability: compAvailability,
          status: changeLeave,
          leaveDescription: currentDescription,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      if (result.data.error === false) {
        loadAttendance()
      }
    } catch (error) {
      console.error(error)
    }
  }

  // Get Day from a given date string
  const getDay = (dateString: string) => {
    const parsedDate: Date = new Date(dateString)
    const day: number = parsedDate.getDate()
    return day
  }
  const getDayInIst = (dateString: string): number | null => {
    const parsedDate = new Date(dateString)
    return isNaN(parsedDate.getTime()) ? null : parsedDate.getDate()
  }

  // Variable to set the color of the row
  let isRowDark = false

  const handleFutureEventClick = (event: any) => {
    Swal.fire({
      title: `Wooo Hooo its a holiday due to ${event} today!`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
  }

  function changeDateOrder(inputDate: string): string {
    const [year, month, day] = inputDate.split('-')
    return `${day}-${month}-${year}`
  }

  const asteriskStyle = {
    color: 'red',
  }

  // Function for the table rows
  const tableRows = records.map((emp: Employee) => {
    isRowDark = !isRowDark

    // Get which dates the employee has worked on
    const workingDates: {[key: number]: Attendance} = {}
    emp.userAttendances.forEach((attData: Attendance) => {
      const day = getDay(attData.createdAt)
      workingDates[day] = attData
    })

    // Get which dates have events on them.
    const eventDates: {[key: number]: any} = {}
    calendarEvents.forEach((event: any) => {
      const day = getDayInIst(event.startDate)
      if (day) {
        eventDates[day] = event
      }
    })

    const firstName: string = emp.fullName.split(' ')[0]
    const lastName: string = emp.fullName.split(' ')[1]

    return (
      <tr key={emp.appUserId} className='py-5 fw-bolder fs-6'>
        <td
          className='min-w-250px'
          style={{
            position: 'sticky',
            zIndex: 1,
            left: 0,
            color: 'black',
            backgroundColor: '#f5f5f5',
          }}
        >
          <div className='symbol symbol-30px symbol-circle me-4' style={{marginLeft: '10px'}}>
            <img alt='Pic' src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}`} />
          </div>
          <Link
            style={{marginLeft: '10px', color: 'black'}}
            to={'/employee_management/edit-details'}
            state={emp.appUserId}
            className='link'
            data-bs-toggle='tooltip'
          >
            {emp.fullName}
          </Link>
        </td>
        {dates.map((currDate: number, index: number) => {
          if (sundays.includes(currDate)) {
            if (workingDates[currDate] !== undefined) {
              return (
                <td
                  key={index}
                  className='text-center min-w-70px'
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_1'
                  title={`Total Working Hours = ${workingDates[currDate]?.totalLoginTimeWithoutBreak}`}
                  data-toggle='tooltip'
                  onClick={() => {
                    setEmployeeName(emp.fullName)
                    const dateStr = changeDateOrder(workingDates[currDate].createdAt.split('T')[0])
                    setSelectedDate(dateStr)
                    setCurrentStatus(workingDates[currDate].status)
                    setCurrentDescription(workingDates[currDate].leaveDescription)
                    loadActivity(
                      workingDates[currDate].createdAt.split('T')[0],
                      emp.email,
                      workingDates[currDate]._id,
                      emp.appUserId,
                      workingDates[currDate].status
                    )
                    loadWorkingDetails(
                      emp.appUserId,
                      workingDates[currDate].createdAt.split('T')[0]
                    )
                    setIsModalOpen(true)
                  }}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: 'lightyellow',
                  }}
                >
                  <div className='working'>
                    S
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                </td>
              )
            }
            return (
              <td
                key={index}
                className='text-center min-w-70px'
                style={{backgroundColor: '#ffffe6'}}
              >
                <div className='weekend'>
                  S{workingDates[currDate]?.isAutoLoggedOut && <span style={asteriskStyle}>*</span>}
                </div>
              </td>
            )
          } else if (eventDates[currDate] && workingDates[currDate]) {
            const day = getDayInIst(eventDates[currDate].endDate)
            if (day && day > currDate) {
              eventDates[currDate + 1] = eventDates[currDate]
            }
            return (
              <td
                key={index}
                className='text-center min-w-70px'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_1'
                title={`Total Working Hours = ${workingDates[currDate]?.totalLoginTimeWithoutBreak}`}
                data-toggle='tooltip'
                onClick={() => {
                  setEmployeeName(emp.fullName)
                  const dateStr = changeDateOrder(workingDates[currDate].createdAt.split('T')[0])
                  setSelectedDate(dateStr)
                  setCurrentStatus(workingDates[currDate].status)
                  setCurrentDescription(workingDates[currDate].leaveDescription)
                  loadActivity(
                    workingDates[currDate].createdAt.split('T')[0],
                    emp.email,
                    workingDates[currDate]._id,
                    emp.appUserId,
                    workingDates[currDate].status
                  )
                  loadWorkingDetails(emp.appUserId, workingDates[currDate].createdAt.split('T')[0])
                  setIsModalOpen(true)
                  showToast(`${eventDates[currDate].title} on this day!`, 'success')
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                {workingDates[currDate].status === 'ABSENT' ? (
                  <div className='notWorking'>
                    A
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'PRESENT' ? (
                  <div className='working'>
                    P
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'SICK_LEAVE' ? (
                  <div className='notWorking'>
                    SL
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'CASUAL_LEAVE' ? (
                  <div className='notWorking'>
                    CL
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'PRIVILEGE_LEAVE' ? (
                  <div className='notWorking'>
                    PL
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'SWAP_LEAVE' ? (
                  <div className='notWorking'>
                    SWL
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'WEEKEND' ? (
                  <div className='weekend'>
                    W
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : (
                  <div className='working'>
                    HD
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                )}
              </td>
            )
          } else if (workingDates[currDate]) {
            return (
              <td
                key={index}
                className='text-center min-w-70px'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_1'
                title={`Total Working Hours = ${workingDates[currDate]?.totalLoginTimeWithoutBreak}`}
                data-toggle='tooltip'
                onClick={() => {
                  setEmployeeName(emp.fullName)
                  const dateStr = changeDateOrder(workingDates[currDate].createdAt.split('T')[0])
                  setSelectedDate(dateStr)
                  setCurrentStatus(workingDates[currDate].status)
                  setCurrentDescription(workingDates[currDate].leaveDescription)
                  loadActivity(
                    workingDates[currDate].createdAt.split('T')[0],
                    emp.email,
                    workingDates[currDate]._id,
                    emp.appUserId,
                    workingDates[currDate].status
                  )
                  loadWorkingDetails(emp.appUserId, workingDates[currDate].createdAt.split('T')[0])
                  setIsModalOpen(true)
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                {workingDates[currDate].status === 'ABSENT' ? (
                  <div className='notWorking'>
                    A
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'PRESENT' ? (
                  <div className='working'>
                    P
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'SICK_LEAVE' ? (
                  <div className='notWorking'>
                    SL
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'CASUAL_LEAVE' ? (
                  <div className='notWorking'>
                    CL
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'PRIVILEGE_LEAVE' ? (
                  <div className='notWorking'>
                    PL
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'SWAP_LEAVE' ? (
                  <div className='notWorking'>
                    SWL
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : workingDates[currDate].status === 'WEEKEND' ? (
                  <div className='weekend'>
                    W
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                ) : (
                  <div className='working'>
                    HD
                    {workingDates[currDate]?.isAutoLoggedOut && (
                      <span style={asteriskStyle}>*</span>
                    )}
                  </div>
                )}
              </td>
            )
          } else if (eventDates[currDate]) {
            const day = getDayInIst(eventDates[currDate].endDate)
            if (day && day > currDate) {
              eventDates[currDate + 1] = eventDates[currDate]
            }
            return (
              <td
                key={index}
                className='text-center min-w-70px'
                onClick={() => handleFutureEventClick(eventDates[currDate].title)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <div className='notWorking'>H</div>
              </td>
            )
          }

          return (
            <td key={index} className='text-center min-w-70px'>
              N/A
            </td>
          )
        })}
      </tr>
    )
  })

  // Function for handling search
  function handleSearch(searchValue: string) {
    setSearch(searchValue)
  }

  const leaveOptions = [
    {
      value: 'ABSENT',
      label: 'ABSENT',
      isdisabled: false,
    },
    {
      value: 'PRESENT',
      label: 'PRESENT',
      isdisabled: false,
    },
    {
      value: 'SICK_LEAVE',
      label: `Sick Leave (Taken Leaves - ${
        employeeLeave?.sickLeave?.takenLeave ? employeeLeave?.sickLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.sickLeave?.totalLeave ? employeeLeave?.sickLeave?.totalLeave : 0
      })`,
      isdisabled: employeeLeave?.sickLeave?.takenLeave >= employeeLeave?.sickLeave?.totalLeave,
    },
    {
      value: 'CASUAL_LEAVE',
      label: `Casual Leave (Taken Leaves - ${
        employeeLeave?.casualLeave?.takenLeave ? employeeLeave?.casualLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.casualLeave?.totalLeave ? employeeLeave?.casualLeave?.totalLeave : 0
      })`,
      isdisabled: employeeLeave?.casualLeave?.takenLeave >= employeeLeave?.casualLeave?.totalLeave,
    },
    {
      value: 'PRIVILEGE_LEAVE',
      label: `Privilege Leave (Taken Leaves - ${
        employeeLeave?.privilegeLeave?.takenLeave ? employeeLeave?.privilegeLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.privilegeLeave?.totalLeave ? employeeLeave?.privilegeLeave?.totalLeave : 0
      })`,
      isdisabled:
        employeeLeave?.privilegeLeave?.takenLeave >= employeeLeave?.privilegeLeave?.totalLeave,
    },
    {
      value: 'SWAP_LEAVE',
      label: 'Swap Leave',
      isdisabled: false,
    },
  ]

  const handleLeaveChange = (selectedOption: any) => {
    setChangeLeave(selectedOption.value)
  }
  const handleAddDescription = (e: any) => {
    setCurrentDescription(e.target.value)
  }

  const handlePageChange = (data: any) => {
    setCurrentPage(data.selected + 1)
  }

  return (
    <>
      <PageTitle breadcrumbs={permissionsBreadCrumbs}>All Employees Attendance</PageTitle>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid mx-2'
                data-kt-select2='true'
                data-placeholder='Show records'
                defaultValue={5}
                onChange={(e) => setRecordsPerPage(parseInt(e.target.value))}
              >
                <option value='5'>5 Records</option>
                <option value='10'>10 Records</option>
                <option value='15'>15 Records</option>
                <option value='25'>25 Records</option>
                <option value='50'>50 Records</option>
              </select>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <div className='vstack align-items-center'>
              <div>
                <h2 className='mb-0 fs-1 fw-bolder' style={{display: 'block'}}>
                  Attendance Calendar
                </h2>
              </div>
              <div>
                <h6
                  className='mb-0 fs-3'
                  style={{
                    fontWeight: 'normal',
                    color: 'grey',
                  }}
                >
                  {`${currentMonth} ${currentYear}`}
                </h6>
              </div>
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {/* begin::Filter Button */}
              <button
                type='button'
                className='btn btn-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                Filter
              </button>
              {/* end::Filter Button */}
              {/* begin::SubMenu */}
              <div
                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                data-kt-menu='true'
              >
                {/* begin::Header */}
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
                {/* end::Header */}
                <div className='separator border-gray-200'></div>
                {/* {search bar} */}
                <div className='d-flex align-items-center position-relative px-4 py-4'>
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid ps-10 w-100'
                    placeholder='Search Employees'
                    onChange={(e: any) => {
                      handleSearch(e.target.value)
                    }}
                  />
                </div>
                {/* {search bar} */}
                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}
                {/* begin::Dropdown for selecting department */}
                <div className='d-flex align-items-center position-relative px-4 py-4'>
                  <select
                    className='form-select form-select-solid w-100'
                    data-kt-select2='true'
                    data-placeholder='Select Department'
                    data-allow-clear='true'
                    onChange={(e) => {
                      setCurrentDepartment(e.target.value !== 'null' ? e.target.value : null)
                    }}
                  >
                    <option value={'null'}>Select Department</option>
                    {departments.map((dn: any) => (
                      <option key={dn.appDepartmentId} value={dn.appDepartmentId}>
                        {dn.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* end::Dropdown for selecting department */}
                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}
                {/* begin::Dropdown for selecting month */}
                <div className='d-flex align-items-center position-relative px-4 py-4'>
                  <select
                    className='form-select form-select-solid w-100'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    onChange={(e) => {
                      if (e.target.value !== 'null') {
                        setCurrentMonth(e.target.value)
                      }
                    }}
                  >
                    <option value={'null'}>Select Month</option>
                    {monthOptions.map((dn: any, index: number) => (
                      <option value={dn} key={index}>
                        {dn}
                      </option>
                    ))}
                  </select>
                </div>
                {/* end::Dropdown for selecting month */}
                {/* end::Dropdown for selecting department */}
                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}
                {/* begin::Dropdown for selecting year */}
                <div className='d-flex align-items-center position-relative px-4 py-4'>
                  <select
                    className='form-select form-select-solid w-100'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    onChange={(e) => {
                      if (e.target.value !== 'null') {
                        setCurrentYear(e.target.value)
                      }
                    }}
                  >
                    <option value={'null'}>Select Year</option>
                    {yearOptions.map((dn: any, index: number) => (
                      <option value={dn} key={index}>
                        {dn}
                      </option>
                    ))}
                  </select>
                </div>
                {/* end::Dropdown for selecting year */}
              </div>
              {/* end::SubMenu */}
            </div>
          </div>
        </div>

        <KTCardBody className='py-4'>
          {isLoading ? (
            <div className='d-flex align-items-center justify-content-center loader-container'>
              <Spinner animation='border' variant='primary' />
            </div>
          ) : (
            <div className='table-responsive' style={{maxHeight: '65vh', overflowY: 'auto'}}>
              <table
                id='kt_table_users'
                className='table table-bordered align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th
                      className='min-w-250px px-7 text-center align-middle'
                      style={{
                        position: 'sticky',
                        zIndex: 1,
                        left: 0,
                        top: 0,
                        color: 'black',
                        backgroundColor: '#e9e4e0',
                      }}
                    >
                      Employee
                    </th>
                    {dates.map((e: any, index: number) => {
                      if (e < 10) {
                        return (
                          <th
                            key={index}
                            className='min-w-70px text-center'
                            style={{backgroundColor: '#e9e4e0'}}
                          >
                            <div
                              style={{color: 'black', fontWeight: 'bold'}}
                            >{`${currentMonth} 0${e}`}</div>{' '}
                            <div style={{color: 'black', fontWeight: 'lighter'}}>
                              {daysInMonthArray[index]}
                            </div>
                          </th>
                        )
                      }
                      return (
                        <th
                          key={index}
                          className='min-w-70px text-center'
                          style={{backgroundColor: '#e9e4e0'}}
                        >
                          <div
                            style={{color: 'black', fontWeight: 'bold'}}
                          >{`${currentMonth} ${e}`}</div>{' '}
                          <div style={{color: 'black', fontWeight: 'lighter'}}>
                            {daysInMonthArray[index]}
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>{tableRows}</tbody>
              </table>
            </div>
          )}
          {/* Modal/Attendance Info popup */}
          <div
            className={`modal fade ${isModalOpen ? 'show' : ''}`}
            tabIndex={-1}
            id='kt_modal_1'
            aria-modal={isModalOpen}
            role='dialog'
            style={{
              display: isModalOpen ? 'block' : 'none',
              zIndex: '2',
              top: '10%',
              left: '6%',
            }}
          >
            {/* <div className={`modal fade`} tabIndex={-1} id='kt_modal_1'> */}
            <div className='modal-dialog modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <div className='vstack align-items-center'>
                    <div>
                      <h2 className='mb-0 fs-3 fw-bolder' style={{display: 'block'}}>
                        {`${employeeName} Attendance Info`}
                      </h2>
                    </div>
                    <div>
                      <h6
                        className='mb-0 fs-4'
                        style={{
                          fontWeight: 'normal',
                          color: 'grey',
                        }}
                      >
                        {selectedDate}
                      </h6>
                    </div>
                  </div>
                  {/* Close button */}
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    // data-bs-dismiss='modal'
                    // aria-label='Close'
                    onClick={handleCloseModal}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  {isLoading ? (
                    <div className='d-flex align-items-center justify-content-center loader-container'>
                      <Spinner animation='border' variant='primary' />
                    </div>
                  ) : employeeActivity.length !== 0 ? (
                    // Display When attendance record is present
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <TimesheetCard
                        name={employeeActivity[0]?.fullName}
                        title='Timesheet'
                        date={employeeActivity[0]?.date}
                        checkInTime={employeeActivity[0]?.activities[0]?.time}
                        checkOutTime={
                          timeLast[0]?.activity === 'checkout' ? timeLast[0]?.time : '- - -'
                        }
                        totalBreakTime={workingDetails[0]?.totalBreakTime}
                        totalCheckInTime={workingDetails[0]?.totalLoggedinTime}
                        timeWithoutBreak={workingDetails[0]?.totalLoginTimeWithoutBreak}
                        overtime={workingDetails[0]?.overTime}
                        status={workingDetails[0]?.status}
                        employeeLeave={employeeLeave}
                        handleAddDescription={handleAddDescription}
                        currentDescription={currentDescription}
                        handleLeaveChange={handleLeaveChange}
                      />
                      <div style={{flex: 1, marginLeft: '10px'}}>
                        <ActivityCard activityData={employeeActivity[0].activities} />
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* <h4 style={{marginBottom: "20px"}}>No Records Found</h4> */}
                      <Select
                        name='absent'
                        onChange={handleLeaveChange}
                        defaultValue={[
                          {
                            value: currentStatus,
                            label: currentStatus,
                            isdisabled: false,
                          },
                        ]}
                        options={leaveOptions}
                        isOptionDisabled={(option) => option.isdisabled}
                        className='basic-single'
                        classNamePrefix='select'
                      />
                      <div className='form-group' style={{marginTop: '20px'}}>
                        <label className='fw-bold fs-6 mb-2'>Description</label>
                        <textarea
                          rows={2}
                          id='description'
                          name='description'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          placeholder={currentDescription ? currentDescription : 'Enter Reason'}
                          onChange={handleAddDescription}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
                {/* Modal Footer */}
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light'
                    // data-bs-dismiss='modal'
                    // aria-label='Close'
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      markPresentAbsent()
                      handleCloseModal()
                    }}
                    // data-bs-dismiss='modal'
                    // aria-label='Close'
                  >
                    Apply Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </KTCardBody>
        <div className='d-flex justify-content-between align-items-center'>
          <span className='fs-6 fw-bold text-gray-700 px-4 py-4'>
            Showing {firstIndex + 1} to {lastIndex <= totalRecords ? lastIndex : totalRecords} of{' '}
            {totalRecords} Employees
          </span>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={noOfPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={'pagination px-4 py-4'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
        <div className='accordion' id='kt_accordion_1'>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_1'>
              <button
                className='accordion-button fs-4 fw-bold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_1'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_1'
              >
                <i className='fa-solid fa-triangle-exclamation' style={{fontSize: '24px'}}></i>
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_1'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_1'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <span className='fw-bold text-gray-700'>
                  H - Holiday | P - Present | A - Absent | HD - Half Day | S - Sunday | W - Weekend
                  | CL - Casual Leave | PL - Privilege Leave | SL - Sick Leave | SWL - Swap Leave |
                  N/A - Not Available
                </span>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
    </>
  )
}

export default AttendancePage
