/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import RequestConfig from '../../../../../app/modules/RequestConfig'
import { AppMenu, ConstructMenuResponse } from './app_menu.model'

const SidebarMenuMain = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const intl = useIntl()

  const [appMenus, setAppMenus] = useState<AppMenu[]>([])

  const getConstructedAppMenus = async () => {
    try {
      const API_URL = process.env.REACT_APP_API_URL
      const request = await RequestConfig.get(`${API_URL}/permission/menu/construct-menu`, {
        headers: {
          genie_access_token: `Bearer ${localStorage.getItem('token')}`
        }
      })
      const response: ConstructMenuResponse = request.data
      setAppMenus(response.data.appMenus)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getConstructedAppMenus()
  }, [])

  return (
    <>
      {appMenus.map(appMenu => {
        return (
          !appMenu.hasSubMenus ? (
            <SidebarMenuItem key={appMenu.name} title={appMenu.name} to={appMenu.url || ''} />
          ) : (
            <SidebarMenuItemWithSub key={appMenu.name} title={appMenu.name} to={appMenu.url || ''}>
              {appMenu.appSubMenus?.map(appSubMenu => (
                <SidebarMenuItem key={appSubMenu.sequenceNumber} title={appSubMenu.name} to={appSubMenu.url} />
              ))}
            </SidebarMenuItemWithSub>
          )
        );
      })}
    </>
  )
}

export { SidebarMenuMain }
