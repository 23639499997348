/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useEffect} from 'react'
import Select from 'react-select'

type Props = {
  title?: string
  date?: string
  checkInTime?: any
  checkOutTime?: string
  totalCheckInTime?: string
  totalBreakTime?: string
  overtime?: string
  timeWithoutBreak?: string
  name?: string
  status?: string
  employeeLeave?: any
  handleLeaveChange?: any
  handleAddDescription?: any
  currentDescription?: string
}

const checkInStyle = {
  backgroundColor: '#f9f9f9',
  border: '1px solid #e3e3e3',
  borderRadius: '4px',
  margin: '0 0 20px',
  padding: '10px 15px',
}

const totalTimeDiv = {
  margin: '0 0 20px',
}

const timeInfoDiv = {
  backgroundColor: '#f9f9f9',
  border: '5px solid #e3e3e3',
  fontSize: '18px',
  height: '120px',
  width: '120px',
  margin: '0 auto',
  borderRadius: '50%',
}

const breakOvertimeStyle = {
  backgroundColor: '#f9f9f9',
  border: '1px solid #e3e3e3',
  borderRadius: '4px',
  margin: '0 10px 2px',
  padding: '10px 15px',
}

// Function to convert minutes to Hour and Minutes format
function formatMinutesToHoursAndMinutes(minutesString: string): string {
  const minutes = parseInt(minutesString, 10)

  if (isNaN(minutes)) {
    return 'Invalid input'
  }

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  const formattedString = `${hours} h ${remainingMinutes} m`

  return formattedString
}

const TimesheetCard: FC<Props> = ({
  title,
  date,
  checkInTime,
  checkOutTime,
  totalCheckInTime = '0.0',
  totalBreakTime = '0.0',
  overtime = '0.0',
  timeWithoutBreak = '0.0',
  name = '',
  status = '',
  employeeLeave,
  handleLeaveChange,
  handleAddDescription,
  currentDescription,
}) => {
  totalCheckInTime = formatMinutesToHoursAndMinutes(totalCheckInTime.split(' ')[0])
  totalBreakTime = formatMinutesToHoursAndMinutes(totalBreakTime.split(' ')[0])
  overtime = formatMinutesToHoursAndMinutes(overtime.split(' ')[0])
  timeWithoutBreak = formatMinutesToHoursAndMinutes(timeWithoutBreak.split(' ')[0])

  const leaveOptions = [
    {
      value: 'ABSENT',
      label: 'ABSENT',
      isdisabled: false,
    },
    {
      value: 'PRESENT',
      label: 'PRESENT',
      isdisabled: false,
    },
    {
      value: 'SICK_LEAVE',
      label: `Sick Leave (Taken Leaves - ${
        employeeLeave?.sickLeave?.takenLeave ? employeeLeave?.sickLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.sickLeave?.totalLeave ? employeeLeave?.sickLeave?.totalLeave : 0
      })`,
      isdisabled: employeeLeave?.sickLeave?.takenLeave >= employeeLeave?.sickLeave?.totalLeave,
    },
    {
      value: 'CASUAL_LEAVE',
      label: `Casual Leave (Taken Leaves - ${
        employeeLeave?.casualLeave?.takenLeave ? employeeLeave?.casualLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.casualLeave?.totalLeave ? employeeLeave?.casualLeave?.totalLeave : 0
      })`,
      isdisabled: employeeLeave?.casualLeave?.takenLeave >= employeeLeave?.casualLeave?.totalLeave,
    },
    {
      value: 'PRIVILEGE_LEAVE',
      label: `Privilege Leave (Taken Leaves - ${
        employeeLeave?.privilegeLeave?.takenLeave ? employeeLeave?.privilegeLeave?.takenLeave : 0
      } and Total Leave - ${
        employeeLeave?.privilegeLeave?.totalLeave ? employeeLeave?.privilegeLeave?.totalLeave : 0
      })`,
      isdisabled:
        employeeLeave?.privilegeLeave?.takenLeave >= employeeLeave?.privilegeLeave?.totalLeave,
    },
    {
      value: 'SWAP_LEAVE',
      label: 'Swap Leave',
      isdisabled: false,
    },
  ]

  useEffect(() => {
    // getUserProfile()
    // eslint-disable-next-line
  }, [])

  const cardStyle = {
    height: '350px',
    overflow: 'auto',
  }

  return (
    <div className='card card-border border-4' style={cardStyle}>
      <div className='card-body border border-gray-300 p-9'>
        <div className='fs-3 fw-bolder text-dark'>{date}</div>

        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{title}</p>

        <div className='mb-5'>
          <div style={checkInStyle}>
            <div className='fs-6 text-gray-800 fw-bolder'>{name}</div>
            <div className='fw-bold text-gray-400'>Employee's Name</div>
          </div>
          <div style={checkInStyle}>
            <div className='fs-6 text-gray-800 fw-bolder'>{checkInTime}</div>
            <div className='fw-bold text-gray-400'>Checked In At</div>
          </div>
          <div style={totalTimeDiv}>
            <div className='d-flex flex-column align-items-center' style={timeInfoDiv}>
              <span style={{marginTop: '25%'}}>{totalCheckInTime}</span>
              <span>Total Time</span>
            </div>
          </div>
          <div style={checkInStyle}>
            <div className='fs-6 text-gray-800 fw-bolder'>{checkOutTime}</div>
            <div className='fw-bold text-gray-400'>Checked Out At</div>
          </div>
        </div>
        <div className='d-flex flex-wrap'>
          <div style={breakOvertimeStyle}>
            <div className='fs-6 text-gray-800 fw-bolder'>{totalBreakTime}</div>
            <div className='fw-bold text-gray-400'>Break</div>
          </div>
          <div style={breakOvertimeStyle}>
            <div className='fs-6 text-gray-800 fw-bolder'>{overtime}</div>
            <div className='fw-bold text-gray-400'>Overtime</div>
          </div>
          <div style={breakOvertimeStyle}>
            <div className='fs-6 text-gray-800 fw-bolder'>{timeWithoutBreak}</div>
            <div className='fw-bold text-gray-400'>Without Break</div>
          </div>
        </div>
        {/* Mark leave */}
        <div style={{marginTop: '20px'}}>
          <Select
            name='leave'
            onChange={handleLeaveChange}
            defaultValue={[
              {
                value: status,
                label: status,
                isdisabled: false,
              },
            ]}
            options={leaveOptions}
            isOptionDisabled={(option) => option.isdisabled}
            className='basic-single'
            classNamePrefix='select'
          />
          <div className='form-group' style={{marginTop: '20px'}}>
            <label className='required fw-bold fs-6 mb-2'>Description</label>
            <textarea
              rows={2}
              id='description'
              name='description'
              className='form-control form-control-solid mb-3 mb-lg-0'
              placeholder={currentDescription ? currentDescription : 'Enter Reason'}
              onChange={handleAddDescription}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TimesheetCard}
