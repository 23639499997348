import {useContext, useEffect, useState} from 'react'
import {TicketContext} from '../../context/TicketContext'
import {Option} from '../../../common/globals/common.constants'
import {Helmet} from 'react-helmet'
import {ModalComponent} from '../../../common/components/modal/Modal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import {LoadingButton} from '../../../common/components/LoadingButton/LoadingButton'
import {showToast} from '../../../common/toastify/toastify.config'
import {AppTicketService} from '../service/ticket.service'

type TicketOperationState = {
  editMode: boolean
  showModal: boolean
  readOnlyMode: boolean
  appTicket?: any | {}
}

const createTicketSchema = Yup.object().shape({
  task: Yup.string()
    .trim()
    .min(3, 'Task must be at least 3 characters')
    .max(100, 'Task must be at most 100 characters')
    .required('Task is required'),
  description: Yup.string()
    .trim()
    .min(3, 'Description must be at least 3 characters')
    .max(300, 'Description must be at most 300 characters')
    .nullable(),
  appDepartmentId: Yup.string().trim().required('Department is required'),
  assignedTo: Yup.array()
    .of(Yup.string())
    .min(1, 'Atleast one employee must be selected for this task')
    .required('Assigned To is required'),
  assignedBy: Yup.string().trim().required('Assigned By is required'),
  email: Yup.string(),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  priority: Yup.string().oneOf(['Low', 'Medium', 'High']).required('Priority is required'),
})

const updateTicketSchema = Yup.object().shape({
  appTicketId: Yup.string().required('appTicketId is required'),
  task: Yup.string()
    .trim()
    .min(3, 'Task must be at least 3 characters')
    .max(100, 'Task must be at most 100 characters')
    .required('Task is required'),
  description: Yup.string()
    .trim()
    .min(3, 'Description must be at least 3 characters')
    .max(300, 'Description must be at most 300 characters')
    .nullable(),
  appDepartmentId: Yup.string().trim().required('Department is required'),
  assignedTo: Yup.array()
    .of(Yup.string())
    .min(1, 'Atleast one employee must be selected for this task')
    .required('Assigned To is required'),
  assignedBy: Yup.string().trim().required('Assigned By is required'),
  email: Yup.string(),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required'),
  priority: Yup.string().oneOf(['Low', 'Medium', 'High']).required('Priority is required'),
  status: Yup.string()
    .oneOf(['Open', 'Closed', 'Doubt', 'Testing', 'On Hold', 'Deploy'])
    .required('Status is required'),
  completedPercent: Yup.number()
    .oneOf([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100])
    .required('Completed Percent is required'),
})

type Ticket = {
  task: string
  description: string | null
  appDepartmentId: string
  assignedTo: string[]
  assignedBy: string
  email: string | null
  startDate: Date | string
  endDate: Date | string
  priority: string
  status: string
  completedPercent: number
}

type updateTicket = {
  appUserId: string
  appTicketId: string
  task: string
  description: string | null
  appDepartmentId: string
  assignedTo: string[]
  assignedBy: string
  email: string | null
  startDate: Date | string
  endDate: Date | string
  priority: string
  status: string
  completedPercent: number
}

let initialValues: Ticket = {
  task: '',
  description: null,
  appDepartmentId: '',
  assignedTo: [],
  assignedBy: '',
  email: '',
  startDate: '',
  endDate: '',
  priority: '',
  status: '',
  completedPercent: 0,
}

let updateTicketInitialValues: updateTicket = {
  appUserId: '',
  appTicketId: '',
  task: '',
  description: null,
  appDepartmentId: '',
  assignedTo: [],
  assignedBy: '',
  email: '',
  startDate: '',
  endDate: '',
  priority: '',
  status: '',
  completedPercent: 0,
}

const statusOptions: string[] = ['Open', 'Closed', 'Doubt', 'Testing', 'On Hold', 'Deploy']
const percentCompletedOptions: number[] = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

function EmployeeTicketModal() {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<TicketOperationState>({
    editMode: false,
    showModal: false,
    readOnlyMode: false,
    appTicket: undefined,
  })
  const [selectState, setSelectState] = useState<{
    appUserIds: Option[]
    appDepartmentId: Option
  }>({
    appUserIds: [],
    appDepartmentId: {label: 'Choose One', value: null},
  })
  const [departmentOptions, setDepartmentOptions] = useState<{value: string; label: string}[]>([])
  const [employeeOptions, setEmployeeOptions] = useState<{value: string; label: string}[]>([])
  const {modalState, closeModal, onUpdateData} = useContext(TicketContext)

  const getDepartments = async () => {
    const response = await AppTicketService.loadDepartments()
    if ('data' in response && response.error === false) {
      const departments = response.data.AppDepartment
      setDepartmentOptions(
        departments?.map((department: any) => {
          return {value: department.appDepartmentId || '', label: department.name || ''}
        })
      )
    }
  }

  const getEmployees = async (id: string) => {
    const response = await AppTicketService.loadEmployees(id)
    if ('data' in response && response.error === false) {
      const employees = response.data.AppUsers
      setEmployeeOptions(
        employees?.map((employee: any) => {
          return {value: employee.appUserId || '', label: employee?.company_email || employee.email}
        })
      )
    }
  }

  const formik = useFormik({
    initialValues:
      modalState.editMode || modalState.readOnlyMode ? updateTicketInitialValues : initialValues,
    validationSchema: modalState.editMode ? updateTicketSchema : createTicketSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      values.description = values.description ? values.description : null
      values.assignedBy = modalState?.appTickets?.assignedBy
        ? modalState.appTickets.assignedBy
        : values.assignedBy
      if (!modalState.editMode && !modalState.readOnlyMode) {
        const {email, status, completedPercent, ...appTicketDetails} = values
        const request = await AppTicketService.createTicket(appTicketDetails)
        if ('data' in request && request?.error === false) {
          if ('message' in request.data) {
            showToast(request.data.message, 'success')
          }
          onUpdateData()
          closeModal()
        } else {
          setLoading(false)
        }
      } else {
        if (modalState.appTickets && 'appTicketId' in modalState.appTickets) {
          const {email, ...appTicketDetails} = values
          const request = await AppTicketService.updateTicket(appTicketDetails)
          if ('data' in request && request?.error === false) {
            if ('message' in request.data) {
              showToast(request.data.message, 'success')
            }
            onUpdateData()
            closeModal()
          } else {
            setLoading(false)
          }
        }
      }
      setLoading(false)
      setSubmitting(false)
    },
  })

  useEffect(() => {
    if (!modalState.editMode && !modalState.readOnlyMode) {
      getDepartments()
    }
    setState((prevState) => {
      return {
        ...prevState,
        editMode: modalState.editMode,
        readOnlyMode: modalState.readOnlyMode,
        showModal: modalState.showModal,
      }
    })
    let id = ''
    let email = ''
    ;(async () => {
      const tokenResponse = await AppTicketService.getEmployeeViaToken()
      if (tokenResponse?.User?.appUserId) id = tokenResponse.User.appUserId
      if (tokenResponse?.User?.email || tokenResponse?.User?.company_email)
        email = tokenResponse?.User?.company_email || tokenResponse?.User?.email
      formik.resetForm()
      if (!modalState.editMode && !modalState.readOnlyMode) {
        formik.setFieldValue('assignedBy', id)
        formik.setFieldValue('email', email)
      }
    })()

    if ((modalState.editMode || modalState.readOnlyMode) && modalState.appTickets) {
      if ('appTicketId' in modalState.appTickets) {
        setState((prevState) => {
          return {...prevState, appTicket: modalState.appTickets}
        })
        updateTicketInitialValues = {
          appUserId: id,
          appTicketId: modalState?.appTickets?.appTicketId || '',
          task: modalState?.appTickets?.task || '',
          description: modalState?.appTickets?.description || '',
          appDepartmentId: modalState?.appTickets?.appDepartmentId || '',
          assignedTo: modalState?.appTickets?.assignedTo || [],
          assignedBy: modalState?.appTickets?.assignedBy || '',
          email: modalState?.appTickets?.creator || '',
          startDate: modalState?.appTickets?.startDate || '',
          endDate: modalState?.appTickets?.endDate || '',
          priority: modalState?.appTickets?.priority || '',
          status: modalState?.appTickets?.status || '',
          completedPercent: parseInt(modalState?.appTickets?.completedPercent) || 0,
        }
        const departmentValue: any = {label: '', value: ''}
        const employeeValues: Option[] = []

        ;(async () => {
          const response = await AppTicketService.loadDepartments()
          if ('data' in response && response.error === false) {
            const departments = response.data.AppDepartment
            setDepartmentOptions(
              departments?.map((department: any) => {
                return {value: department.appDepartmentId || '', label: department.name || ''}
              })
            )
            setDepartmentOptions((prevState) => {
              prevState?.forEach((option) => {
                if (modalState.appTickets?.appDepartmentId?.includes(option.value)) {
                  departmentValue.label = option.label
                  departmentValue.value = option.value
                }
              })
              return prevState
            })
          }
          const empResponse = await AppTicketService.loadEmployees(
            modalState?.appTickets?.appDepartmentId
          )
          if ('data' in empResponse && empResponse.error === false) {
            const employees = empResponse.data.AppUsers
            setEmployeeOptions(
              employees?.map((employee: any) => {
                return {
                  value: employee.appUserId || '',
                  label: employee?.company_email || employee.email,
                }
              })
            )
            setEmployeeOptions((prevState) => {
              prevState?.forEach((option) => {
                if (modalState.appTickets?.assignedTo?.includes(option.value)) {
                  employeeValues.push({
                    label: option.label,
                    value: option.value,
                  })
                }
              })
              return prevState
            })
          }
          setSelectState((prevState) => {
            return {
              ...prevState,
              appDepartmentId: {label: departmentValue.label, value: departmentValue.value},
              appUserIds: employeeValues,
            }
          })
        })()
      }
    } else {
      initialValues = {
        task: '',
        description: null,
        appDepartmentId: '',
        assignedTo: [],
        assignedBy: '',
        email: '',
        startDate: '',
        endDate: '',
        priority: '',
        status: '',
        completedPercent: 0,
      }
      setSelectState({
        appDepartmentId: {label: 'Choose One', value: null},
        appUserIds: [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.showModal, modalState.editMode, modalState.readOnlyMode, modalState.appTickets])

  useEffect(() => {
    if (!modalState.editMode && !modalState.readOnlyMode) {
      if (formik.values.appDepartmentId) {
        getEmployees(formik.values.appDepartmentId)
      } else {
        formik.setFieldValue('assignedTo', [])
        setSelectState((prevState) => {
          return {
            ...prevState,
            appUserIds: [],
          }
        })
      }
    } else if (modalState.editMode) {
      if (formik.values.appDepartmentId) {
        getEmployees(formik.values.appDepartmentId)
      } else {
        formik.setFieldValue('assignedTo', [])
        setSelectState((prevState) => {
          return {
            ...prevState,
            appUserIds: [],
          }
        })
      }
    }
    // else {
    //   if (formik.values.appDepartmentId) {
    //     getEmployees(formik.values.appDepartmentId)
    //   } else {
    //     formik.setFieldValue('assignedTo', [])
    //     setSelectState((prevState) => {
    //       return {
    //         ...prevState,
    //         appUserIds: [],
    //       }
    //     })
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.appDepartmentId])

  useEffect(() => {})

  return (
    <>
      {modalState.showModal && (
        <Helmet>
          <title>
            Tickets
            {modalState.showModal
              ? ` | ${
                  state.readOnlyMode
                    ? 'View Ticket'
                    : state.editMode
                    ? 'Update Ticket'
                    : 'Create Ticket'
                }`
              : ''}
          </title>
        </Helmet>
      )}
      <ModalComponent
        handleClose={() => {
          setDepartmentOptions([])
          setEmployeeOptions([])
          setSelectState((prevState) => {
            return {
              ...prevState,
              appUserIds: [],
              appDepartmentId: {label: 'Choose One', value: null},
            }
          })
          setSelectState({appUserIds: [], appDepartmentId: {label: 'Choose One', value: null}})
          closeModal()
        }}
        show={modalState.showModal}
        modalTitle={
          state.readOnlyMode ? 'View Ticket' : state.editMode ? 'Update Ticket' : 'Create Ticket'
        }
        id={'ticketModal'}
      >
        <form onSubmit={formik.handleSubmit} id='ticket_form' className='form'>
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              <span className={clsx({required: !state.readOnlyMode})}>Task</span>
            </label>
            <input
              disabled={state.readOnlyMode}
              type='text'
              {...formik.getFieldProps('task')}
              className={clsx(
                'form-control bg-transparent py-2',
                {'is-invalid': formik.touched.task && formik.errors.task},
                {
                  'is-valid': formik.touched.task && !formik.errors.task,
                }
              )}
              placeholder='Enter a task'
            />
            {formik.touched.task && formik.errors.task && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.task}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              <span>Description</span>
            </label>
            <textarea
              disabled={state.readOnlyMode}
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control bg-transparent py-2',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {'is-valid': formik.touched.description && !formik.errors.description}
              )}
              placeholder='Describe the task'
              style={{resize: 'none'}}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.description}</span>
              </div>
            )}
          </div>
          <div className='fv-row row mb-5'>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                <span className={clsx({required: !state.readOnlyMode})}>Department</span>
              </label>
              <Select
                isDisabled={state.readOnlyMode}
                className={clsx(
                  {
                    'is-invalid': formik.touched.appDepartmentId && formik.errors.appDepartmentId,
                  },
                  {
                    'is-valid': formik.touched.appDepartmentId && !formik.errors.appDepartmentId,
                  }
                )}
                backspaceRemovesValue={true}
                onChange={(option) => {
                  formik.setFieldValue('appDepartmentId', option?.value)
                  setSelectState((prevState) => {
                    return {
                      ...prevState,
                      appDepartmentId: {
                        label: option?.label || 'Choose One',
                        value: option?.value || null,
                      },
                    }
                  })
                }}
                value={{
                  label: selectState.appDepartmentId.label || 'Select',
                  value: selectState.appDepartmentId.value,
                }}
                options={departmentOptions}
                onBlur={formik.handleBlur}
                isMulti={false}
                isClearable={true}
                placeholder={'Select Department'}
              />
              {formik.touched.appDepartmentId && formik.errors.appDepartmentId && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.appDepartmentId}</span>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label
                className={clsx('form-label fs-6 fw-bolder text-dark', {
                  required: !state.readOnlyMode,
                })}
              >
                Priority
              </label>
              <div className='fv-row row'>
                <div className='col-4'>
                  <div data-kt-buttons='true'>
                    <label className='btn btn-sm btn-outline btn-outline-dashed btn-outline-success px-2 w-100'>
                      <div className='d-flex align-items-center justify-content-evenly'>
                        <div className='form-check form-check-custom form-check-solid form-check-success pe-1'>
                          <input
                            disabled={state.readOnlyMode}
                            className='form-check-input w-15px h-15px'
                            type='radio'
                            {...formik.getFieldProps('priority')}
                            value={'Low'}
                            checked={formik.values.priority === 'Low'}
                          />
                        </div>
                        <div className='fw-bold'>Low</div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className='col-4'>
                  <div data-kt-buttons='true'>
                    <label className='btn btn-sm btn-outline btn-outline-dashed btn-outline-warning px-2 w-100'>
                      <div className='d-flex align-items-center justify-content-evenly'>
                        <div className='form-check form-check-custom form-check-solid form-check-warning pe-1'>
                          <input
                            disabled={state.readOnlyMode}
                            className='form-check-input w-15px h-15px'
                            type='radio'
                            {...formik.getFieldProps('priority')}
                            value={'Medium'}
                            checked={formik.values.priority === 'Medium'}
                          />
                        </div>
                        <div className='fw-bold'>Mid</div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className='col-4'>
                  <div data-kt-buttons='true'>
                    <label className='btn btn-sm btn-outline btn-outline-dashed btn-outline-danger px-2 w-100'>
                      <div className='d-flex align-items-center justify-content-evenly'>
                        <div className='form-check form-check-custom form-check-solid form-check-danger pe-1'>
                          <input
                            disabled={state.readOnlyMode}
                            className='form-check-input w-15px h-15px'
                            type='radio'
                            {...formik.getFieldProps('priority')}
                            value={'High'}
                            checked={formik.values.priority === 'High'}
                          />
                        </div>
                        <div className='fw-bold'>High</div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {formik.touched.priority && formik.errors.priority && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.priority}</span>
                </div>
              )}
            </div>
          </div>
          {!state.editMode && !state.readOnlyMode ? (
            <div className='fv-row mb-5'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                <span className={clsx({required: !state.readOnlyMode})}>Assigned By</span>
              </label>
              <input
                disabled
                type='text'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent py-2',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
          ) : (
            <div className='fv-row row mb-5'>
              <div className='col-5'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <span className={clsx({required: !state.readOnlyMode})}>Assigned By</span>
                </label>
                <input
                  disabled
                  type='text'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent py-2',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
              <div className='col-4'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <span className={clsx({required: !state.readOnlyMode})}>Status</span>
                </label>
                <select
                  className='form-select form-select-solid w-100'
                  disabled={state.readOnlyMode}
                  data-placeholder='Select Status'
                  onChange={(e) => {
                    formik.handleChange(e)
                    formik.setFieldValue('status', e.target.value ? e.target.value : null)
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.status || ''}
                >
                  {statusOptions.map((status: string, index: number) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  <span className={clsx({required: !state.readOnlyMode})}>% Finished</span>
                </label>
                <select
                  className='form-select form-select-solid w-100'
                  disabled={state.readOnlyMode}
                  data-placeholder='Select Completed %'
                  onChange={(e) => {
                    formik.handleChange(e)
                    formik.setFieldValue(
                      'completedPercent',
                      e.target.value ? parseInt(e.target.value) : null
                    )
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.completedPercent || ''}
                >
                  {percentCompletedOptions.map((status: number, index: number) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className='fv-row mb-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              <span
                className={clsx({
                  required: !state.readOnlyMode,
                })}
              >
                Assigned To
              </span>
            </label>
            <Select
              isDisabled={state.readOnlyMode}
              className={clsx(
                {
                  'is-invalid': formik.touched.assignedTo && formik.errors.assignedTo,
                },
                {
                  'is-valid': formik.touched.assignedTo && !formik.errors.assignedTo,
                }
              )}
              backspaceRemovesValue={true}
              onChange={(options) => {
                let selectedValues: any = options?.map((option) => {
                  return option?.value
                })
                formik.setFieldValue('assignedTo', selectedValues || [])
                selectedValues = options?.map((option) => {
                  return {label: option.label, value: option.value}
                })
                setSelectState((prevState) => {
                  return {
                    ...prevState,
                    appUserIds: selectedValues || [],
                  }
                })
              }}
              value={selectState.appUserIds}
              options={employeeOptions}
              onBlur={formik.handleBlur}
              isMulti={true}
              placeholder={'Select Employees'}
            />
            {formik.touched.assignedTo && formik.errors.assignedTo && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.assignedTo}</span>
              </div>
            )}
          </div>
          <div className='fv-row row mb-5'>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                <span className={clsx({required: !state.readOnlyMode})}>Start Date</span>
              </label>
              <div className='w-100'>
                <DatePicker
                  selected={
                    formik.values.startDate ? moment(formik.values.startDate).toDate() : null
                  }
                  onChange={(date) => formik.setFieldValue('startDate', date)}
                  className='form-select form-select-solid w-100'
                  placeholderText='Select Start Date'
                />
              </div>
              {formik.touched.startDate && formik.errors.startDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block' style={{paddingLeft: '68px'}}>
                    {formik.errors.startDate}
                  </div>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                <span className={clsx({required: !state.readOnlyMode})}>End Date</span>
              </label>
              <div className='w-100'>
                <DatePicker
                  selected={formik.values.endDate ? moment(formik.values.endDate).toDate() : null}
                  onChange={(date) => formik.setFieldValue('endDate', date)}
                  disabled={!formik.values.startDate}
                  minDate={
                    formik.values.startDate ? moment(formik.values.startDate).toDate() : null
                  }
                  className='form-select form-select-solid w-100'
                  placeholderText='Select End Date'
                />
              </div>
              {formik.touched.endDate && formik.errors.endDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block' style={{paddingLeft: '68px'}}>
                    {formik.errors.endDate}
                  </div>
                </div>
              )}
            </div>
          </div>
          {!state.readOnlyMode && (
            <>
              <div className='d-flex flex-wrap justify-content-evenly pb-lg-0 pt-lg-10 pt-5'>
                <LoadingButton
                  btnText={state.editMode ? 'Update' : 'Submit'}
                  loading={loading}
                  disableBtn={formik.isSubmitting || !formik.isValid || loading}
                  btnClass={'btn btn-primary me-4'}
                />
                <button
                  type='button'
                  onClick={closeModal}
                  className='btn btn-secondary'
                  disabled={formik.isSubmitting || loading}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </form>
      </ModalComponent>
    </>
  )
}

export default EmployeeTicketModal
