import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useState} from 'react'
import {useEffect} from 'react'
import api from '../../../../app/modules/RequestConfig'
import {Option} from '../../../../app/modules/common/globals/common.constants'
import {showToast} from '../../../../app/modules/common/toastify/toastify.config'
const API_URL = process.env.REACT_APP_API_URL
export const GET_PROFILE_PICTURE = `${API_URL}/agent/account-files/get-profile-picture`
export const MARK_ATTENDANCE = `${API_URL}/agent/activity/markAttendence`
export const GET_USER_LAST_ACTIVITY = `${API_URL}/agent/activity/get-user-last-activity`
const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

let lastActivity: any = ''

const Navbar = () => {
  const [profilePicture, setProfilePicture] = useState('')
  const [userCurrentActivity, setUserCurrentActivity] = useState<Option>({
    label: 'Choose',
    value: null,
  })
  const [userActivityOptions, setUserActivityOptions] = useState<Option[]>([])
  const {config} = useLayout()

  useEffect(() => {
    getUserProfile()
  }, [])

  const getUserProfile = async () => {
    const varToken = localStorage.getItem('token')
    try {
      const request = await api.get(`${process.env.REACT_APP_API_URL}/agent/auth/getAgentByToken`, {
        headers: {
          genie_access_token: `Bearer ${varToken}`,
        },
      })
      const response = request.data
      const first_name = response?.User?.username.split(' ')[0] || 'Nourish'
      const last_name = response?.User?.username.split(' ')[1] || 'Genie'
      setProfilePicture(`https://ui-avatars.com/api/?name=${first_name}+${last_name}`)
    } catch (error) {
      console.error(error)
    }
  }

  const userActivities: Option[] = [
    {
      label: 'Check In',
      value: 'checkin',
    },
    {
      label: 'Break In',
      value: 'breakin',
    },
    {
      label: 'Break Out',
      value: 'breakout',
    },
    {
      label: 'Check Out',
      value: 'checkout',
    },
  ]

  const updateUserActivity = async (activity: string) => {
    try {
      const authToken = localStorage.getItem('token')
      const tokenRequest = await api.get(
        `${process.env.REACT_APP_API_URL}/agent/auth/getAgentByToken`,
        {
          headers: {
            genie_access_token: `Bearer ${authToken}`,
          },
        }
      )
      const response = tokenRequest.data
      const email = response?.User?.company_email
      const fullName = response?.User?.username

      const request = await api.post(
        MARK_ATTENDANCE,
        {
          email,
          fullName,
          activity,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + authToken,
          },
        }
      )
      if (request.data.error === false) {
        lastActivity = activity
        showToast(request.data.data.message, 'success')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getUserLastActivity = async () => {
    try {
      const authToken = localStorage.getItem('token')
      const tokenRequest = await api.get(
        `${process.env.REACT_APP_API_URL}/agent/auth/getAgentByToken`,
        {
          headers: {
            genie_access_token: `Bearer ${authToken}`,
          },
        }
      )
      const response = tokenRequest.data
      const email = response?.User?.company_email
      const request = await api.post(
        GET_USER_LAST_ACTIVITY,
        {
          email,
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + authToken,
          },
        }
      )
      if (request.data.error === false) {
        const lastUserActivity = request?.data?.data?.lastUserActivity
        lastActivity = lastUserActivity
        const activityOption = userActivityOptions.find(
          (option) => option.value === lastUserActivity
        )
        setUserCurrentActivity({label: activityOption?.label || '', value: activityOption?.value})
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleUserActivityOptions = () => {
    if (userCurrentActivity.value) {
      lastActivity = userCurrentActivity.value
    }
    let activityOptions = []

    switch (lastActivity) {
      case null:
      case undefined:
        activityOptions = userActivities
        break
      case 'checkin':
        activityOptions = userActivities.filter(
          (activity) => activity.value !== 'breakout' && activity.value !== 'checkin'
        )
        break
      case 'checkout':
        activityOptions = userActivities.filter(
          (activity) =>
            activity.value !== 'breakout' &&
            activity.value !== 'breakin' &&
            activity.value !== 'checkout'
        )
        break
      case 'breakin':
        activityOptions = userActivities.filter(
          (activity) => activity.value !== 'checkin' && activity.value !== 'breakin'
        )
        break
      case 'breakout':
        activityOptions = userActivities.filter(
          (activity) => activity.value !== 'checkin' && activity.value !== 'breakout'
        )
        break
      default:
        return
    }
    setUserActivityOptions(activityOptions)
  }

  useEffect(() => {
    getUserLastActivity()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleUserActivityOptions()
    // eslint-disable-next-line
  }, [userCurrentActivity.value])

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <select
          className='form-select form-select-sm form-select-solid'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={userCurrentActivity.value || ''}
          id='userActivity'
          onChange={(e) => {
            const selectIndex = e.target.options.selectedIndex
            const label = e.target.options[selectIndex].innerHTML
            const value = e.target.value
            setUserCurrentActivity({label: label, value: value})
            updateUserActivity(value)
          }}
        >
          {/* <option value='' disabled hidden>
            {!lastActivity
              ? 'Choose'
              : lastActivity === 'breakout'
              ? 'Check In'
              : userActivities.find((option) => option.value === lastActivity)?.label}
          </option> */}

          {userActivityOptions.map((optionObj) => {
            return (
              <option value={optionObj.value} key={optionObj.value}>
                {optionObj.label}
              </option>
            )
          })}
          <option value='' disabled hidden>
            {'Select'}
          </option>
        </select>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={profilePicture} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
