import React, {useEffect, useState, useRef} from 'react'
import clsx from 'clsx'
import CreateTicket from './CreateNewTicket'
import SendRemainder from './service/SendRemainder'
import {KTSVG} from '../../../../_metronic/helpers'
import {Spinner} from 'react-bootstrap'
import {RxCross2} from 'react-icons/rx'
import {FcHighPriority} from 'react-icons/fc'
import {FcLowPriority} from 'react-icons/fc'
import {FcMediumPriority} from 'react-icons/fc'
import {AiFillCaretDown, AiOutlineUsergroupAdd} from 'react-icons/ai'
import Select from 'react-select'
import CopyToClipboard from 'react-copy-to-clipboard'
import {PaginationComponent} from '../../common/components/pagination/PaginationComponent'
import {showToast} from '../../common/toastify/toastify.config'
import {BiRefresh} from 'react-icons/bi'
import api from '../../RequestConfig'
import 'react-toastify/dist/ReactToastify.css'
import {prev_state, pagination_prev_state} from './modals/create_ticket'
import {onChangeSortObj, sortObj, PAGE, CURRENTPAGE} from '../../common/globals/common.constants'
const API_URL = process.env.REACT_APP_API_URL
const GET_USER_BY_TOKEN = `${API_URL}/agent/auth/getAgentByToken`

const CreateTicketMain = () => {
  const page = PAGE
  const currentPage = CURRENTPAGE
  const [state, setState] = useState<prev_state>({
    appAgents: 0,
    agentsCount: 0,
  })
  const [paginationState, setPaginationState] = useState<pagination_prev_state>({
    itemsPerPage: 10,
    showingFrom: 1,
    showingTill: 10,
    page: page ? page : currentPage,
  })

  const [sortState, setSortState] = useState<sortObj>({
    sortBy: null,
    sortOn: null,
  })

  const userIdRef = useRef('')

  const [remainderidsdetail, setRemainderIdsdetail] = useState({
    sender_id: userIdRef.current,
    reciever_id: [],
    setUser: false,
  })

  const [currentTicketId, setcurrentTicketId] = useState('')
  const [userOptions, setUserOptions] = useState([{value: '', label: ''}])
  const [selectedOptions, setSelectedOptions] = useState([{value: '', label: ''}])
  const [sortpriority, setSortpriority] = useState('')
  const [sortstatus, setSortstatus] = useState('')
  const [mappriority, setMappriority] = useState(new Map())
  const [mapstatus, setMapstatus] = useState(new Map())
  const [allreadyusermap, setAllreadyusermap] = useState(new Map())
  const [priority, setPriority] = useState([{_id: '', name: '', color: ''}])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [category, setCategory] = useState([{_id: '', name: ''}])
  const [status, setStatus] = useState([{_id: '', name: ''}])
  const [searchstatevalue, setSearchStateValue] = useState('')
  const [allticket, setAllTicket] = useState([
    {
      _id: '',
      subject: '',
      content: '',
      To: [],
      priority: '',
      category: '',
      status: '',
      complete: 0,
    },
  ])
  const [isLoading, setIsLoading] = React.useState(false)
  const getAllTicket = async (page: number, limit: number, opt = 0) => {
    setIsLoading(true)
    if (searchstatevalue) {
      searchTicket()
      return
    }
    // eslint-disable-next-line eqeqeq
    if ((sortpriority || sortstatus) && opt == 0) {
      sortPriorityOrStatus(page, limit)
      return
    }

    const response = await fetch(`${API_URL}/ticketroutes/all_ticket`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        From: userIdRef.current,
        page: page,
        limit: limit,
        sortOn: sortState.sortOn,
        sortBy: sortState.sortBy,
      }),
    })
    const json_temp = await response.json()
    const json = json_temp.data
    const ticket_priority = new Map()
    const ticket_category = new Map()
    const ticket_status = new Map()
    const ticket_priority_temp = []
    const ticket_category_temp = []
    const ticket_status_temp = []
    for (let i = 0; i < json.priority_detail.length; i++) {
      ticket_priority.set(json.priority_detail[i]._id, [
        json.priority_detail[i].name,
        json.priority_detail[i].color,
      ])
      ticket_priority_temp.push({
        _id: json.priority_detail[i]._id,
        name: json.priority_detail[i].name,
        color: json.priority_detail[i].color,
      })
    }
    for (let i = 0; i < json.category_detail.length; i++) {
      ticket_category.set(json.category_detail[i]._id, [
        json.category_detail[i].name,
        json.category_detail[i].color,
      ])
      ticket_category_temp.push({
        _id: json.category_detail[i]._id,
        name: json.category_detail[i].name,
      })
    }
    for (let i = 0; i < json.status_detail.length; i++) {
      ticket_status.set(json.status_detail[i]._id, [
        json.status_detail[i].name,
        json.status_detail[i].color,
      ])
      ticket_status_temp.push({_id: json.status_detail[i]._id, name: json.status_detail[i].name})
    }
    setMappriority(ticket_priority)
    setMapstatus(ticket_status)
    setPriority(ticket_priority_temp)
    setCategory(ticket_category_temp)
    setStatus(ticket_status_temp)

    let totalRecords = json.all_ticket.length
    setState((previousState: prev_state) => {
      return {
        ...previousState,
        appAgents: showingFrom,
        agentsCount: json.total_ticket,
      }
    })
    let showingFrom = (page - 1) * limit + 1
    let showingTill: any
    if (totalRecords) {
      showingTill = Math.min(json.total_ticket, page * limit)
    }
    setPaginationState((previousState: pagination_prev_state) => {
      return {
        ...previousState,
        showingFrom: showingFrom,
        showingTill: showingTill,
      }
    })
    setAllTicket(json.all_ticket)
    setIsLoading(false)
  }

  const sortPriorityOrStatus = async (page: number, limit: number) => {
    const response = await fetch(`${API_URL}/ticketroutes/all_category_ticket`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        From: userIdRef.current,
        ticketPriorityId: sortpriority,
        ticketStatusId: sortstatus,
        page: page,
        limit: paginationState.itemsPerPage,
      }),
    })
    const json_temp = await response.json()
    const json = json_temp.data
    let totalRecords = json.all_category_ticket.length
    setState((previousState: prev_state) => {
      return {
        ...previousState,
        appAgents: showingFrom,
        agentsCount: json.total_ticket,
      }
    })
    let showingFrom = json.total_ticket > 0 ? (page - 1) * limit + 1 : 0
    let showingTill: any
    if (totalRecords) {
      showingTill = Math.min(json.total_ticket, page * limit)
    }
    setPaginationState((previousState: pagination_prev_state) => {
      return {
        ...previousState,
        showingFrom: showingFrom,
        showingTill: showingTill,
      }
    })
    setAllTicket(json.all_category_ticket)
  }

  const sort_Priority = (e: any) => {
    setSortpriority(e.target.value)
  }

  const sort_Status = (e: any) => {
    setSortstatus(e.target.value)
  }
  const onChange = (e: any) => {
    setSearchStateValue(e.target.value)
  }
  const searchTicket = async () => {
    const response = await fetch(`${API_URL}/ticketroutes/all_search_ticket`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        From: userIdRef.current,
        searchValue: searchstatevalue,
        page: paginationState.page,
        limit: paginationState.itemsPerPage,
      }),
    })
    const json_temp = await response.json()
    const json = json_temp.data
    let totalRecords = json.all_search_ticket.length
    setState((previousState: prev_state) => {
      return {
        ...previousState,
        appAgents: showingFrom,
        agentsCount: json.total_ticket,
      }
    })
    let showingFrom = (paginationState.page - 1) * paginationState.itemsPerPage + 1
    let showingTill: any
    if (totalRecords) {
      showingTill = Math.min(json.total_ticket, paginationState.page * paginationState.itemsPerPage)
    }
    setPaginationState((previousState: pagination_prev_state) => {
      return {
        ...previousState,
        showingFrom: showingFrom,
        showingTill: showingTill,
      }
    })
    setAllTicket(json.all_search_ticket)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPriorityId, setCurrentPriorityId] = useState('')
  const valueRef = useRef('')
  const set_current_status = (priority_id: string) => {
    setCurrentPriorityId(priority_id)
    valueRef.current = priority_id
  }
  const updatePriority = async () => {
    const response = await fetch(`${API_URL}/ticketroutes/update_priority`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ticket_id: currentTicketId,
        priorityId: valueRef.current,
      }),
    })
    const json = await response.json()
    setShowPriorityBar(false)
    getAllTicket(paginationState.page, paginationState.itemsPerPage)
    if (!json.error) {
      showToast(`Priority Change Successfully`, 'success')
    } else {
      showToast('Priority Change Failed', 'error')
    }
  }

  const updateCompletedPercent = async (
    ticket_id: string,
    next_completion: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const response = await fetch(`${API_URL}/ticketroutes/update_completion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ticket_id: ticket_id,
        completionPercent: Number(next_completion.target.value),
      }),
    })
    const json = await response.json()
    getAllTicket(paginationState.page, paginationState.itemsPerPage)
    if (!json.error) {
      showToast(`Completion ${next_completion.target.value}% Change Successfully`, 'success')
    } else {
      showToast('Completion change failed', 'error')
    }
  }

  const [showFilter, setShowFilter] = useState(false)
  const show_filter = () => {
    if (showFilter) {
      setShowFilter(false)
    } else {
      setShowFilter(true)
    }
  }

  const [showAddUser, setShowAddUser] = useState(false)
  const [alreadyArrayAssingedUser, setAlreadyArrayAssingedUser] = useState([{value: '', label: ''}])

  const show_added_user = (To: any, ticket_id: any) => {
    if (showAddUser) {
      setShowAddUser(false)
    } else {
      let l = [{value: '', label: ''}]
      for (let i = 0; i < To.length; i++) {
        l.push(allreadyusermap.get(To[i]))
      }
      l.shift()
      setAlreadyArrayAssingedUser(l)
      setShowAddUser(true)
      setcurrentTicketId(ticket_id)
    }
  }

  const onPageChange = (page: any) => {
    page = page.selected
    setPaginationState((previousState) => {
      return {...previousState, page: page + 1}
    })
    getAllTicket(page + 1, paginationState.itemsPerPage)
  }

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOptions(selectedOption)
  }

  const getAllUser = async () => {
    const response = await fetch(`${API_URL}/ticketroutes/get_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        loginUserId: userIdRef.current,
      }),
    })
    const json_temp = await response.json()
    const json = json_temp.data
    const already_user = new Map()
    for (let i = 0; i < json.allUsers.length; i++) {
      already_user.set(json.allUsers[i].value, {
        value: json.allUsers[i].value,
        label: json.allUsers[i].label,
      })
    }
    setAllreadyusermap(already_user)
    setUserOptions(json.allUsers)
  }

  const onClickTableHeader = (event: any) => {
    const newSortObj = onChangeSortObj(event, sortState)
    setSortState({
      sortBy: newSortObj.sortBy,
      sortOn: newSortObj.sortOn,
    })
  }

  const add_user = async () => {
    let new_add_user = []
    for (let i = 0; i < selectedOptions.length; i++) {
      new_add_user.push(selectedOptions[i].value)
    }
    const response = await fetch(`${API_URL}/ticketroutes/add_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ticket_id: currentTicketId,
        addedUsersIds: new_add_user,
      }),
    })
    const json = await response.json()
    if (!json.error) {
      getAllTicket(paginationState.page, paginationState.itemsPerPage)
      showToast('Added User Successfully', 'success')
      setShowAddUser(false)
    } else {
      showToast('Failed or Limit cross to add User', 'error')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authUser, setAuthUser] = useState(true)

  const [showPriorityBar, setShowPriorityBar] = useState(false)
  const show_priority_bar = (ticket_id: string) => {
    if (showPriorityBar) {
      setShowPriorityBar(false)
    } else {
      setShowPriorityBar(true)
      setcurrentTicketId(ticket_id)
    }
  }

  useEffect(() => {
    const set_user_id = async () => {
      const varToken = localStorage.getItem('token')
      try {
        const result = await api.get(GET_USER_BY_TOKEN, {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        })
        userIdRef.current = result.data.User.appUserId
        getAllUser()
        getAllTicket(1, 10)
      } catch (err: any) {
        console.error(err)
      }
    }
    set_user_id()
    // getAllUser();
    // getAllTicket(1, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortState.sortOn, sortState.sortBy, searchstatevalue])
  return (
    <>
      {!authUser && <h5 className='text-center'>You are not authorized user</h5>}
      {authUser && (
        <div className='card'>
          <SendRemainder _ids={remainderidsdetail} />
          <div className='card-header py-5'>
            <div className='card-title'>
              <select
                className='form-select form-select-solid mx-2'
                data-kt-select2='true'
                data-placeholder='Show records'
                defaultValue={10}
                onChange={(e: any) => {
                  setPaginationState((prevState) => {
                    return {
                      ...prevState,
                      itemsPerPage: Number(e.target.value),
                    }
                  })
                  getAllTicket(paginationState.page, Number(e.target.value))
                }}
              >
                <option value='10'>10 Records</option>
                <option value='15'>15 Records</option>
                <option value='25'>25 Records</option>
                <option value='50'>50 Records</option>
              </select>
              <div className='my-4 mx-5'>
                <i
                  className='fas fa-search'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_8'
                  style={{fontSize: '22px', cursor: 'pointer'}}
                ></i>
                <div className='modal fade my-5' tabIndex={-1} id='kt_modal_8'>
                  <div className='modal-dialog'>
                    <div className='modal-content'>
                      <div className='modal-header' style={{height: '10px'}}>
                        <div>
                          <div className='input-group'>
                            <input
                              type='search'
                              style={{width: '350px'}}
                              name='search'
                              onChange={onChange}
                              id='search'
                              className='form-control border-0'
                              placeholder='Type here and click on Search'
                              aria-label='Search'
                              aria-describedby='search-addon'
                            />
                            <i
                              className='fas fa-search mx-3 my-3'
                              onClick={searchTicket}
                              style={{fontSize: '20px', color: 'blue', cursor: 'pointer'}}
                            ></i>
                          </div>
                        </div>
                        <div
                          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <RxCross2 style={{width: '20px', height: '20px', color: 'red'}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* -------------------- */}
              </div>
              <div className='my-3'>
                <BiRefresh
                  style={{fontSize: '30px', cursor: 'pointer'}}
                  onClick={() => {
                    sortState.sortOn = null
                    sortState.sortBy = null
                    setSortstatus('')
                    setSortpriority('')
                    setSearchStateValue('')
                    getAllTicket(paginationState.page, paginationState.itemsPerPage, 1)
                  }}
                />
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
                <div className='d-flex align-items-center gap-2 gap-lg-3'>
                  <div className='m-0'>
                    <i
                      className='fas fa-filter mx-4'
                      onClick={show_filter}
                      style={{fontSize: '22px', cursor: 'pointer'}}
                    ></i>
                    {showFilter && (
                      <div
                        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px show'
                        style={{
                          zIndex: '105',
                          position: 'fixed',
                          inset: '0px auto auto',
                          margin: '0px',
                          transform: 'translate3d(-50%, 70px, 0px)',
                        }}
                      >
                        <div className='px-7 py-5'>
                          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                        </div>
                        <div className='separator border-gray-200'></div>
                        <div className='px-7 py-5'>
                          <div className='mb-10'>
                            <label className='form-label fw-bold'>Priority:</label>
                            <div>
                              <select
                                onChange={sort_Priority}
                                name='_sortprioriy'
                                className='form-select form-select-solid'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                              >
                                <option value=''>Filter Priority</option>
                                {priority?.map((_priority1) => (
                                  <option value={_priority1._id}>{_priority1.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='mb-10'>
                            <label className='form-label fw-bold'>Status:</label>
                            <div>
                              <select
                                onChange={sort_Status}
                                name='_sortstatus'
                                className='form-select form-select-solid'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                              >
                                <option value=''>Filter Status</option>
                                {status?.map((_status1) => (
                                  <option value={_status1._id}>{_status1.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='d-flex justify-content-end'>
                            <button
                              onClick={show_filter}
                              className='btn btn-sm btn-light btn-active-light-primary me-2'
                              data-kt-menu-dismiss='true'
                            >
                              Reset
                            </button>
                            <button
                              type='submit'
                              onClick={() => {
                                sortPriorityOrStatus(1, paginationState.itemsPerPage)
                                show_filter()
                              }}
                              className='btn btn-sm btn-primary'
                              data-kt-menu-dismiss='true'
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <CreateTicket loginUserId={userIdRef.current} />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{marginTop: '50px'}}
              className='d-flex align-items-center justify-content-center loader-container'
            >
              <Spinner animation='border' variant='primary' />
            </div>
          ) : (
            <div className='card-body pt-0'>
              <div className='py-3'>
                <table className='table align-middle table-row-dashed table-responsive fs-6 gy-2'>
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='min-w-125px text-center text-black-50'>ID</th>
                      <th
                        id='subject'
                        onClick={onClickTableHeader}
                        className={clsx(
                          'min-w-125px table-sort text-center cursor-pointer text-black-50',
                          {
                            'table-sort-asc':
                              sortState.sortOn === 'subject' && sortState.sortBy === 'asc',
                          },
                          {
                            'table-sort-desc':
                              sortState.sortOn === 'subject' && sortState.sortBy === 'desc',
                          }
                        )}
                      >
                        Title
                      </th>
                      <th className='min-w-125px text-black-50'>Assigned To</th>
                      <th className='min-w-125px text-black-50'>
                        <div style={{marginLeft: '10px'}}>Update Priority</div>
                      </th>
                      <th className='min-w-125px text-black-50'>
                        <div style={{marginLeft: '12px'}}>Completed %</div>
                      </th>
                      <th className='min-w-125px text-center text-black-50'>Stauts</th>
                      <th className='min-w-125px text-center text-black-50'>Action</th>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {allticket?.map((ticket) => (
                      <tr>
                        <td
                          className='mw-50px text-center cursor-pointer'
                          title='Click here to copy'
                          data-toggle='tooltip'
                        >
                          <CopyToClipboard
                            onCopy={(text, result) => {
                              result && showToast('ID copied to clipboard', 'success')
                            }}
                            text={ticket._id || ''}
                          >
                            <span className='badge badge-light-dark text-hover-primary text-gray-700 mb-1 my-4'>
                              NG
                              {`${
                                // eslint-disable-next-line eqeqeq
                                ticket.category.trim().split(' ').length == 1
                                  ? ticket.category.slice(0, 2)?.toUpperCase()
                                  : ticket.category
                                      .split(/\s/)
                                      .reduce(
                                        (response, word) => (response += word.slice(0, 1)),
                                        ''
                                      )
                                      ?.toUpperCase()
                              }`}
                              -{`${ticket._id?.substring(0, 10)}...`}
                              {/* NG{`${ticket.category.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')?.toUpperCase()}`}-{`${ticket._id?.substring(0, 10)}...`} */}
                            </span>
                          </CopyToClipboard>
                        </td>
                        <td className='text-center'>
                          <span className='text-gray-800 mb-1'>{ticket.subject}</span>
                        </td>
                        <td className=''>
                          <p
                            className='my-4'
                            style={{cursor: 'pointer'}}
                            onClick={() => show_added_user(ticket.To, ticket._id)}
                          >
                            <AiOutlineUsergroupAdd style={{fontSize: '25px', marginLeft: '25px'}} />
                          </p>
                          {showAddUser && (
                            <div
                              className='menu menu-sub menu-sub-dropdown w-250px w-md-300px show'
                              style={{
                                zIndex: '105',
                                position: 'fixed',
                                inset: '0px auto auto',
                                margin: '0px',
                                transform: 'translate(-100%, 80px)',
                              }}
                            >
                              <div className='px-7 py-5'>
                                <div className='fs-5 text-dark fw-bolder'>Add User</div>
                              </div>
                              <div className='separator border-gray-200'></div>
                              <div className='px-7 py-5'>
                                <label className='required form-label my-3'>Select Add User</label>
                                <Select
                                  defaultValue={alreadyArrayAssingedUser}
                                  isMulti
                                  name='name'
                                  onChange={handleSelectChange}
                                  options={userOptions}
                                  className='basic-multi-select'
                                  classNamePrefix='select'
                                />
                                <div className='d-flex justify-content-end'>
                                  <button
                                    onClick={() => show_added_user(ticket.To, ticket._id)}
                                    className='btn btn-sm btn-light btn-active-light-primary me-2 my-5'
                                    data-kt-menu-dismiss='true'
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type='submit'
                                    onClick={() => add_user()}
                                    className='btn btn-sm btn-primary my-5'
                                    data-kt-menu-dismiss='true'
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td
                          style={{cursor: 'pointer'}}
                          className='text-center'
                          onClick={() => show_priority_bar(ticket._id)}
                        >
                          {/* eslint-disable-next-line eqeqeq */}
                          {mappriority.get(ticket.priority)?.[0] == 'Low' && (
                            <FcLowPriority style={{marginRight: '10px', fontSize: '16px'}} />
                          )}
                          {/* eslint-disable-next-line eqeqeq */}
                          {mappriority.get(ticket.priority)?.[0] == 'Medium' && (
                            <FcMediumPriority style={{marginRight: '10px', fontSize: '16px'}} />
                          )}
                          {/* eslint-disable-next-line eqeqeq */}
                          {mappriority.get(ticket.priority)?.[0] == 'High' && (
                            <FcHighPriority style={{marginRight: '10px', fontSize: '16px'}} />
                          )}
                          {mappriority.get(ticket.priority)?.[0]}{' '}
                          {/* eslint-disable-next-line eqeqeq */}
                          {mappriority.get(ticket.priority)?.[0] == 'Medium' ? (
                            <AiFillCaretDown style={{marginLeft: '26px'}} />
                          ) : (
                            <AiFillCaretDown style={{marginLeft: '50px'}} />
                          )}
                          {showPriorityBar && (
                            <div
                              className='menu menu-sub menu-sub-dropdown w-150px w-md-150px show'
                              style={{
                                zIndex: '105',
                                position: 'fixed',
                                inset: '0px auto auto',
                                margin: '0px',
                                transform: 'translate(-110%, 250%)',
                              }}
                            >
                              <div className='py-5'>
                                {priority?.map((_priority1) => (
                                  <>
                                    <div
                                      onClick={() => {
                                        set_current_status(_priority1._id)
                                        updatePriority()
                                      }}
                                      style={{cursor: 'pointer'}}
                                    >
                                      {/* eslint-disable-next-line eqeqeq */}
                                      {_priority1.name == 'Low' && (
                                        <div>
                                          <FcLowPriority style={{marginRight: '15px'}} /> Low{' '}
                                        </div>
                                      )}
                                      {/* eslint-disable-next-line eqeqeq */}
                                      {_priority1.name == 'Medium' && (
                                        <div className='my-3'>
                                          <FcMediumPriority style={{marginRight: '15px'}} /> Medium
                                        </div>
                                      )}
                                      {/* eslint-disable-next-line eqeqeq */}
                                      {_priority1.name == 'High' && (
                                        <div>
                                          <FcHighPriority style={{marginRight: '15px'}} /> High
                                        </div>
                                      )}
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          )}
                        </td>
                        <td className='text-center'>
                          <div className='row text-center' style={{marginLeft: '5px'}}>
                            <div className='col padding-0'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-muted me-2 fs-7 fw-semibold'>
                                  {ticket.complete}%
                                </span>
                              </div>
                              <div className='d-flex'>
                                <div className='progress h-6px w-100'>
                                  <div
                                    className='progress-bar bg-success'
                                    role='progressbar'
                                    style={{width: `${ticket.complete}%`}}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className='col padding-0'>
                              <select
                                style={{height: '7px', width: '6px'}}
                                className='form-select border-gray-100 my-4'
                                onChange={(e) => updateCompletedPercent(ticket._id, e)}
                                name='sendto'
                                defaultValue={'DEFAULT'}
                                aria-label='Default select example'
                              >
                                <option value='0'>0%</option>
                                <option value='25'>25%</option>
                                <option value='50'>50%</option>
                                <option value='75'>75%</option>
                                <option value='100'>100%</option>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td className='mw-50px text-center'>
                          <span
                            style={{
                              color: `${mapstatus.get(ticket.status)?.[1]}`,
                              fontSize: '12px',
                            }}
                            className='badge badge-sm badge-square badge-light-primary my-4'
                          >
                            {mapstatus.get(ticket.status)?.[0]}
                          </span>
                        </td>
                        <td className='text-center'>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            href='#'
                            className='btn btn-light btn-active-light-primary btn-sm'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                          >
                            Actions
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr072.svg'
                              className='svg-icon-5 m-0'
                            />
                          </a>
                          {/* begin::Menu */}
                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                            data-kt-menu='true'
                          >
                            {/* begin::Menu item */}
                            <div className='menu-item px-3'>
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a
                                className='menu-link px-1'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_10'
                                onClick={() => {
                                  setRemainderIdsdetail({
                                    sender_id: userIdRef.current,
                                    reciever_id: ticket.To,
                                    setUser: true,
                                  })
                                }}
                                style={{cursor: 'pointer'}}
                              >
                                Send Remainder
                              </a>
                            </div>
                            {/* end::Menu item */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {allticket.length === 0 && (
                  <p className='text-bold text-center my-5'>
                    -----------------------------You Are Not Created Any
                    Ticket-----------------------------
                  </p>
                )}
              </div>
              <PaginationComponent
                onPageChange={onPageChange}
                pageCount={Math.ceil(state.agentsCount / paginationState.itemsPerPage)}
                showingFrom={paginationState.showingFrom}
                showingTill={paginationState.showingTill}
                totalRecords={state.agentsCount}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CreateTicketMain
