import {Dispatch, SetStateAction, createContext, useState} from 'react'
import api from '../../RequestConfig'
import {showToast} from '../../common/toastify/toastify.config'

type ModalFunction = Dispatch<SetStateAction<any>>

interface SalaryContextProps {
  isAddSalaryModalOpen: boolean
  isEditSalaryModalOpen: boolean
  isSalaryListModalOpen: boolean
  isSalarySlipModalOpen: boolean
  isMailSalarySlipModalOpen: boolean
  isViewSalaryModalOpen: boolean
  isMonthlySalaryModalOpen: boolean
  salaryDetails: any[]
  searchData: any
  recordsPerPage: number
  currentPage: number
  totalRecords: number
  month: string | null
  year: string | null
  appDesignationId: string | null
  appDepartmentId: string | null
  checkedRows: any
  selectAllChecked: any
  handleCheckboxChange: any
  addSalaryModalFunction: ModalFunction
  editSalaryModalFunction: ModalFunction
  salaryListModalFunction: ModalFunction
  salarySlipModalFunction: ModalFunction
  viewSalaryModalFunction: ModalFunction
  mailSalarySlipModalFunction: ModalFunction
  monthlySalaryModalFunction: ModalFunction
  addNewSalaryDetail: (value: any[]) => void
  addSalaryDetail: (value: any[]) => void
  deleteSalaryDetails: (ids: string[]) => void
  onChangeSearchFunction: ModalFunction
  onChangeRecordFunction: ModalFunction
  onChangePageFunction: ModalFunction
  onChangeTotalRecordsFunction: ModalFunction
  onChangeDesignationId: ModalFunction
  onChangeDepartmenId: ModalFunction
  onChangeMonth: ModalFunction
  onChangeYear: ModalFunction
  deleteSalary: any
  getSalaryDetails: any
}

export const SalaryContext = createContext<SalaryContextProps>({
  isAddSalaryModalOpen: false,
  isEditSalaryModalOpen: false,
  isSalaryListModalOpen: false,
  isSalarySlipModalOpen: false,
  isMailSalarySlipModalOpen: false,
  isViewSalaryModalOpen: false,
  isMonthlySalaryModalOpen: false,
  salaryDetails: [],
  searchData: '',
  recordsPerPage: 10,
  currentPage: 1,
  totalRecords: 0,
  appDesignationId: null,
  appDepartmentId: null,
  month: null,
  year: null,
  checkedRows: [],
  selectAllChecked: false,
  handleCheckboxChange: () => {},
  addSalaryModalFunction: () => {},
  editSalaryModalFunction: () => {},
  salaryListModalFunction: () => {},
  salarySlipModalFunction: () => {},
  mailSalarySlipModalFunction: () => {},
  viewSalaryModalFunction: () => {},
  monthlySalaryModalFunction: () => {},
  addNewSalaryDetail: () => {},
  addSalaryDetail: () => {},
  deleteSalaryDetails: () => {},
  onChangeSearchFunction: () => {},
  onChangeRecordFunction: () => {},
  onChangeTotalRecordsFunction: () => {},
  onChangePageFunction: () => {},
  onChangeDesignationId: () => {},
  onChangeDepartmenId: () => {},
  onChangeMonth: () => {},
  onChangeYear: () => {},
  deleteSalary: () => {},
  getSalaryDetails: () => {},
})

const API_URL = process.env.REACT_APP_API_URL
const DELETE_SALARY = `${API_URL}/salary/delete-salary-details`
const GET_ALL_SALARY = `${API_URL}/salary/get-salary-details`

function SalaryContextProvider({children}: any) {
  // state to track wether the modal is open or close
  const currentDate = new Date()
  const defaultMonth = currentDate.toLocaleString('default', {month: 'long'})
  const defaultYear = currentDate.getFullYear().toString()
  const [isAddSalaryModalOpen, setIsAddSalaryModalOpen] = useState<boolean>(false)
  const [isEditSalaryModalOpen, setIsEditSalaryModalOpen] = useState<boolean>(false)
  const [isSalaryListModalOpen, setIsSalaryListModalOpen] = useState<boolean>(false)
  const [isSalarySlipModalOpen, setIsSalarySlipModalOpen] = useState<boolean>(false)
  const [isViewSalaryModalOpen, setIsViewSalaryModalOpen] = useState<boolean>(false)
  const [isMailSalarySlipModalOpen, setIsMailSalarySlipModalOpen] = useState<boolean>(false)
  const [isMonthlySalaryModalOpen, setIsMonthlySalaryModalOpen] = useState<boolean>(false)
  const [salaryDetails, setSalaryDetails] = useState<any[]>([])
  const [searchData, setSearchData] = useState<any>('')
  const [appDesignationId, setAppDesignationId] = useState<string | null>(null)
  const [appDepartmentId, setAppDepartmentId] = useState<string | null>(null)
  const [month, setMonth] = useState<string | null>(defaultMonth)
  const [year, setYear] = useState<string | null>(defaultYear)
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [checkedRows, setCheckedRows] = useState<string[]>([])

  // function to handle opening and closing of modal
  const addSalaryModalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsAddSalaryModalOpen(value)
  }

  const editSalaryModalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsEditSalaryModalOpen(value)
  }

  const salaryListModalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsSalaryListModalOpen(value)
  }

  const salarySlipModalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsSalarySlipModalOpen(value)
  }

  const mailSalarySlipModalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsMailSalarySlipModalOpen(value)
  }

  const viewSalaryModalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsViewSalaryModalOpen(value)
  }

  const monthlySalaryModalFunction: ModalFunction = (value: SetStateAction<boolean>) => {
    setIsMonthlySalaryModalOpen(value)
  }

  const addNewSalaryDetail = (value: any[]) => {
    setSalaryDetails((prevSalries) => [value[0], ...prevSalries])
  }

  const addSalaryDetail = (value: any[]) => {
    setSalaryDetails(value)
  }

  const deleteSalaryDetails = (ids: string[]) => {
    setSalaryDetails((prevSalaries) =>
      prevSalaries.filter((salaryDetails) => !ids.includes(salaryDetails.appUserSalaryDetailsId))
    )
  }

  const onChangeSearchFunction: ModalFunction = (event: SetStateAction<any>) => {
    setSearchData(event)
  }

  const onChangeRecordFunction: ModalFunction = (event: SetStateAction<number>) => {
    setRecordsPerPage(event)
  }

  const onChangePageFunction: ModalFunction = (event: SetStateAction<number>) => {
    setCurrentPage(event)
  }

  const onChangeTotalRecordsFunction: ModalFunction = (event: SetStateAction<number>) => {
    setTotalRecords(event)
  }

  const onChangeDesignationId: ModalFunction = (value: SetStateAction<string | null>) => {
    setAppDesignationId(value)
  }

  const onChangeDepartmenId: ModalFunction = (value: SetStateAction<string | null>) => {
    setAppDepartmentId(value)
  }

  const onChangeMonth: ModalFunction = (value: SetStateAction<string | null>) => {
    if (value) {
      setMonth(value)
    } else {
      setMonth(defaultMonth)
    }
  }

  const onChangeYear: ModalFunction = (value: SetStateAction<string | null>) => {
    if (value) {
      setYear(value)
    } else {
      setYear(defaultYear)
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'selectAll') {
      const {checked} = e.target
      setCheckedRows(
        checked ? salaryDetails?.map((salary: any) => salary.appUserSalaryDetailsId || '') : []
      )
      setSelectAllChecked(checked)
    } else {
      const {checked, id} = e.target
      setCheckedRows((prevcheckedMenus) =>
        checked ? [...prevcheckedMenus, id] : prevcheckedMenus.filter((salaryId) => salaryId !== id)
      )
      setSelectAllChecked(false)
    }
  }

  const deleteSalary = async (ids: string[]) => {
    const varToken = localStorage.getItem('token')

    try {
      const result = await api.delete(DELETE_SALARY, {
        headers: {
          genie_access_token: 'Bearer ' + varToken,
        },
        data: {
          appUserSalaryDetailsIds: ids,
        },
      })
      if (result.data.error === false) {
        showToast(`${result.data.data.message}`, 'success')
        setCheckedRows([])
        setSelectAllChecked(false)
        await getSalaryDetails()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getSalaryDetails = async () => {
    const varToken = localStorage.getItem('token')

    try {
      const result = await api.post(
        GET_ALL_SALARY,
        {
          appUserSalaryDetailsId: null,
          appUserId: null,
          appDepartmentId,
          appDesignationId,
          month,
          year,
          search: searchData ? searchData : null,
          metaData: {
            sortBy: null,
            sortOn: null,
            limit: recordsPerPage,
            offset: searchData ? null : recordsPerPage * (currentPage - 1),
            fields: [],
          },
        },
        {
          headers: {
            genie_access_token: 'Bearer ' + varToken,
          },
        }
      )
      if (result.data.error === false) {
        setSalaryDetails(result.data.data.appUsersSalaryDetails)
        onChangeTotalRecordsFunction(result.data.data.metaData.total_records)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <SalaryContext.Provider
      value={{
        isAddSalaryModalOpen,
        isEditSalaryModalOpen,
        isSalaryListModalOpen,
        isSalarySlipModalOpen,
        isMailSalarySlipModalOpen,
        isViewSalaryModalOpen,
        isMonthlySalaryModalOpen,
        salaryDetails,
        searchData,
        recordsPerPage,
        currentPage,
        totalRecords,
        appDesignationId,
        appDepartmentId,
        month,
        year,
        checkedRows,
        selectAllChecked,
        addSalaryModalFunction,
        editSalaryModalFunction,
        salaryListModalFunction,
        salarySlipModalFunction,
        mailSalarySlipModalFunction,
        viewSalaryModalFunction,
        monthlySalaryModalFunction,
        addNewSalaryDetail,
        addSalaryDetail,
        deleteSalaryDetails,
        onChangeSearchFunction,
        onChangeRecordFunction,
        onChangePageFunction,
        onChangeTotalRecordsFunction,
        onChangeDesignationId,
        onChangeDepartmenId,
        onChangeMonth,
        onChangeYear,
        handleCheckboxChange,
        deleteSalary,
        getSalaryDetails,
      }}
    >
      {children}
    </SalaryContext.Provider>
  )
}

export default SalaryContextProvider
